angular
  .module('dlcApp.factories')
  .factory('ReportView', [
    'api', '$q', '$timeout', 'stats', 'ChartSeriesModel', 'DateRanges',
    function (api, $q, $timeout, stats, ChartSeriesModel, DateRanges) {
      var ReportView = function (apiInstance) {
        this.api = apiInstance || api;
        this.isZoomed = false;
        this.panCounter = 0;
        this.clientXLastPosition = 0;
        this.mouseDirection = "";
      };

      ReportView.prototype.updatePolarChart = function (report, chart) {
        var apiDataSeries = [];
        var requestRanges = [];
        var chartType;

        chart.series.forEach(function (chartSeries, chartSeriesIdx) {
          var range = {
            start: apiDataSeries.length
          };

          var options = chartSeries.options;
          chartType = options.roseType;

          if (options.roseType === 'wind') {
            report.getWindRoseDataSeries(options.datasetId, options.roseName)
              .forEach(function (dataSeries) {
                apiDataSeries.push({
                  datasetId: chartSeries.options.datasetId,
                  dataSeriesId: dataSeries.id,
                  aggregate: true
                });
              });
          } else if (options.roseType === 'class') {
            var classDataSeries = report.getWindClassRoseDataSeries(options.datasetId, options.roseName);

            angular.forEach(classDataSeries, function (dataSeriesArray, classIdx) {
              if (parseInt(classIdx, 10) !== chartSeriesIdx) {
                return;
              }

              dataSeriesArray.forEach(function (dataSeries) {
                apiDataSeries.push({
                  datasetId: options.datasetId,
                  dataSeriesId: dataSeries.id,
                  aggregate: true
                });
              });
            });
          } else {
            console.error(chart);
            throw new Error('Invalid chart type!');
          }

          range.end = apiDataSeries.length - 1;

          requestRanges.push(range);
        });

        var from = moment.utc(this.globalXMin);
        var to = moment.utc(this.globalXMax);

        return this.api.getDataSetValues(from, to, apiDataSeries)
          .success(function (data) {
            var roseTotal;

            if (chartType === 'class') {
              // Wind Class Rose total is that of all segments and classes.
              roseTotal = data.map(function (stats) {
                return stats.sum;
              }).reduce(function (prev, curr) {
                return prev + curr;
              });
            }

            chart.series.forEach(function (cSeries, index) {
              var range = requestRanges[index];

              // slice(begin, end):
              // end: Zero-based index at which to end extraction. slice extracts up to but not including end
              // This is why we need the + 1 on the end. See DEL-712.
              var rawSeriesData = data.slice(range.start, range.end + 1).map(function (stats) {
                return stats.sum;
              });

              if (chartType === 'wind') {
                // Wind rose total is calculated per series.
                roseTotal = rawSeriesData.reduce(function (prev, curr) {
                  return prev + curr;
                });
              }

              cSeries.setData(rawSeriesData.map(function (value, index) {
                if (roseTotal === 0) {
                  // You can't divide by zero, and returning 0 shows 100% all around.
                  return null;
                } else {
                  return (value / roseTotal) * 100;
                }
              }), false);

              rawSeriesData = null;
            });

            chart.redraw();
            chart.hideLoading();
            data = null;
          });
      };

      ReportView.prototype.updateChart = function (report, c, setExtremes) {
        var _this = this;

        if (c.polar) {
          return _this.updatePolarChart(report, c);
        }

        // Update extremes by default, or if a truthy value passed in.
        if (setExtremes === undefined || setExtremes) {
          var extremes = c.xAxis[0].getExtremes();

          // Don't re-request data if this chart is already showing the correct range.
          // This is useful when the user zooms more than once whilst we're loading data.
          if (extremes.min === _this.globalXMin && extremes.max === _this.globalXMax) {
            return $q.when().then(function () {
              c.hideLoading();
            });
          }

          c.xAxis[0].setExtremes(_this.globalXMin, _this.globalXMax);
        }

        // If this is a placeholder, take no action.
        if (c.series.length === 1 && c.series[0].options.placeholder === true) {
          return $q.when().then(function () {
            c.hideLoading();
          });
        }

        var from = moment.utc(_this.globalXMin);
        var to   = moment.utc(_this.globalXMax);

        // Create array of items to fetch.
        var apiDataSeries = c.series.map(function (cSeries) {
          var series = report.getDataSeries(cSeries.options.id);

          if (!series) {
            console.error(cSeries.options);
            throw new Error('Failed to find dataSeries ' + cSeries.options.id);
          }

          var maxDataPoints = Math.floor(c.plotWidth / 8);

          return {
            datasetId: series.datasetId,
            dataSeriesId: series.id,
            chartType: series.chartDetail.chartType,
            bucketSize: _this.api.calcChartBucketSize(series, from, to, maxDataPoints),
            allowNull: Boolean(series.sampleRate) // Only allow nulls (shown as gaps) for series with a sampleRate.
          };
        });

        // Fetch the data and update the chart's series.
        return _this.api.getDataSetValues(from, to, apiDataSeries)
          .success(function (data) {
            data.forEach(function (seriesData, index) {
              var options = apiDataSeries[index];
              var chartType;
              var processMethod;

              if (options.bucketSize) {
                if (options.chartType === ChartSeriesModel.CHART_TYPE_BAR) {
                  processMethod = _this.processSumData;
                  chartType = 'column';
                } else {
                  processMethod = _this.processAggregateData;
                  chartType = 'arearange';
                }
              } else {
                processMethod = _this.processRawData;
                chartType = (options.chartType === ChartSeriesModel.CHART_TYPE_BAR) ? 'column' : 'line';
              }

              var chartData = processMethod.call(_this, seriesData, options.allowNull);

              if (c.series[index].type != chartType) {
                c.series[index].update({ type: chartType }, false);
              }
              c.series[index].setData(chartData, false, false, false);
            });

            c.redraw();
            c.hideLoading();
            data = null;
          })
          .error(console.error.bind(console));
      };

      /**
       * This function returns a Promise either immediately if there's nothing to do,
       * or once the DOM is ready to load data (all charts will be visible and showing
       * the "Loading..." message.
       */
      ReportView.prototype.renderReport = function (report, startDate, endDate, timeframe) {
        // We cannot render a report with no datasets, do nothing.
        if (report.datasets.length === 0) {
          return $q.when();
        }
        var renderReportDeferred = $q.defer();

        var statName = 'renderReport';
        if (report.id) {
          statName += ':' + report.id;
        } else if (report.datasets && report.datasets[0]) {
          statName += ':dataset:' + report.datasets[0].id;
        }

        stats.start(statName);
        stats.start(statName + ':time-to-first-chart');
        report.setDateRangeFromDatasets();
        var _this = this;

        // If we have date ranges passed in, use those to set the extremes of the graph,
        // otherwise, just use the first and last date of the dataset.
        endDate = endDate || report.dateRange.lastDate.valueOf();
        startDate = startDate || report.dateRange.firstDate.valueOf();

        // If we have a timeframe, we need to update the startTime to match said timeframe
        if (timeframe && startDate === report.dateRange.firstDate.valueOf()) {
          startDate = this.getAutoZoomStartTimeFromTimeframe(endDate, timeframe);
        }

        _this.globalXMin = startDate;
        _this.globalXMax = endDate;

        var DIGITAL_SERIES_HEIGHT = window.innerWidth < 375 ? 70 : 80;
        var DIGITAL_SERIES_MAX_TOP = window.innerWidth < 375 ? 200 : 240;

        var afterSetExtremes = _this.afterSetExtremes.bind(_this, report);

        // Use a $timeout to delay jQuery execution.
        $timeout(function () {

          var datasetPrefixes = {};
          report.datasets.forEach(function (dataset, idx) {
            datasetPrefixes[dataset.id] = report.datasets.length > 1 ? '[' + (idx + 1) + '] ' : '';
          });

          var loadDataFunctions = report.charts.map(function (chart, id) {
            // Ignore empty elements
            if (!chart) {
              return;
            }

            var highchartsSeries = [];

            var axes = {
              x: [],
              y: []
            };

            // Determine the min resolution per chart
            var resolutions = [];
            var minimums = [];
            var maximums = [];

            if (chart.isWindRose()) {
              // Set wind rose axes.
              axes.x.push(_this.createWindRoseXAxis(report, chart));
              axes.y.push(_this.createWindRoseYAxis(report, chart));

              // Create the highcharts series objects.
              chart.series.forEach(function (chartSeries, index) {
                highchartsSeries.push({
                  name: datasetPrefixes[chartSeries.datasetId] + chartSeries.roseName,
                  datasetId: chartSeries.datasetId,
                  type: 'column',
                  pointPadding: 0.1 * (index < 5 ? index : index + 1), // avoid 0.5 Highcharts bug
                  roseName: chartSeries.roseName,
                  roseType: 'wind'
                });
              });
            } else if (chart.isWindClassRose()) {
              // Set wind rose axes.
              axes.x.push(_this.createWindRoseXAxis(report, chart));
              axes.y.push(_this.createWindRoseYAxis(report, chart));

              // Create one highcharts series object per class (there can be only one series here).
              var classSeries = report.getWindClassRoseDataSeries(chart.series[0].datasetId, chart.series[0].roseName);

              angular.forEach(classSeries, function (dataSeries, classIdx) {
                classIdx = parseInt(classIdx, 10);

                highchartsSeries.push({
                  name: datasetPrefixes[dataSeries[0].datasetId] + dataSeries[0].chartDetail.classRange,
                  datasetId: chart.series[0].datasetId,
                  type: 'column',
                  roseName: chart.series[0].roseName,
                  roseType: 'class'
                });
              });
            } else {
              // Set standard datetime x axis.
              axes.x.push({
                type: 'datetime',
                dateTimeLabelFormats: {
                  day: '%e. %b %Y'
                },
                min: _this.globalXMin,
                max: _this.globalXMax,
                events: {
                  afterSetExtremes: afterSetExtremes
                }
              });

              var yAxisIndex = 0;
              var chartUnits = report.getChartUnits(id);
              for (var i = 0; i < chartUnits.length; i++) {
                resolutions.push([]);
                minimums.push([]);
                maximums.push([]);
              }

              // Translate the series array into a Highcharts-compatible one.
              chart.series.forEach(function (chartSeries) {
                // Get full data series object from dataset
                var series = report.getDataSeries(chartSeries.dataSeriesId);

                // These three checks explicitly check for undefined due to 0 being a valid value.
                // Do not change them.
                var axisIdx = chartUnits.indexOf(series.units);
                if (series.resolution !== undefined) {
                  resolutions[axisIdx].push(series.resolution);
                }
                if (series.minimum !== undefined) {
                  minimums[axisIdx].push(series.minimum);
                }
                if (series.maximum !== undefined) {
                  maximums[axisIdx].push(series.maximum);
                }

                // We'll need to know if this chart contains binary series later.
                if (series.type === 6) {
                  yAxisIndex = axes.y.length;

                  // Attempt to use the series units as label, fall back to "false/true";
                  var labels;
                  if (series.units && series.units.indexOf('~') !== -1) {
                    labels = series.units.split('~');
                  } else {
                    labels = ['false', 'true'];
                  }

                  axes.y.push({
                    top: DIGITAL_SERIES_MAX_TOP - (DIGITAL_SERIES_HEIGHT * yAxisIndex),
                    height: DIGITAL_SERIES_HEIGHT,
                    offset: 0,
                    min: 0,
                    max: 1,
                    gridLineWidth: 0,
                    categories: labels,
                    title: false
                  });
                } else {
                  yAxisIndex = axisIdx;
                }

                var highchartSeriesConfig = {
                  id:   series.id,
                  name: datasetPrefixes[series.datasetId] + series.name,
                  data: [],
                  tooltip: {
                    valueSuffix: ' ' + (series.units || '').trim(),
                    valueDecimals: series.resolution < 1 ? series.resolution.toString().length - 2 : 0
                  },
                  yAxis: yAxisIndex
                };

                if (series.chartDetail.chartType === ChartSeriesModel.CHART_TYPE_BAR) {
                  highchartSeriesConfig.type = 'column';
                  highchartSeriesConfig.pointPadding = 0;
                  highchartSeriesConfig.pointPlacement = -0.5;
                  highchartSeriesConfig.groupPadding = 0;
                } else {
                  highchartSeriesConfig.type = 'arearange';
                  highchartSeriesConfig.step = (series.type === 6);
                }

                if (series.isProgramSetting) {
                  highchartSeriesConfig.step = true;
                  highchartSeriesConfig.dashStyle = 'dash';
                }

                highchartsSeries.push(highchartSeriesConfig);
              });
            }

            // Add a default yAxis if none created. i.e. not binary or wind
            if (!axes.y.length) {
              var minRange;
              var floor;
              var ceiling;
              report.getChartUnits(id).forEach(function (unit, axisIdx) {
                if (resolutions.length && resolutions[axisIdx].length) {
                  minRange = resolutions[axisIdx].reduce(function (prev, curr) {
                    return Math.min(prev, curr);
                  });
                  minRange *= 10;
                }
                if (minimums.length && minimums[axisIdx].length) {
                  floor = minimums[axisIdx].reduce(function (prev, curr) {
                    return Math.min(prev, curr);
                  });
                }
                if (maximums.length && maximums[axisIdx].length) {
                  ceiling = maximums[axisIdx].reduce(function (prev, curr) {
                    return Math.max(prev, curr);
                  });
                }

                axes.y.push({
                  title: {
                    text: unit
                  },
                  minRange: minRange,
                  floor: floor,
                  ceiling: ceiling,
                  opposite: axisIdx === 0 ? 0 : 1// alternate between sides to display the unit.
                });

              });
            }

            // Build the chart options.
            var highchartsOptions = _this.createChartOptions(report, chart, highchartsSeries);
            highchartsOptions.xAxis = axes.x;
            highchartsOptions.yAxis = axes.y;

            // Create the chart, show "loading" state.
            var $container = $('#chart-' + id).highcharts(highchartsOptions);
            var highchart = $container.highcharts();
            highchart.showLoading();

            if (chart.isWindRose() || chart.isWindClassRose()) {
              var placeholderOptions = {
                chart: {
                  animation: false,
                  plotBackgroundColor: '#EDF1F5',
                  height: 80,
                  spacing: [0, 0, 0, 0],
                  style: {
                    fontFamily: '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
                  },
                  zoomType: 'x',
                  resetZoomButton: {
                    theme: {
                      display: 'none'
                    }
                  },
                  panning: false
                },

                credits: {
                  enabled: false
                },

                legend: {
                  enabled: false
                },

                plotOptions: {
                  series: {
                    animation: false
                  }
                },

                series: [
                  {
                    data: [],
                    placeholder: true
                  }
                ],

                title: {
                  text: null
                },

                xAxis: {
                  type: 'datetime',
                  dateTimeLabelFormats: {
                    day: '%e. %b %Y'
                  },
                  min: _this.globalXMin,
                  max: _this.globalXMax,
                  events: {
                    afterSetExtremes: afterSetExtremes
                  }
                },

                yAxis: {
                  title: {
                    text: null
                  }
                }
              };

              var placeholderCallback = function (chart) {
                var textElement = chart.renderer.text('Drag a date range to view data', 30, 15);
                textElement.css({
                  color: '#5C7B9C',
                  'font-size': '11px'
                });
                textElement.add();
              };

              $('#chart-' + id + '-placeholder').highcharts(placeholderOptions, placeholderCallback);
            }

            // Return a function to load and populate all of this chart's data series.
            return function () {
              return _this.updateChart(report, highchart, false);
            };
          });

          // Process each loadDataFunctions in order.
          var loadDeferred = $q.defer();

          loadDeferred.promise.then(function () {
            // Resolve the renderReport promise first, as the DOM is now in a good state with
            // all Highcharts instances created and showing "Loading..."
            return renderReportDeferred.resolve(loadDataFunctions);
          }).then(function () {
            stats.start(statName + ':load');
          }).then(function () {
            var loadDataChain = $q.when();

            loadDataFunctions.forEach(function (f, index) {
              loadDataChain = loadDataChain
                .then(function () {
                  stats.start(statName + ':load:chart:' + index);
                })
                .then(f)
                .then(function () {
                  stats.stop(statName + ':load:chart:' + index);

                  // Record time taken to load first chart's data separately.
                  if (index === 0) {
                    stats.stop(statName + ':time-to-first-chart');
                  }
                });
            });

            return loadDataChain;
          }).then(function () {
            stats.stop(statName + ':load');
            stats.stop(statName);
          });

          // Delay starting to load data so the page has time to settle.
          setTimeout(loadDeferred.resolve, 300);
        }, navigator.userAgent.indexOf('MSIE') === -1 ? 0 : 150);

        return renderReportDeferred.promise;
      };

      ReportView.prototype.createChartOptions = function (report, chart, highchartsSeries) {
        var options = {
          chart: {
            animation: false,
            panning: true,
            panKey: 'shift',
            style: {
              fontFamily: '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
            },
            zoomType: 'x',
            resetZoomButton: {
              theme: {
                display: 'none'
              }
            }
          },

          subtitle: {
            text: 'Click and drag to zoom in. Hold down shift key to pan.',
            align: 'left',
            verticalAlign: 'bottom'
          },

          credits: {
            text: "Delta-T Devices Ltd",
            href: "http://www.delta-t.co.uk/DeltaLINK-Cloud.asp"
          },

          plotOptions: {
            series: {
              animation: false
            }
          },

          series: highchartsSeries,

          title: {
            text: null
          },

          tooltip: {
            crosshairs: true,
            shared: true,
            xDateFormat: '%e. %b %Y %H:%M'
          }
        };

        if (chart.isWindRose() || chart.isWindClassRose()) {
          options.chart.polar = true;
          options.chart.type = 'column';

          options.chart.spacing = [10, 20, 15, 25];

          options.plotOptions.series.pointPlacement = 'on';

          options.plotOptions.column = {
            grouping: false,
            shadow: false
          };

          // As we're calculating percentages, the resolution of the data is meaningless.
          options.tooltip.valueDecimals = 2;
          options.tooltip.valueSuffix = '%';

          if (window.innerWidth > 480) {
            // We can't fit the legend on the side at mobile sizes, so only apply this on tablet and above.
            options.legend = {
              align: 'right',
              verticalAlign: 'middle',
              layout: 'vertical'
            };
          }

          if (chart.isWindClassRose()) {

            // https://api.highcharts.com/highcharts/plotOptions.column.stacking
            // Set column stacking to normal, to stack by value. I.e. put chart
            // bars into ascending order of Wind Speed with higher speeds towards
            // outer edge of the chart. I think :)
            options.plotOptions.column.stacking = 'normal';

            if (!options.legend) {
              options.legend = {};
            }

            // Set the title and subtitle on the Wind Class Rose's legend. This
            // results in something like:
            // WindClass2
            // Wind speed
            var legendTitle = '<span style="font-size: 15px; font-weight: normal; color: #676a6c;">' +
              chart.series[0].roseName + '</span><br>' +
              '<span style="font-size: 12px; color: #676a6c; font-weight: normal;">Wind speed</span>';

            // Apply the legend title
            options.legend.title = {
              text: legendTitle
            };
          }
        }

        return options;
      };

      ReportView.prototype.createWindRoseXAxis = function (report, chart) {
        var chartSeries = chart.series[0];
        var dataSeries;

        if (chart.isWindRose()) {
          dataSeries = report.getWindRoseDataSeries(chartSeries.datasetId, chartSeries.roseName);
        } else if (chart.isWindClassRose()) {
          var classDataSeries = report.getWindClassRoseDataSeries(chartSeries.datasetId, chartSeries.roseName);
          dataSeries = classDataSeries['0'];
        } else {
          throw new Error('Invalid chart!');
        }

        var categories = dataSeries.map(function (s) {
          return s.chartDetail.segmentName;
        });

        return {
          tickInterval: 1,
          categories: categories,
          tickmarkPlacement: 'on'
        };
      };

      ReportView.prototype.createWindRoseYAxis = function (report, chart) {
        return {
          min: 0,
          endOnTick: false,
          showLastLabel: true,
          title: {
            text: null
          },
          labels: {
            align: 'center',
            formatter: function () {
              // Omit labels for 0 or lower, as '0%' looks bad in the centre.
              return (this.value <= 0) ? null : this.value + '%';
            }
          },
          reversedStacks: false
        };
      };

      ReportView.prototype.afterSetExtremes = function (report, event) {
        var _this = this;

        // Catch a panning event
        (function (H) {
          H.wrap(H.Chart.prototype, "pan", function (proceed) {
            // Determine on which side you want to pan to
            $(document).on("mousemove", function (mouseEvent) {
              _this.mouseDirection =
                _this.clientXLastPosition > mouseEvent.clientX ? "backward" :
                _this.clientXLastPosition < mouseEvent.clientX ? "forward" : _this.mouseDirection;
              _this.clientXLastPosition = mouseEvent.clientX;
            });

            // Require a little bit more mouse drag in order to pan
            _this.panCounter += 1;
            if (_this.panCounter == 8) {
              _this.panCounter = 0;

              // Pan proportionaly of zoomed-in range (e.g. 2 hrs in daily view, 2 months in yearly etc.)
              var panStrength = (event.max - event.min) / 10;

              // Update the stored extremes.
              if (_this.mouseDirection == "backward") {
                _this.globalXMin = event.min + panStrength;
                _this.globalXMax = event.max + panStrength;
              } else if (_this.mouseDirection == "forward") {
                _this.globalXMin = event.min - panStrength;
                _this.globalXMax = event.max - panStrength;
              }

              // We need an up-to-date reference to all the active highcharts.
              var charts = $(".chart-container")
                .map(function (index, e) {
                  return $(e).highcharts();
                })
                .get();

              // Display the "Loading" message immediately.
              charts.forEach(function (c) {
                c.showLoading();
              });
              // Queue up the chart (series) updates.
              var p = $q.when();

              // Update the chart the user zoomed in on first.
              var eventChart = event.target.chart;
              p = p.then(function () {
                return _this.updateChart(report, eventChart, false);
              });

              charts.forEach(function (c) {
                // Don't update the user-selected chart twice.
                if (c === eventChart) {
                  return;
                }

                p = p.then(function () {
                  return _this.updateChart(report, c);
                });
              });

              proceed.call(this, arguments[1], arguments[2]);
            }
          });
        })(Highcharts);

        if (event.trigger !== 'zoom') {
          // Only update other charts if the *user* actioned this event.
          return;
        }

        this.isZoomed = true;

        // Update the stored extremes.
        _this.globalXMin = event.min;
        _this.globalXMax = event.max;

        // We need an up-to-date reference to all the active highcharts.
        var charts = $('.chart-container')
          .map(function (index, e) {
            return $(e).highcharts();
          })
          .get();

        // Display the "Loading" message immediately.
        charts.forEach(function (c) {
          c.showLoading();
        });

        // Queue up the chart (series) updates.
        var p = $q.when();

        // Update the chart the user zoomed in on first.
        var eventChart = event.target.chart;
        p = p.then(function () {
          return _this.updateChart(report, eventChart, false);
        });

        charts.forEach(function (c) {
          // Don't update the user-selected chart twice.
          if (c === eventChart) {
            return;
          }

          p = p.then(function () {
            return _this.updateChart(report, c);
          });
        });
      };

      ReportView.prototype.processAggregateData = function (data, allowNull) {
        // Filter out null values if they're not allowed.
        if (!allowNull) {
          data = data.filter(function (bucket) {
            return bucket.doc_count > 0;
          });
        }

        return data.map(function (bucket) {
          return [
            moment.utc(bucket.key_as_string).valueOf(),
            bucket.stats.min,
            bucket.stats.max
          ];
        });
      };

      ReportView.prototype.processSumData = function (data, allowNull) {
        // Filter out null values if they're not allowed.
        if (!allowNull) {
          data = data.filter(function (bucket) {
            return bucket.doc_count > 0;
          });
        }

        return data.map(function (bucket) {
          return [
            moment.utc(bucket.key_as_string).valueOf(),
            bucket.stats.sum
          ];
        });
      };

      ReportView.prototype.processRawData = function (data, allowNull) {
        // Filter out "null" values if they're not allowed.
        if (!allowNull) {
          data = data.filter(function (doc) {
            return doc.floatValue !== undefined;
          });
        }

        return data.map(function (doc) {
          return [
            moment.utc(doc.date).valueOf(),
            doc.floatValue !== undefined ? doc.floatValue : null
          ];
        });
      };

      ReportView.prototype.zoomTo = function (report, min, max) {
        var _this = this;

        if (!min && !max) {
          _this.isZoomed = false;
          _this.globalXMin = report.dateRange.firstDate.valueOf();
          _this.globalXMax = report.dateRange.lastDate.valueOf();
        } else {
          _this.isZoomed = true;
          _this.globalXMin = min.valueOf();
          _this.globalXMax = max.valueOf();
        }

        // We need an up-to-date reference to all the active highcharts.
        var charts = $('.chart-container')
          .map(function (index, e) {
            return $(e).highcharts();
          })
          .get();

        // Display the "Loading" message immediately.
        charts.forEach(function (c) {
          c.showLoading();
        });

        // Queue up the chart (series) updates.
        var p = $q.when();

        charts.forEach(function (c) {
          p = p.then(function () {
            return _this.updateChart(report, c);
          });
        });
      };

      ReportView.prototype.zoomGraphToTimeframe = function (report, timeframe) {
        var start;
        var end;

        // Snap to the the user's zoom level or the upper limit of the graph, if the zoom level is
        // outside of that range
        if (this.globalXMax > report.dateRange.lastDate.valueOf()) {
          end = report.dateRange.lastDate.valueOf();
        } else {
          end = this.globalXMax;
        }

        // put our start point where we need it based on the timeframe we have specified
        start = this.getStartTimeFromTimeframe(end, timeframe);

        // if our start point hangs off the left hand side of the graph, snap to the left hand side
        if (start < report.dateRange.firstDate.valueOf()) {
          start = report.dateRange.firstDate.valueOf();
          end = this.getEndTimeFromTimeframe(start, timeframe);
        }

        // If the right hand side is now greater than the data, snap the right hand
        // side to the data.
        if (end > report.dateRange.lastDate.valueOf()) {
          end = report.dateRange.lastDate.valueOf();
          start = report.dateRange.firstDate.valueOf();
        }

        this.zoomTo(report, start, end);
        this.timeframe = timeframe;
      };

      ReportView.prototype.getAutoZoomStartTimeFromTimeframe = function (timestamp, timeframe) {
        // get end date
        var date = moment(timestamp);

        // types: days, weeks, months, years...
        var timeframeType = timeframe.split('-')[1];
        var timeframeNumber = timeframe.split('-')[0];
        switch (timeframeType) {
          case DateRanges.DAY:
          case DateRanges.DAYS:
            return date.subtract(timeframeNumber, 'day').valueOf();
          case DateRanges.WEEK:
          case DateRanges.WEEKS:
            return date.subtract(timeframeNumber, 'week').valueOf();
          case DateRanges.MONTH:
          case DateRanges.MONTHS:
            return date.subtract(timeframeNumber, 'month').valueOf();
          case DateRanges.YEAR:
          case DateRanges.YEARS:
            return date.subtract(timeframeNumber, 'year').valueOf();
        }
      };

      ReportView.prototype.getStartTimeFromTimeframe = function (timestamp, timeframe) {
        var date = moment(timestamp);

        switch (timeframe) {
          case DateRanges.DAY:
            return date.subtract(1, 'day').valueOf();
          case DateRanges.WEEK:
            return date.subtract(1, 'week').valueOf();
          case DateRanges.MONTH:
            return date.subtract(1, 'month').valueOf();
          case DateRanges.THREE_MONTH:
            return date.subtract(3, 'months').valueOf();
          case DateRanges.YEAR:
            return date.subtract(1, 'year').valueOf();
        }
      };

      ReportView.prototype.getEndTimeFromTimeframe = function (timestamp, timeframe) {
        var date = moment(timestamp);

        switch (timeframe) {
          case DateRanges.DAY:
            return date.add(1, 'day').valueOf();
          case DateRanges.WEEK:
            return date.add(1, 'week').valueOf();
          case DateRanges.MONTH:
            return date.add(1, 'month').valueOf();
          case DateRanges.THREE_MONTH:
            return date.add(3, 'months').valueOf();
          case DateRanges.YEAR:
            return date.add(1, 'year').valueOf();
        }
      };

      return ReportView;
    }
  ]);
