angular
  .module('dlcApp.factories')
  .factory('DashboardRepository', [
    'SessionStorage',
    function (SessionStorage) {
      var STORAGE_KEY = 'DashboardRepository.dashboard';

      var DashboardRepository = function () {
        this.dashboard = null;

        var dashboard = SessionStorage.get(STORAGE_KEY);
        if (dashboard) {
          try {
            this.dashboard = JSON.parse(dashboard);
          } catch (e) {
            console.error(e);
          }
        }
      };

      DashboardRepository.prototype.set = function (dashboard) {
        this.dashboard = dashboard;
        SessionStorage.set(STORAGE_KEY, angular.toJson(dashboard));
      };

      DashboardRepository.prototype.get = function () {
        return this.dashboard;
      };

      return new DashboardRepository();
    }
  ]);
