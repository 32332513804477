module.exports = [
  '$translate',
  function ($translate) {
    return function () {
      var highchartsOptionKeys = [
        'contextButtonTitle',
        'decimalPoint',
        'downloadJPEG',
        'downloadPDF',
        'downloadPNG',
        'downloadSVG',
        'loading',
        'months',
        'shortMonths',
        'numericSymbols',
        'printChart',
        'resetZoom',
        'resetZoomTitle',
        'thousandsSep',
        'weekdays'
      ];

      var highchartsLanguageKeys = highchartsOptionKeys.map(function (key) {
        return 'highcharts.' + key;
      });

      // These keys need the value splitting into an array.
      var arrayKeys = [
        'months',
        'shortMonths',
        'numericSymbols',
        'weekdays'
      ];

      return $translate(highchartsLanguageKeys).then(function (translated) {
        var lang = {};

        highchartsLanguageKeys.forEach(function (languageKey, index) {
          var optionKey = highchartsOptionKeys[index];

          if (arrayKeys.indexOf(optionKey) === -1) {
            lang[optionKey] = translated[languageKey];
          } else {
            lang[optionKey] = translated[languageKey].split('_');
          }
        });

        Highcharts.setOptions({
          lang: lang
        });

        Highcharts.charts.forEach(function (chart) {
          if (!chart) {
            return;
          }

          chart.redraw(false);
        });
      });
    };
  }
];
