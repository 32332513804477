(function () {
  var UPDATE_INTERVAL = 300000;
  var REAUTH_INTERVAL = 43200000;
  var RETRY_INTERVAL = 10000;
  var HTTP_TIMEOUT = 20000;

  var dependencies = [
    'ui.router',
    'dlcApp.factories'
  ];

  angular.module('dlcApp.controllers.public.dashboards', dependencies)
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider
          .state('public.dashboard', {
            url: '/dashboards/{token:string}',
            templateUrl: '/views/public/dashboards.html',
            controller: 'DashboardCtrl',
            data: {
              pageTitle: 'Dashboard'
            }
          });
      }
    ])
    .controller('DashboardCtrl', [
      '$rootScope',
      '$scope',
      '$stateParams',
      '$timeout',
      'sharedAPI',
      'api',
      'WidgetManager',
      'NetworkErrorMessage',
      function (
        $rootScope,
        $scope,
        $stateParams,
        $timeout,
        sharedAPI,
        standardAPI,
        WidgetManager,
        NetworkErrorMessage
      ) {
        var api;
        var reauthTimeout;
        var refreshTimeout;

        if ($stateParams.token) {
          api = sharedAPI('dashboard');

          // Allow this controller to control redirects instead of the API itself.
          api.redirectOnError = false;

          api.getSharedDashboard($stateParams.token)
            .then(function (res) {
              api.setToken(res.data, false);
              fetchDashboard(res.data.dashboardId);

              reauthTimeout = $timeout(reauthDashboard, REAUTH_INTERVAL);
              $scope.errorMessage = undefined;
            })
            .catch(function () {
              handleError();
              reauthTimeout = $timeout(reauthDashboard, RETRY_INTERVAL);
            });
        } else {
          api = standardAPI;
          fetchDashboard($stateParams.id);
        }

        $rootScope.$watch('hasDashboardError', function (hasError) {
          if (hasError) {
            handleError();
            return;
          }
          $scope.errorMessage = undefined;
        });

        function setRefreshTimeout(id, timeout) {
          // Don't allow more than one update to be scheduled.
          if (refreshTimeout) {
            return;
          }

          refreshTimeout = $timeout(function () {
            refreshTimeout = false;
            fetchDashboard(id);
          }, timeout);
        }

        function clearRefreshTimeout() {
          if (refreshTimeout) {
            $timeout.cancel(refreshTimeout);
          }

          refreshTimeout = false;
        }

        function reauthDashboard() {
          if (reauthTimeout) {
            $timeout.cancel(reauthTimeout);
          }
          reauthTimeout = false;

          api.getSharedDashboard($stateParams.token, HTTP_TIMEOUT)
            .then(function (res) {
              api.setToken(res.data, false);
              setRefreshTimeout(res.data.dashboardId, UPDATE_INTERVAL);

              reauthTimeout = $timeout(reauthDashboard, REAUTH_INTERVAL);
              $scope.errorMessage = undefined;
            })
            .catch(function () {
              handleError();
              reauthTimeout = $timeout(reauthDashboard, RETRY_INTERVAL);
            });
        }

        function handleError() {
          NetworkErrorMessage.build(NetworkErrorMessage.FAILURE).then((function (error) {
            $scope.errorMessage = error;
          }));
        }

        function fetchDashboard(id) {
          clearRefreshTimeout();
          WidgetManager.clear();

          if (!api.hasToken()) {
            // If there's no token, do nothing since the reauth process will re-schedule automatically.
            return;
          }

          api.getDashboard(id, HTTP_TIMEOUT)
            .then(function (res) {
              $scope.dashboard = res.data;

              setRefreshTimeout(id, UPDATE_INTERVAL);
              $scope.errorMessage = undefined;
            })
            .catch(function (err) {

              if (err.status == 401) {
                reauthDashboard();
              } else {
                setRefreshTimeout(id, RETRY_INTERVAL);
                handleError();
              }
            });
        }

        $scope.$on('$destroy', function () {
          clearRefreshTimeout();

          if (reauthTimeout) {
            $timeout.cancel(reauthTimeout);
            reauthTimeout = false;
          }
        });
      }
    ]);
})();
