module.exports = [
  { ianaId: 'Etc/GMT+12' },
  { ianaId: 'Etc/GMT+11' },
  { ianaId: 'Pacific/Honolulu' },
  { ianaId: 'America/Anchorage' },
  { ianaId: 'America/Santa_Isabel' },
  { ianaId: 'America/Los_Angeles' },
  { ianaId: 'America/Phoenix' },
  { ianaId: 'America/Chihuahua' },
  { ianaId: 'America/Denver' },
  { ianaId: 'America/Guatemala' },
  { ianaId: 'America/Chicago' },
  { ianaId: 'America/Mexico_City' },
  { ianaId: 'America/Regina' },
  { ianaId: 'America/Bogota' },
  { ianaId: 'America/New_York' },
  { ianaId: 'America/Indiana/Indianapolis' },
  { ianaId: 'America/Caracas' },
  { ianaId: 'America/Asuncion' },
  { ianaId: 'America/Halifax' },
  { ianaId: 'America/Cuiaba' },
  { ianaId: 'America/La_Paz' },
  { ianaId: 'America/St_Johns' },
  { ianaId: 'America/Sao_Paulo' },
  { ianaId: 'America/Argentina/Buenos_Aires' },
  { ianaId: 'America/Cayenne' },
  { ianaId: 'America/Godthab' },
  { ianaId: 'America/Montevideo' },
  { ianaId: 'America/Bahia' },
  { ianaId: 'America/Santiago' },
  { ianaId: 'Etc/GMT+2' },
  { ianaId: 'Atlantic/Azores' },
  { ianaId: 'Atlantic/Cape_Verde' },
  { ianaId: 'Africa/Casablanca' },
  { ianaId: 'Etc/UTC' },
  { ianaId: 'Europe/London' },
  { ianaId: 'Atlantic/Reykjavik' },
  { ianaId: 'Europe/Berlin' },
  { ianaId: 'Europe/Budapest' },
  { ianaId: 'Europe/Paris' },
  { ianaId: 'Europe/Warsaw' },
  { ianaId: 'Africa/Lagos' },
  { ianaId: 'Africa/Windhoek' },
  { ianaId: 'Asia/Amman' },
  { ianaId: 'Europe/Bucharest' },
  { ianaId: 'Asia/Beirut' },
  { ianaId: 'Africa/Cairo' },
  { ianaId: 'Asia/Damascus' },
  { ianaId: 'Africa/Johannesburg' },
  { ianaId: 'Europe/Kiev' },
  { ianaId: 'Europe/Istanbul' },
  { ianaId: 'Asia/Jerusalem' },
  { ianaId: 'Europe/Kaliningrad' },
  { ianaId: 'Africa/Tripoli' },
  { ianaId: 'Asia/Baghdad' },
  { ianaId: 'Asia/Riyadh' },
  { ianaId: 'Europe/Moscow' },
  { ianaId: 'Africa/Nairobi' },
  { ianaId: 'Asia/Tehran' },
  { ianaId: 'Asia/Dubai' },
  { ianaId: 'Asia/Baku' },
  { ianaId: 'Indian/Mauritius' },
  { ianaId: 'Asia/Tbilisi' },
  { ianaId: 'Asia/Yerevan' },
  { ianaId: 'Asia/Kabul' },
  { ianaId: 'Asia/Tashkent' },
  { ianaId: 'Asia/Yekaterinburg' },
  { ianaId: 'Asia/Karachi' },
  { ianaId: 'Asia/Kolkata' },
  { ianaId: 'Asia/Colombo' },
  { ianaId: 'Asia/Kathmandu' },
  { ianaId: 'Asia/Almaty' },
  { ianaId: 'Asia/Dhaka' },
  { ianaId: 'Asia/Novosibirsk' },
  { ianaId: 'Asia/Rangoon' },
  { ianaId: 'Asia/Bangkok' },
  { ianaId: 'Asia/Krasnoyarsk' },
  { ianaId: 'Asia/Shanghai' },
  { ianaId: 'Asia/Irkutsk' },
  { ianaId: 'Asia/Singapore' },
  { ianaId: 'Australia/Perth' },
  { ianaId: 'Asia/Taipei' },
  { ianaId: 'Asia/Ulaanbaatar' },
  { ianaId: 'Asia/Tokyo' },
  { ianaId: 'Asia/Seoul' },
  { ianaId: 'Asia/Yakutsk' },
  { ianaId: 'Australia/Adelaide' },
  { ianaId: 'Australia/Darwin' },
  { ianaId: 'Australia/Brisbane' },
  { ianaId: 'Australia/Sydney' },
  { ianaId: 'Pacific/Port_Moresby' },
  { ianaId: 'Australia/Hobart' },
  { ianaId: 'Asia/Magadan' },
  { ianaId: 'Asia/Vladivostok' },
  { ianaId: 'Pacific/Guadalcanal' },
  { ianaId: 'Pacific/Auckland' },
  { ianaId: 'Etc/GMT-12' },
  { ianaId: 'Pacific/Fiji' },
  { ianaId: 'Pacific/Tongatapu' },
  { ianaId: 'Pacific/Apia' },
  { ianaId: 'Pacific/Kiritimati' }
];
