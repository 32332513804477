angular.module('dlcApp.controllers.datasets', ['ui.router', 'datatables'])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('app.datasets', {
        url: '/datasets',
        templateUrl: '/views/datasets/index.html',
        controller: 'DatasetsCtrl',
        data: {
          pageTitle: 'Datasets'
        }
      });
  }])
  .controller('DatasetsCtrl', ['$scope', '$q', 'DLCDefaultOptions', 'DTColumnDefBuilder', 'api',
    function ($scope, $q, DLCDefaultOptions, DTColumnDefBuilder, api) {
      var allDatasets;
      $scope.datasetsLoaded = false;
      $scope.stateIdFilter = null;

      $scope.createTableOptions = function () {
        if ($scope.tableOptions) {
          return;
        }

        DLCDefaultOptions({ 0: 'asc' }).then(function (options) {
          $scope.tableOptions = options;

          $scope.dtColumnDefs = [
            DTColumnDefBuilder.newColumnDef(-1).notSortable(),
            DTColumnDefBuilder.newColumnDef(2).withOption('orderDataType', 'cell-text')
          ];
        });
      };

      // Gets all datasets and puts them in $scope.datasets
      $scope.getDatasets = function () {
        api.getDatasets().then(function (res) {
          $scope.createTableOptions();
          $scope.$emit('DatasetsCtrl.datasetsLoaded');

          // If a dataset has a description, add a truncated version of it as a
          // new property, for use inside Bootstrap popovers
          res.data.forEach(function (dataset) {
            if (dataset.description) {
              var d = dataset.description;
              dataset.truncatedDescription = d.slice(0, 150) + (d.slice(150) && '...');
            }
          });

          $scope.datasetsLoaded = true;
          allDatasets = res.data;
          $scope.datasets = allDatasets;
        });
      };

      $scope.getDatasets();

      // Function to filter Dataset table by state (Imported, Stopped, Logging, and Archived)
      $scope.filterByState = function (stateId, event) {
        event.preventDefault();

        if (stateId == $scope.stateIdFilter) {
          // This filter is already active, disable it
          $scope.datasets = allDatasets;
          $scope.stateIdFilter = null;
          return;
        }

        $scope.datasets = allDatasets.filter(function (dataset) {
          return dataset.state === stateId;
        });

        $scope.stateIdFilter = stateId;
      };
    }
  ]);

