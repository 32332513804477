module.exports = [
  '$scope', '$state', '$stateParams', 'ReportRepository', 'TranslateNotify',
  'stats', 'ChartSeriesModel', 'groupedDataSeriesFilter',
  function (
    $scope, $state, $stateParams, ReportRepository, TranslateNotify,
    stats, ChartSeriesModel, groupedDataSeries
  ) {
    var CHART_UNITS_LIMIT = 3;
    var STEPPED_CHART_SERIES_LIMIT = 4;
    var WINDROSE_CHART_SERIES_LIMIT = 3;

    var BINARY_DATA_TYPE = 6;
    var STEPPED_CHART_TYPE = 7;
    var WINDROSE_CHART_TYPE = 2;
    var WINDCLASSROSE_CHART_TYPE = 3;

    var report = ReportRepository.get();
    $scope.chartIndex = $stateParams.chartIndex;
    $scope.report = report;
    $scope.chart = report.charts[$scope.chartIndex];
    $scope.dataSeriesTypes = [];
    $scope.chartUnits = [];
    $scope.currentlySelectedSeries = [];

    if ($scope.chart.series[0]) {
      $scope.chart.series.forEach(function (series) {
        var units = getSeriesUnits(series);

        if ($scope.chartUnits.indexOf(units) === -1) {
          $scope.chartUnits.push(units);
        }
      });
    }

    var initialSelectedSeries;
    var refreshSelectedSeries = function () {
      $scope.selectedSeries = {};

      // Get all the data series.
      var dataSeries = $scope.chart.series.map(function (chartSeries) {
        if (chartSeries.dataSeriesId) {
          return $scope.report.getDataSeries(chartSeries.dataSeriesId);
        } else {
          return $scope.report.getWindRoseDataSeries(chartSeries.datasetId, chartSeries.roseName)[0];
        }
      });

      // Group them, build the selected hash.
      groupedDataSeries(dataSeries).forEach(function (dataSeries) {
        $scope.selectedSeries[dataSeries.id] = true;
      });

      // Make a copy of the ID indexed series object to use as a hash table for
      // applying changes, to check if the chart already holds a series.
      initialSelectedSeries = angular.copy($scope.selectedSeries);
    };

    refreshSelectedSeries();

    /**
     * Helper function to get series units, supports "fake" units for wind rose and wind class rose.
     *
     * @param {object} series ChartSeriesModel or data series.
     *
     * @return {string}
     */
    function getSeriesUnits(series) {
      if (series instanceof ChartSeriesModel) {
        if (series.dataSeriesId) {
          series = $scope.report.getDataSeries(series.dataSeriesId);
        } else if (series.roseName) {
          series = $scope.report.getWindRoseDataSeries(series.datasetId, series.roseName)[0];
        }
      }

      if (series.chartDetail.classIdx !== undefined) {
        // Make up a unique unit for wind class rose series so they can only be charted on their own.
        return 'wind-class-rose-' + series.id;
      } else if (series.chartDetail.segmentIdx !== undefined) {
        // Allow regular wind roses (with the same number of segments) to be added to the same chart.
        return 'wind-rose-' + series.chartDetail.noSegments;
      } else {
        // Fall back to actual reported units for all other series.
        return series.units;
      }
    }

    $scope.updateChartUnits = function () {
      $scope.chartUnits = [];
      $scope.dataSeriesTypes = [];
      $scope.currentlySelectedSeries = [];

      angular.forEach($scope.selectedSeries, function (isSelected, seriesId) {
        // Ensure the user cannot add more than two chart units
        if (isSelected) {
          var dataseries = $scope.report.getDataSeries(seriesId);
          var units = getSeriesUnits(dataseries);

          if ($scope.chartUnits.indexOf(units) === -1) {
            $scope.chartUnits.push(units);
          }

          // Extract the keys of dataseries that are currently selected,
          // not the ones that have been selected in the past session.
          $scope.currentlySelectedSeries.push(seriesId);
          // need to pretend to be stepped if value type is binary as well... bug in dataset data coming from DTAPI
          $scope.dataSeriesTypes.push(
            dataseries.type === BINARY_DATA_TYPE ? STEPPED_CHART_TYPE : dataseries.chartDetail.chartType);
        }
      });
    };

    $scope.isDataSeriesAvailable = function (dataSeries) {
      var units = getSeriesUnits(dataSeries);
      // If this dataset is already selected, I can de-select it.
      if ($scope.selectedSeries[dataSeries.id]) {
        return true;
      }

      // need to check if is it binary value type as well... bug in dataset data coming from DTAPI
      if (dataSeries.chartDetail.chartType === STEPPED_CHART_TYPE || dataSeries.type === BINARY_DATA_TYPE) {
        if ($scope.dataSeriesTypes.length > 0 && $scope.dataSeriesTypes.indexOf(STEPPED_CHART_TYPE) === -1) {
          return false;
        }

        if ($scope.currentlySelectedSeries.length >= STEPPED_CHART_SERIES_LIMIT) {
          return false;
        }

        return true;
      }

      if ($scope.dataSeriesTypes.indexOf(STEPPED_CHART_TYPE) !== -1) {
        return false;
      }

      if (dataSeries.chartDetail.chartType === WINDROSE_CHART_TYPE) {
        if ($scope.dataSeriesTypes.length > 0 && $scope.dataSeriesTypes.indexOf(WINDROSE_CHART_TYPE) === -1) {
          return false;
        }

        if ($scope.currentlySelectedSeries.length >= WINDROSE_CHART_SERIES_LIMIT) {
          return false;
        }
      }

      if ($scope.dataSeriesTypes.indexOf(WINDROSE_CHART_TYPE) !== -1) {
        return false;
      }

      if (
        dataSeries.chartDetail.chartType === WINDCLASSROSE_CHART_TYPE &&
        $scope.currentlySelectedSeries.length > 0
      ) {
        return false;
      }

      if ($scope.dataSeriesTypes.indexOf(WINDCLASSROSE_CHART_TYPE) !== -1) {
        return false;
      }

      if ($scope.chartUnits.length >= CHART_UNITS_LIMIT && $scope.chartUnits.indexOf(units) === -1) {
        return false;
      }

      return true;
    };

    $scope.getSeriesIndex = function (seriesId) {
      var matchingSeriesIndex = null;

      $scope.chart.series.some(function (series, seriesIndex) {
        if (series.dataSeriesId == seriesId) {
          matchingSeriesIndex = seriesIndex;
          return true;
        }
      });
      return matchingSeriesIndex;
    };

    $scope.applyChanges = function () {
      var binarySeriesCount = 0;

      // Check how many binary series have been selected.
      angular.forEach($scope.selectedSeries, function (series, seriesId) {
        if (series && $scope.report.getDataSeries(seriesId).type == 6) {
          ++binarySeriesCount;
        }
      });

      angular.forEach($scope.selectedSeries, function (series, seriesId) {
        if (series) {
          // Confirm we don't already have this series in the chart
          if (!initialSelectedSeries[seriesId]) {
            // The chart does not currently hold this series

            // Load in real series object
            var realSeries = $scope.report.getDataSeries(seriesId);

            var seriesOptions = {
              datasetId: realSeries.datasetId,
              chartType: realSeries.chartDetail.chartType
            };

            if (realSeries.chartDetail.roseName) {
              seriesOptions.roseName = realSeries.chartDetail.roseName;
            } else {
              seriesOptions.dataSeriesId = seriesId;
            }

            $scope.chart.addSeries(new ChartSeriesModel(seriesOptions));

            $scope.report.refreshLookupHashes();
          }
        } else {
          // Confirm this series is actually assigned to the chart
          // before trying to delete it
          if (initialSelectedSeries[seriesId]) {
            // Remove series from chart
            $scope.chart.series.splice($scope.getSeriesIndex(seriesId), 1);
          }
        }
      });

      // If this chart no longer has any series in it, remove it
      if ($scope.chart.series.length === 0) {
        $scope.report.deleteChart($scope.chartIndex);
      }

      // These aren't needed for day-to-day users, but makes dev easier (makes refresh work).
      ReportRepository.set($scope.report);
      refreshSelectedSeries();

      // Track update
      stats.sendEvent('Report', 'Edit chart dataseries');

      $state.go('app.reports.draft');
    };

    $scope.updateChartUnits();
  }
];
