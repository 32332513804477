module.exports = [
  '$interval', '$modal', 'WidgetTypes', 'DataseriesTypes', 'WidgetManager', 'DashboardRepository',
  function ($interval, $modal, WidgetTypes, DataseriesTypes, WidgetManager, DashboardRepository) {
    return {
      scope: {
        getDashboard: '&dashboard',
        widget: '=dashboardWidget',
        editable: '=dashboardWidgetEditable',
        index: '=dashboardWidgetIndex'
      },
      templateUrl: '/views/widgets/widget.html',
      link: function (scope, element, attr) {
        var intervalId = null;

        scope.$on('$destroy', function () {
          if (intervalId) {
            $interval.cancel(intervalId);
            intervalId = null;
          }
        });

        attr.$addClass('dashboard-widget-container');
        attr.$addClass('dashboard-widget-' + scope.index);

        scope.deleteWidget = function (index) {
          var dashboard = scope.getDashboard();
          var widget = dashboard.widgets[scope.index];

          var modal = $modal.open({
            templateUrl: '/views/dashboards/dashboard_delete_widget.html',
            controller: ['$scope', function ($scope) {
              $scope.widget = widget;
            }]
          });

          modal.result.then(function () {
            dashboard.widgets.splice(index, 1);
            DashboardRepository.set(dashboard);
          });
        };

        function getRangeOfColorsForDataseries(count) {
          if (count === 1) {
            return ['rgb(71, 110, 156)'];
          }

          var darkestColor = [22, 48, 100];
          var lightestColor = [146, 204, 242];

          var rStep = (lightestColor[0] - darkestColor[0]) / (count - 1);
          var gStep = (lightestColor[1] - darkestColor[1]) / (count - 1);
          var bStep = (lightestColor[2] - darkestColor[2]) / (count - 1);

          var colors = [];
          for (var i = 0; i < count; ++i) {
            colors.push('rgb(' +
              Math.trunc(lightestColor[0] - rStep * i) +
              ', ' +
              Math.trunc(lightestColor[1] - gStep * i) +
              ', ' +
              Math.trunc(lightestColor[2] - bStep * i) +
              ')'
            );
          }
          return colors;
        }

        function bindColorsToDataseries(dataseries) {
          var colors = getRangeOfColorsForDataseries(dataseries.length);

          dataseries = dataseries.map(function (ds, idx) {
            ds.color = colors[idx];
            return ds;
          });

          return dataseries;
        }


        function createFakeDataFunction() {
          if (scope.widget.type === WidgetTypes.GAUGE) {
            return function (value) {
              scope.hasData = true;
              scope.data = [{ date: moment().format(), floatValue: value }];
            };
          }

          if (scope.widget.type === WidgetTypes.LINE) {
            return function (value, isBinary) {
              scope.hasData = true;
              scope.widget.detail.dataStartDT = moment().subtract(5, 'day').format();
              scope.widget.detail.dataEndDT = moment().format();

              scope.data = [];
              for (var i = 5; i >= 0; --i) {
                var binaryValue = (i % 2) === 0.0 ? 1 : 0;
                var floatValue = (6 - i) / 6 * value;
                scope.data.push({
                  date: moment().subtract(i, 'day').format(),
                  floatValue: isBinary ? binaryValue : floatValue
                });
              }
            };
          }

          if (scope.widget.type === WidgetTypes.WINDROSE) {
            return function (value) {

              // Make the view (windrose-widget.html) hide the preloader, and show the chart content
              scope.hasData = true;
              // Add start and end dates, similarly to how it's done by a Line Chart
              // widget, so that new Windrose widgets know that Time Span needs to
              // be chosen on their edit page before the Apply button validates and
              // becomes active. See $translate(timeSpanPeriodKeys) inside
              // app/js/controllers/dashboards.js
              // These values come out as current timestamp, and current - 5 days:
              // 2018-08-17T11:42:05+01:00
              // 2018-08-22T11:42:05+01:00
              scope.widget.detail.dataStartDT = moment().subtract(5, 'day').format();
              scope.widget.detail.dataEndDT = moment().format();

              // Fake data that'll populate the windrose when it is added to the
              // dashboard and the user has not yet connected real data to it
              scope.data = bindColorsToDataseries([
                {
                  name: '<1.5m/s',
                  data: [8, 5, 6, 15, 2, 13, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                  name: '1.5-3.3m/s',
                  data: [3, 3, 12, 15, 12, 1, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                  name: '3.3-5.4m/s',
                  data: [5, 2, 10, 12, 6, 2, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                  name: '5.4-7.9m/s',
                  data: [0, 0, 5, 7, 12, 2, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                  name: '7.9-10.7m/s',
                  data: [3, 3, 5, 6, 8, 3, 1, 0, 0, 0, 0, 0, 0]
                },
                {
                  name: '>10.7m/s',
                  data: [0, 0, 0, 5, 6, 0, 0, 0, 0, 0, 0, 0, 0]
                }
              ]);
            };
          }

          if (scope.widget.type === WidgetTypes.TEXT && scope.widget.detail.showHistory) {
            return function (value, isBinary, isSweep) {
              var fakeValue = function (index) {
                if (isBinary) {
                  return (index % 2) === 0 ? 1 : 0;
                }

                var base = value * (1 / 6);
                return base * (index + 1);
              };

              scope.hasData = true;
              scope.data = [{ date: moment().format(), floatValue: isBinary ? 0.0 : value }];
              if (!isSweep) {
                scope.filteredData = [
                  { date: moment().subtract(5, 'day').format(), durationSeconds: 86400, floatValue: fakeValue(0) },
                  { date: moment().subtract(6, 'day').format(), durationSeconds: 86400, floatValue: fakeValue(1) },
                  { date: moment().subtract(7, 'day').format(), durationSeconds: 86400, floatValue: fakeValue(2) }
                ];
              }
            };
          }

          if (scope.widget.type === WidgetTypes.TEXT) {
            return function (value) {
              scope.hasData = true;
              scope.data = [{ date: moment().format(), floatValue: value }];
            };
          }

          console.error('Could not create fakeData function for unhandled type', scope.widget.type);
          return function () {};
        }

        scope.DataseriesTypes = DataseriesTypes;
        scope.type = WidgetTypes.getName(scope.widget.type).toLowerCase();

        // This is the default color, but do _not_ set it using CSS classes,
        // due to a workaround for BigText removing style attributes.
        var defaultColor = scope.widget.type === WidgetTypes.LINE ? '#ffffff' : '#0e9aef';
        scope.thresholdColor = scope.widget.color || defaultColor;
        scope.$watch('widget.color', function (value, oldValue) {
          // Don't change the color if a threshold has overridden it.
          if (scope.thresholdColor === oldValue) {
            scope.thresholdColor = value;
          }
        });

        function animateWidget() {
          if (!scope.widget.detail.data.dataseries) {
            return;
          }

          var first = scope.widget.detail.data.dataseries[0];
          var minimum = isNaN(scope.widget.detail.minimum) || scope.widget.detail.minimum === null ?
            first.minimum : scope.widget.detail.minimum;
          var maximum = isNaN(scope.widget.detail.maximum) || scope.widget.detail.maximum === null ?
            first.maximum : scope.widget.detail.maximum;

          var range = {
            min: minimum,
            max: maximum
          };

          if (scope.widget.type === WidgetTypes.LINE || scope.widget.type === WidgetTypes.WINDROSE) {
            // No need to sweep fake data for line charts since the thresholds are only used to create color bands.
            return;
          }

          var value = Math.round(minimum + ((maximum - minimum) / 3.0));
          setFakeData(value, scope.widget.type === DataseriesTypes.BINARY);
          scope.dataseries = first;

          if (intervalId) {
            $interval.cancel(intervalId);
          }

          var FULL_SWEEP_SECONDS = 5.0;
          var UPDATE_FRAME_RATE;

          // Scale the frame rate back for tablet/mobile devices.
          if (document.body.offsetWidth <= 400) {
            UPDATE_FRAME_RATE = 5.0;
          } else if (document.body.offsetWidth <= 768) {
            UPDATE_FRAME_RATE = 12.0;
          } else {
            UPDATE_FRAME_RATE = 24.0;
          }

          var direction = (range.max - range.min) / FULL_SWEEP_SECONDS / UPDATE_FRAME_RATE;
          intervalId = $interval(function () {
            value += direction;
            if (value >= range.max || value <= range.min) {
              direction *= -1;
            }

            var safeValue = Math.max(range.min, Math.min(range.max, value));
            setFakeData(safeValue, first.type === DataseriesTypes.BINARY, true);
            scope.thresholdColor = WidgetManager.getThresholdColor(scope.widget, safeValue);
          }, 1000 / UPDATE_FRAME_RATE);
        }

        if (scope.widget.placeholder) {
          var setFakeData = createFakeDataFunction();
          setFakeData(30.0);

          scope.$watch('widget.detail.data.dataseries', function (ds) {
            if (!setFakeData) {
              return;
            }

            animateWidget();
          });

          // when the minimum or maximum value changes on a widget,
          // restart the animation with the new start and end point
          scope.$watch('widget.detail.minimum', function () {
            animateWidget();
          });

          scope.$watch('widget.detail.maximum', function () {
            animateWidget();
          });

        } else if (scope.widget.detail.data.dataseries) {
          //
          // "Real" saved widgets (i.e. HAVE had real data connected)
          //

          // The widget needs data from a dataseries, so register this widget with the manager to receive data.

          if (scope.widget.type === WidgetTypes.WINDROSE) {
            scope.dataseries = scope.widget.detail.data.dataseries;
          } else {
            scope.dataseries = scope.widget.detail.data.dataseries[0];
          }

          // Calculate the number of decimal places to use for formatting values.
          var res = scope.dataseries.resolution;
          scope.dataDecimalPlaces = res < 1 ? res.toString().length - 2 : 0;

          WidgetManager.register(scope.widget, function (data) {
            // If we have an array of arrays, we are looking at raw data, otherwise we have aggregated data.
            // Aggregated data comes back as an array of objects.

            // Unpack raw data now, we handle aggregated data later.
            if (Array.isArray(data[0])) {
              // At this point, for raw datasets we only need the first item as we do not handle multiple datasets.
              data = data[0];
            }

            if (scope.widget.type === WidgetTypes.WINDROSE) {
              var series = {};
              var dataseries = scope.widget.detail.data.dataseries;

              var roseTotal = data.map(function (d) {
                return d.stats.sum;
              }).reduce(function (prev, curr) {
                return prev + curr;
              });

              data.forEach(function (value, idx) {
                var ds = dataseries[idx];

                // Group our aggregated data by the class range they correspond to.
                if (!series[ds.chartDetail.classRange]) {
                  series[ds.chartDetail.classRange] = {
                    name: ds.chartDetail.classRange,
                    data: []
                  };
                }

                var val = Number.parseFloat(((value.stats.sum / roseTotal) * 100).toFixed(2));
                series[ds.chartDetail.classRange].data.push(val);
              });

              data = bindColorsToDataseries(Object.values(series));
            }

            scope.data = data;
            scope.hasData = true;

            if (Array.isArray(data) && data.length > 0) {
              if (data[data.length - 1].date !== undefined) {
                scope.lastUpdated = data[data.length - 1].date;
              } else if (data[data.length - 1].key_as_string !== undefined) {
                // Handle aggregated data.
                scope.lastUpdated = data[data.length - 1].key_as_string;
              }

              // Handle time offsets for widgets.
              if (scope.getDashboard) {
                var dashboard = scope.getDashboard();
                var currentDatasetId = scope.widget.detail.data.dataseries[0].datasetId;

                var currentDatasetOffset;

                if (dashboard.datasets) {
                  dashboard.datasets.some(function (dataset) {
                    if (dataset.id === currentDatasetId) {
                      currentDatasetOffset = dataset.timeOffset;
                      return true;
                    }
                  });
                }

                currentDatasetOffset = currentDatasetOffset || scope.widget.timeOffset;

                var timeFormat = 'YYYY-MM-DDTHH:mm:ss';

                if (currentDatasetOffset) {
                  scope.lastUpdated = moment(scope.lastUpdated)
                    .utcOffset(currentDatasetOffset)
                    .format(timeFormat); // Enforce a format to remove timezone information
                } else {
                  // Remove zulu time signifier
                  scope.lastUpdated = moment.utc(scope.lastUpdated)
                    .format(timeFormat);
                }
              }


              if (scope.widget.thresholds) {
                var val = data[data.length - 1].floatValue;
                scope.thresholdColor = WidgetManager.getThresholdColor(scope.widget, val);
              }
            }

            var previousDateMS;

            // If the widget required is a Wind Rose, we don't need to filter
            // the data further at this point
            if (scope.widget.type === WidgetTypes.WINDROSE) {
              scope.filteredData = data;
            } else {
              scope.filteredData = data
              .filter(function (d, idx, all) {
                if (idx === 0) {
                  return true;
                }

                return d.floatValue != all[idx - 1].floatValue;
              })
              .map(function (d, idx, all) {
                var currentDateMS = Date.parse(d.date);

                // We have nothing to compare the first item to, so skip it.
                if (idx > 0) {
                  // Note that we're updating the *previous* item's duration.
                  all[idx - 1].durationSeconds = Math.round((currentDateMS - previousDateMS) / 1000);
                }

                previousDateMS = currentDateMS;

                return d;
              })
              .filter(function (d) {
                return d.durationSeconds;
              });
            }
          });
        }
      }
    };
  }
];
