module.exports = [
  '$compile',
  function ($compile) {
    return {
      restrict: 'A',
      scope: {
        template: '=compile',
        vars: '&compileVars'
      },
      link: function (scope, el) {
        var v = scope.vars();
        delete scope.vars;

        // Promote any vars up to the root of our isolate scope.
        angular.forEach(v, function (value, key) {
          scope[key] = value;
        });

        // We have to wrap the HTML we're given in an enclosing element else leading/trailing text is lost.
        var template = '<span>' + scope.template + '</span>';

        // Assign the compiled and bound template into the element.
        el.html($compile(template)(scope));
      }
    };
  }
];
