angular
  .module('dlcApp.factories')
  .factory('loggerStatus', [
    function () {
      function processPower(logger) {
        if (!logger.status.power || !logger.status.power.value) {
          return;
        }

        var powerWidget = {
          nameKey: 'devices.statuses.Power',
          icon: 'fa-bolt'
        };

        var powerBadge = {
          nameKey: 'devices.statuses.Power'
        };

        var power = logger.status.power.value;

        if (power < 6.6) {
          // Power status is below 6.6V
          powerWidget.classes = 'red-bg';
          powerBadge.classes = 'text-danger';

          powerWidget.textKey = powerBadge.textKey = 'devices.logger.powerStates.low';
        } else {
          powerWidget.classes = 'navy-bg';
          powerBadge.classes = 'text-navy';

          powerWidget.textKey = powerBadge.textKey = 'devices.logger.powerStates.ok';
        }

        logger.widgets.push(powerWidget);
        logger.badges.push(powerBadge);
      }

      function processState(logger) {
        if (logger.state === 3) {
          // Confirmed
          logger.stateLabel = {
            icon: 'fa-check',
            classes: 'label-primary',
            textKey: 'devices.states.confirmed'
          };
        } else if (logger.state === 2) {
          // Problem
          logger.stateLabel = {
            icon: 'fa-warning',
            classes: 'label-danger',
            textKey: 'devices.states.problem'
          };
        } else if (logger.state === 1) {
          // Pending
          logger.stateLabel = {
            icon: 'fa-clock-o',
            textKey: 'devices.states.pending'
          };
        } else {
          // Unknown / invalid?
          logger.stateLabel = {
            icon: 'fa-clock-o',
            textKey: 'devices.states.unknown'
          };
        }
      }

      function processLogging(logger) {
        // If there's no Logging status, then do nothing.
        if (!logger.status.logging) {
          return;
        }

        var loggingWidget = {
          nameKey: 'devices.logger.loggingStatus',
          icon: false,
          classes: false,
          textKey: false
        };

        var loggingBadge = {
          nameKey: 'devices.logger.loggingStatus',
          classes: false,
          textKey: false
        };

        // For output on the Manage devices page, as well as the logger details page
        if (logger.status.logging.value) {
          loggingWidget.icon = 'fa-play';
          loggingWidget.classes = 'navy-bg';

          loggingBadge.classes = 'text-navy';

          loggingBadge.textKey = loggingWidget.textKey = 'devices.logger.loggingStatuses.logging';
        } else {
          loggingWidget.icon = 'fa-stop';
          loggingWidget.classes = 'yellow-bg';

          loggingBadge.classes = 'text-warning';

          loggingBadge.textKey = loggingWidget.textKey = 'devices.logger.loggingStatuses.stopped';
        }

        logger.widgets.push(loggingWidget);
        logger.badges.push(loggingBadge);

      }

      return function (logger) {
        logger.status = {};
        logger.badges = [];
        logger.widgets = [];

        if (logger.statuses) {
          logger.statuses.forEach(function (item) {
            logger.status[item.name.toLowerCase()] = item;
          });

          delete logger.statuses;
        }

        processState(logger);
        processPower(logger);
        processLogging(logger);
      };
    }
  ]);
