module.exports = [
  '$state', '$translate', 'DashboardRepository', 'PermissionTypes',
  function ($state, $translate, DashboardRepository, PermissionTypes) {
    $translate(['dashboards.new.name', 'dashboards.new.description'])
      .then(function (t10s) {
        // Create a new dashboard object with translated name and description
        var dashboard = {
          name: t10s['dashboards.new.name'],
          description: t10s['dashboards.new.description'],
          accessLevel: PermissionTypes.OWNER,
          creator: '', // TODO: set this to the username/email of the currently logged-in user?
          widgets: []
        };

        // Store the translated dashboard in its repository
        DashboardRepository.set(dashboard);

        // Redirect to the draft page to display the dashboard
        $state.go('app.dashboards.draft');
      });
  }
];
