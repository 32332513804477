(function (root) {
  module.exports = [
    'MemoryStorage',
    function (MemoryStorage) {
      var BaseStorage = require('./base');

      var testKey = 'test-storage-' + Date.now();

      try {
        root.sessionStorage.setItem(testKey, testKey);
        if (root.sessionStorage.getItem(testKey) !== testKey) {
          throw new Error('Failed to use sessionStorage');
        }
        return new BaseStorage(root.sessionStorage);
      } catch (e) {
        console.error('Session storage unavailable', e.message);
      }

      return MemoryStorage;
    }
  ];
}(typeof window !== 'undefined' ? window : global));
