(function () {
  var dependencies = [
    'ui.router',
    'dlcApp.controllers.authentication.register',
    'dlcApp.controllers.authentication.forgotten-password',
    'dlcApp.controllers.authentication.set-password',
    'dlcApp.controllers.authentication.login',
    'dlcApp.controllers.authentication.logout'
  ];

  angular.module('dlcApp.controllers.authentication', dependencies)
    .config(['$stateProvider', function ($stateProvider) {
      $stateProvider
        .state('authentication', {
          abstract: true,
          url: '/auth',
          templateUrl: '/views/common/auth_layout.html'
        });
    }]);

  require('./authentication/register');

  require('./authentication/forgotten-password');
  require('./authentication/set-password');

  require('./authentication/login');
  require('./authentication/logout');
})();
