module.exports = [
  'weekdays', 'repeatRates',
  function (weekdays, repeatRates) {
    return {
      restrict: 'E',
      scope: {
        settings: '='
      },
      templateUrl: '/views/devices/gateway_sync_schedule.html',
      link: function (scope, elem, attr) {
        scope.weekdays = weekdays;
        scope.repeatRates = repeatRates;
      }
    };
  }
];

