angular.module('dlcApp.controllers.authentication.login', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('authentication.login', {
        url: '/login',
        templateUrl: '/views/authentication/login.html',
        controller: 'LoginCtrl',
        data: {
          pageTitle: 'Login'
        }
      });
  }])
  .controller('LoginCtrl', [
    '$scope', '$state', '$modal', '$translate', 'notify', 'api',
    function ($scope, $state, $modal, $translate, notify, api) {
      $scope.user = {
        email: null,
        password: null
      };

      $scope.login = function () {
        api.login($scope.user.email, $scope.user.password)
          .then(function (res) {
            $state.go('app.welcome');
          }, function (err) {
            console.error(JSON.stringify(err));

            $scope.password = '';

            $translate('auth.login.emailOrPasswordIncorrect').then(function (message) {
              notify({
                message: message,
                classes: 'alert-danger'
              });
            });
          });
      };
    }
  ]);
