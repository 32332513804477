/**
 * Examples:
 * <div loading-spinner></div>
 *
 * <div loading-spinner loading-spinner-translate-title="general.loading"></div>
 */

module.exports = [
  function () {
    return {
      restrict: 'A',
      templateUrl: '/views/common/loading-spinner.html',
      link: function (scope, element, attr) {
        scope.title = attr.loadingSpinnerTranslateTitle || 'general.loading';
      }
    };
  }
];
