/**
 * This object manages the data requests for widgets, reducing the number of requests
 * we need to make to populate a dashboard.
 */
angular
  .module('dlcApp.factories')
  .factory('WidgetManager', [
    '$rootScope', '$timeout', 'sharedAPI', 'WidgetTypes',
    function ($rootScope, $timeout, sharedAPI, WidgetTypes) {
      var TYPE_WIDGET = 0;
      var TYPE_THRESHOLD = 1;

      var WidgetManager = function () {
        this.clear();
      };

      /**
       * Register a widget and a callback to receive data when the fetch occurs.
       *
       * @param {Widget} widget
       * @param {Function} callback
       *
       * GetDataModel: {
       *   datasetId: Number,
       *   dataSeriesId: Number,
       *   from: ?Moment,
       *   to: ?Moment,
       *   limit: ?Number
       * }
       *
       * @return {void}
       */
      WidgetManager.prototype.register = function (widget, callback) {
        var _this = this;

        // If we were already scheduled to update, cancel that timeout.
        if (this.timeout) {
          $timeout.cancel(this.timeout);
        }

        // There's one instance of the WidgetManager, so there's only one requests
        // array, which will hold all series for all widgets currently displayed
        // in the browser.
        _this.requests.push({
          type: TYPE_WIDGET,
          series: _this.createRequestSeries(widget),
          callback: callback
        });

        // Also schedule a fetch for any dataseries thresholds.
        if (widget.thresholds) {
          widget.thresholds.forEach(function (t) {
            if (!t.data.dataseries) {
              return;
            }

            var ds = t.data.dataseries;

            _this.requests.push({
              type: TYPE_THRESHOLD,
              series: {
                datasetId: ds.datasetId,
                dataSeriesIds: [ds.id],
                limit: 1
              },
              callback: function (data) {
                t.data.number = data[0].floatValue;
              }
            });
          });
        }

        // 500ms after the _last_ call to register, we send the aggregated requests.
        // This type of construct is often referred to as a "debounce".
        this.timeout = $timeout(function () {
          _this.update();
        }, 500);
      };

      /**
       * update - This will run when the dashboard page loads
       * @return {void}
       */
      WidgetManager.prototype.update = function () {
        var requests = this.requests;
        this.clear();

        var api = sharedAPI('dashboard');

        var req = requests.map(function (w) {
          return w.series;
        });

        // getLatestValues function returns a Promise, which is why you can chain
        // a .then onto it
        api.getLatestValues(req)
          .then(function (res) {

            // We have to do two passes because widgets may need their threshold
            // data during processing of the value data.
            requests.forEach(function (w, idx) {
              if (w.type === TYPE_THRESHOLD) {
                w.callback(res.data[idx]);
              }
            });

            requests.forEach(function (w, idx) {
              if (w.type === TYPE_WIDGET) {
                w.callback(res.data[idx]);
              }
            });

            // Broadcast whether we have an error or not in order to show alerts
            $rootScope.hasDashboardError = false;
          })
          .catch(function () {
            $rootScope.hasDashboardError = true;
          });
      };

      WidgetManager.prototype.clear = function () {
        this.requests = [];
      };

      /**
       * createRequestSeries
       * @param {GetWidgetModel} widget
       * @return {SeriesRequest} A single request for a set of dataseries
       */
      WidgetManager.prototype.createRequestSeries = function (widget) {
        var api = sharedAPI('dashboard');
        var detail = widget.detail;

        var from = null;
        var to = null;
        var limit = null;
        var extendOneValue = null;
        var bucketSize = null;

        var firstDS = detail.data.dataseries[0];
        var ids = detail.data.dataseries.map(function (ds) {
          return ds.id;
        });
        if (widget.type === WidgetTypes.WINDROSE) {
          from = moment(detail.dataStartDT);
          to = moment(detail.dataEndDT);
          extendOneValue = false;
        } else if (detail.dataStartDT && detail.dataEndDT) {
          from = moment(detail.dataStartDT);
          to = moment(detail.dataEndDT);
          bucketSize = api.calcChartBucketSize(firstDS, from, to, 100);
          extendOneValue = bucketSize === false;
        } else if (widget.type === WidgetTypes.TEXT && widget.detail.showHistory) {
          limit = 5;
        } else {
          limit = 1;
        }

        return {
          datasetId: firstDS.datasetId,
          dataSeriesIds: ids,
          from: from,
          to: to,
          limit: limit,
          extendOneValue: extendOneValue,
          bucketSize: bucketSize,
          aggregate: widget.type === WidgetTypes.WINDROSE // Windroses always need to use aggregated data, not raw data
        };
      };

      /**
       * getThresholdColor
       * @param {GetWidgetModel} widget
       * @param {number} val
       *
       * @return {string} Hex representation of a color
       */
      WidgetManager.prototype.getThresholdColor = function (widget, val) {
        var c = widget.color;
        // Thresholds need to be sorted for the 'some' code below to work.
        var sorted = widget.thresholds.slice().sort(function (a, b) {
          if (a.data.number === b.data.number) {
            return 0;
          }

          return parseFloat(a.data.number, 10) < parseFloat(b.data.number, 10) ? -1 : 1;
        });

        sorted.map(function (threshold, index) {
          // group thresholds with a start and end time.
          var groupedThreshold = {
            color: threshold.color,
            start: threshold.data.number
          };

          // as we sorted this earlier, this will be the next threshold in chronological order.
          var next = sorted[index + 1];

          if (typeof next !== 'undefined') {
            groupedThreshold.end = next.data.number;
          }

          return groupedThreshold;
        }).some(function (t) {
          // Need to work around "invalid" thresholds when editing widgets.
          if (isNaN(t.start)) {
            return false;
          }

          if (isNaN(t.end)) {
            if (val >= t.start) {
              c = t.color;
              return true;
            }
          } else {
            if (val >= t.start && val <= t.end) {
              c = t.color;
              return true;
            }
          }
        });

        return c;
      };

      return new WidgetManager();
    }
  ]);
