(function () {
  var dependencies = [
    'ui.router',
    'dlcApp.controllers.public.dashboards',
    'dlcApp.controllers.public.report'
  ];

  angular.module('dlcApp.controllers.public', dependencies)
    .config(['$stateProvider', function ($stateProvider) {
      $stateProvider
        .state('public', {
          abstract: true,
          url: '/public',
          templateUrl: '/views/common/public_layout.html'
        })
        .state('public.test', {
          url: '',
          template: ''
        });
    }]);

  require('./public/dashboards.js');
  require('./public/report.js');
})();
