angular
  .module('dlcApp.factories')
  .factory('ReportModel', [
    'api', '$q', 'ChartModel', 'ChartSeriesModel',
    function (api, $q, ChartModel, ChartSeriesModel) {
      var ReportModel = function (options, apiInstance) {
        var _this = this;

        _this.api = apiInstance || api;
        _this.dataSeries = {};
        _this.chartSeries = {};
        _this.charts = [];

        angular.forEach(options, function (value, key) {
          if (key === 'charts') {
            _this[key] = value.map(function (chart) {
              return chart instanceof ChartModel ? chart : new ChartModel(chart);
            });
          } else {
            _this[key] = value;
          }
        });
      };

      ReportModel.prototype.setDateRangeFromDatasets = function () {
        var d = this.dateRange = {
          firstDate: null,
          lastDate: null
        };

        // Loop over each dataset, find the earliest firstRecord, and latest lastRecord.
        this.datasets.forEach(function (dataset) {
          if (dataset.firstRecord && (!d.firstDate || d.firstDate > dataset.firstRecord)) {
            d.firstDate = dataset.firstRecord;
          }

          if (dataset.lastRecord && (!d.lastDate || d.lastDate < dataset.lastRecord)) {
            d.lastDate = dataset.lastRecord;
          }
        });

        // Convert truthy values to moment instances.
        angular.forEach(d, function (value, key) {
          if (value) {
            d[key] = moment.utc(value);
          }
        });
      };

      /**
       * Loads in all dataset objects that do not have a series property.
       */
      ReportModel.prototype.loadDatasets = function () {
        // We must fetch each data set at a time in order to get dataseries data (which we need)
        var _this = this;

        if (!_this.datasets) {
          _this.datasets = [];
          return $q.when();
        }

        var promises = _this.datasets.map(function (dataset, index) {
          if (!dataset.dataSeries) {
            return _this.api.getDataset(dataset.id).then(function (response) {
              _this.datasets[index] = response.data;
            });
          }
        });

        return $q.all(promises).then(function () {
          _this.refreshLookupHashes();
        });
      };

      ReportModel.prototype.getDataset = function (id) {
        var result;

        this.datasets.some(function (dataset) {
          if (dataset.id == id) {
            result = dataset;
            return true;
          }
        });

        return result;
      };

      /**
       * Get the data series.
       *
       * @param {number|object} id The id of the data series to get, or a ChartSeriesModel instance.
       *
       * @return {object}
       */
      ReportModel.prototype.getDataSeries = function (id) {
        if (id instanceof ChartSeriesModel) {
          id = id.dataSeriesId;
        }

        return this.dataSeries[id];
      };

      /**
       * Get array of series comprising a wind rose, in class and segment order.
       *
       * @param {number} datasetId The dataset id to get series from.
       * @param {string} roseName  Name of the wind rose (guaranteed to be unique per data set).
       *
       * @return {object} Array of data series.
       */
      ReportModel.prototype.getWindRoseDataSeries = function (datasetId, roseName) {
        var dataset = this.getDataset(datasetId);

        return dataset.dataSeries.filter(function (dataSeries) {
          return dataSeries.chartDetail.roseName === roseName;
        }).sort(function (a, b) {
          if (a.chartDetail.classIdx === b.chartDetail.classIdx) {
            // If classIdx is the same (same number or both undefined), sort on segmentIdx.
            return a.chartDetail.segmentIdx - b.chartDetail.segmentIdx;
          } else {
            // Otherwise, sort on classIdx.
            return a.chartDetail.classIdx - b.chartDetail.classIdx;
          }
        });
      };

      /**
       * Get hash keyed on class index, values are arrays of series comprising that class, in segment order.
       *
       * @param {number} datasetId The data id to get series from.
       * @param {string} roseName  Name of the wind class rose.
       *
       * @return {object} Hash with class index as key, array of data series as value.
       */
      ReportModel.prototype.getWindClassRoseDataSeries = function (datasetId, roseName) {
        var result = {};

        this.getWindRoseDataSeries(datasetId, roseName).forEach(function (dataSeries) {
          var key = dataSeries.chartDetail.classIdx.toString();

          if (!result[key]) {
            result[key] = [];
          }

          result[key].push(dataSeries);
        });

        return result;
      };

      /**
       * Get the chart series.
       *
       * @param {number} id The data series id of the chart series to get.
       *
       * @return {ChartSeriesModel|false}
       */
      ReportModel.prototype.getChartSeries = function (id) {
        return this.chartSeries[id];
      };

      /**
       * Builds a hash table of data series objects, indexed by series ID.
       *
       * Also adds a datasetId property to the series object
       */
      ReportModel.prototype.refreshLookupHashes = function () {
        var _this = this;
        _this.dataSeries = {};

        _this.datasets.forEach(function (dataset) {
          dataset.dataSeries.forEach(function (series) {
            _this.dataSeries[series.id] = series;
            // Add the dataset Id
            _this.dataSeries[series.id].datasetId = dataset.id;
          });
        });

        _this.chartSeries = {};
        _this.charts.forEach(function (chart) {
          chart.series.forEach(function (series) {
            _this.chartSeries[series.dataSeriesId] = series;
          });
        });
      };

      /**
       * Fetches units of a chart.
       *
       * @param {string} chartIndex
       *  The index of the chart to get units from.
       *
       * @returns {Array<(string | boolean)>}
       *  The units used on chart at chartIndexhas no series (to get the units from).
       */
      ReportModel.prototype.getChartUnits = function (chartIndex) {
        var _this = this;
        var chart = this.charts[chartIndex];

        var dataSeries;
        var units = [];

        chart.series.forEach(function (series) {
          if ((dataSeries = _this.getDataSeries(series.dataSeriesId)) && dataSeries.units) {
            if (units.indexOf(dataSeries.units) === -1) {
              units.push(dataSeries.units);
            }
          }
        });

        return units;
      };

      /**
       * Deletes a chart object at chartIndex from report.charts array
       *
       * @param chartIndex
       *  The index of the chart to delete.
       */
      ReportModel.prototype.deleteChart = function (chartIndex) {
        this.charts.splice(chartIndex, 1);
      };

      /**
       * Adds chart object to charts array in a ReportModel.
       *
       * @param chart
       *  The chart Object to add.
       *
       * @return int
       *  The index of the newly created element in the report.charts array.
       */
      ReportModel.prototype.addChart = function (chart) {
        if (!(chart instanceof ChartModel)) {
          chart = new ChartModel(chart);
        }

        return this.charts.push(chart) - 1;
      };

      /**
       * Assigns a Dataset to the ReportModel.
       *
       * @param {object} dataset The dataset object.
       */
      ReportModel.prototype.addDataset = function (dataset) {
        this.datasets.push(dataset);

        // Refresh data series hash table
        this.refreshLookupHashes();
      };

      /**
       * Removes a Dataset from the ReportModel.
       *
       * @param datasetId
       *  The ID of the Dataset.
       */
      ReportModel.prototype.deleteDataset = function (datasetIndex) {
        this.datasets.splice(datasetIndex, 1);

        // Refresh data series hash table
        this.refreshLookupHashes();
      };

      /**
       * Checks if a dataset is currently assigned to the ReportModel.
       *
       * @param datasetId
       *  The ID of the dataset.
       *
       * @returns {bool}
       *  True if dataset is assigned, otherwise false.
       */
      ReportModel.prototype.isDatasetAssigned = function (datasetId) {
        return this.datasets.some(function (dataset) {
          return dataset.id == datasetId;
        });
      };

      return ReportModel;
    }
  ]);
