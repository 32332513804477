module.exports = [
  'SessionStorage',
  function (SessionStorage) {
    var setSavedMenuState = function (state) {
      return SessionStorage.set('sidebar.state', state);
    };

    var getSavedMenuState = function () {
      return SessionStorage.get('sidebar.state');
    };

    var clearSavedMenuState = function () {
      return SessionStorage.remove('sidebar.state');
    };

    var $body = $('body');

    if (getSavedMenuState() === 'toggled' && $body.width() >= 769) {
      $body.addClass('mini-navbar');
    }

    // Enable sidebar animations, after a delay to avoid the CSS transition.
    setTimeout(
      function () {
        $body.addClass('sidebar-done');
      },
      600
    );

    this.minimalize = function () {
      var $body = $('body');
      $body.toggleClass('mini-navbar');

      if ($body.width() >= 769) {
        // Only remember the state if the site isn't in "mobile" mode, since the sidebar
        // automatically closes in that mode when navigating to a link.
        setSavedMenuState($body.hasClass('mini-navbar') ? 'toggled' : '');
      } else {
        clearSavedMenuState();
      }

      // Hide menu in order to smoothly fade in when changing state.
      $('#side-menu').hide();

      // Fade in the menu after a delay (avoids showing crushed content).
      setTimeout(
        function () {
          $('#side-menu').fadeIn(250);
        }, 250
      );
    };
  }
];
