module.exports = [
  function Notification() {
    return {
      restrict: 'E',
      scope: {
        error: '=error'
      },
      template: '<div class="alert alert-danger"><h3>{{ error.title }}</h3>{{ error.body }}</div>'
    };
  }
];
