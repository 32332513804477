angular.module('dlcApp.controllers.notifications', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('app.notifications', {
        abstract: true,
        url: '/notifications',
        template: '<ui-view />'
      })
      .state('app.notifications.list', {
        url: '',
        templateUrl: '/views/notifications/notifications_list.html',
        controller: 'NotificationsCtrl',
        data: {
          pageTitle: 'Notifications'
        }
      })
      .state('app.notifications.highlight', {
        url: '/{id:int}',
        templateUrl: '/views/notifications/notifications_list.html',
        controller: 'NotificationsCtrl',
        data: {
          pageTitle: 'Notifications'
        }
      });
  }])
  .controller('NotificationsManagerCtrl', [
    '$scope', '$interval', 'api', 'NotificationModel',
    function ($scope, $interval, api, NotificationModel) {
      var REFRESH_INTERVAL = 60000;

      var intervalId = $interval(refreshNotifications, REFRESH_INTERVAL);

      $scope.$on('$destroy', function () {
        $interval.cancel(intervalId);
      });

      $scope.setLastRead = function () {
        $scope.unreadCount = 0;
        api.putNotificationsLastRead();
      };

      /**
       * Replace anchor elements with a span, since nested anchors are invalid HTML
       */
      $scope.getSafeHTML = function (html) {
        var $html = jQuery('<section>' + html + '</section>');
        $html.find('a').each(function (index, el) {
          jQuery(el).replaceWith('<span>' + el.innerHTML + '</span>');
        });
        return $html.html();
      };

      function refreshNotifications() {
        api.getNotifications(10)
          .then(function (res) {
            var lastRead;
            if (!res.data.lastReadUTC) {
              // lastRead can be null, fall back to 1970 so everything is unread.
              lastRead = moment.utc('1970-01-01T00:00:00Z');
            } else {
              lastRead = moment.utc(res.data.lastReadUTC);
            }

            $scope.hasAlarm = false;
            $scope.unreadCount = 0;

            $scope.notifications = (res.data.notifications || [])
              .map(function (n) {
                var m = new NotificationModel(n);

                if (moment.utc(m.createdAtUTC).isAfter(lastRead)) {
                  $scope.unreadCount++;
                }

                if (m.type === NotificationModel.TYPE_ALARM) {
                  $scope.hasAlarm = true;
                }

                return m;
              })
              .slice(0, 5);
          });
      }

      refreshNotifications();
    }
  ])
  .controller('NotificationsCtrl', [
    '$scope', '$stateParams', 'api', 'NotificationModel',
    function ($scope, $stateParams, api, NotificationModel) {
      var PER_PAGE = 10;
      var currentOffset = 0;

      $scope.notifications = false;
      $scope.infiniteScrollBusy = false;

      $scope.getNextPage = function () {
        $scope.infiniteScrollBusy = true;

        api.getNotifications(PER_PAGE, currentOffset)
          .then(function (res) {
            var newNotifications = (res.data.notifications || [])
              .map(function (n) {
                var m = new NotificationModel(n);

                if (m.id === $stateParams.id) {
                  m.highlight = true;
                }

                return m;
              });

            $scope.notifications = ($scope.notifications || []).concat(newNotifications);

            // Only re-enable infinite scroll if there's likely to be more data available.
            $scope.infiniteScrollBusy = (newNotifications.length !== PER_PAGE);
          });

        currentOffset += PER_PAGE;
      };

      $scope.searchTermsChanged = function () {
        // We can't do this directly in the view since there are many sub-scopes.
        $scope.$emit('searchTerms:change');
      };

      // Finally, grab a page of notifications as soon as this controller loads.
      $scope.getNextPage();
    }
  ]);
