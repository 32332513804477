module.exports = [
  function () {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        scope.$watch(function () {
          return element.bigtext({
            maxfontsize: attr.maxSize,
            minfontsize: attr.minSize,
            childSelector: attr.childSelector
          });
        });
      }
    };
  }
];

