angular
  .module('dlcApp.factories')
  .factory('ChartModel', [
    'ChartSeriesModel',
    function (ChartSeriesModel) {
      /**
       * Basic model to represent GetChartModel from the API.
       *
       * This doesn't currently have many methods, but it allows us to enforce the
       * types of objects being passed around, since there are some very similar ones
       * for charts/dataset/dataseries.
       *
       * @param {object} data Parsed object from the API.
       */
      function ChartModel(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.series = data.series.map(function (series) {
          return series instanceof ChartSeriesModel ? series : new ChartSeriesModel(series);
        });

        // This isn't actually used by the API, only for the automated tests.
        if (data.key) {
          this.key = data.key;
        }
      }

      ChartModel.prototype.addSeries = function (series) {
        if (!(series instanceof ChartSeriesModel)) {
          console.warn('Passing an options hash to addSeries is deprecated.');
          series = new ChartSeriesModel(series);
        }

        this.series.push(series);
      };

      ChartModel.prototype.isWindRose = function () {
        return this.series.some(function (chartSeries) {
          return chartSeries.chartType === ChartSeriesModel.CHART_TYPE_ROSE;
        });
      };

      ChartModel.prototype.isWindClassRose = function () {
        return this.series.some(function (chartSeries) {
          return chartSeries.chartType === ChartSeriesModel.CHART_TYPE_CLASS_ROSE;
        });
      };

      return ChartModel;
    }
  ]);
