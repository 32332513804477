module.exports = [
  '$compile',
  function datetimeIcon($compile) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        var attrValue = attr.datetimeIcon;

        if (attrValue !== 'user' && attrValue !== 'device') {
          return;
        }

        var iconClass = attrValue == 'user' ? 'fa-user' : 'fa-hdd-o';
        var tooltipTextKey = attrValue == 'user' ? 'datetimeIcon.userTooltip' : 'datetimeIcon.deviceTooltip';

        var htmlToAppend = '<span class="has-tooltip datetime-icon" tooltip-placement="bottom" ' +
            'tooltip-append-to-body="true" tooltip="{{ \'' + tooltipTextKey + '\' | translate }}">' +
            '<i class="fa ' + iconClass + '"></i>' +
            '</span>';

        element.append($compile(htmlToAppend)(scope));
      }
    };
  }
];
