module.exports = [
  '$timeout',
  function minimalizaSidebar() {
    // Break up the template here otherwise jscs checks will complain about line length
    var templateString = '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary" ' +
     'href="" ng-click="ctrl.minimalize()"><i class="fa fa-bars"></i></a>';

    return {
      restrict: 'A',
      scope: {},
      template: templateString,
      controller: 'SidebarController',
      controllerAs: 'ctrl'
    };
  }
];
