angular
  .module('dlcApp.filters', [])
  .filter('groupedDataSeries', function () {
    /**
     * Filter out wind rose series (used for displaying the available series when editing a chart).
     *
     * @param {object} dataSeries Array of data series.
     *
     * @return {object} Array with all non-wind-rose series, and only the 1st segment (and class) of a wind rose.
     */
    return function (dataSeries) {
      // dataSeries might be empty, because in addition to being called when
      // editing a chart's selected dataseries (e.g. /reports/chart/X), this
      // filter is also called by dashboard_edit_widget.html: It runs when user
      // visits /dashboards/edit-widget/X and the Dataseries <select> is
      // generated by dashboard_edit_widget.html
      if (!dataSeries) {
        return false;
      }
      var roseNames = [];
      return dataSeries.filter(function (s) {
        if (s.chartDetail.noSegments === undefined) {
          return true;
        } else if (s.chartDetail.segmentIdx === 0 && roseNames.indexOf(s.chartDetail.roseName) === -1) {
          roseNames.push(s.chartDetail.roseName);
          return true;
        } else {
          return false;
        }
      });
    };
  })
  .filter('reverse', [
    function () {
      return function Reverse(input) {
        if (!Array.isArray(input)) {
          return false;
        }

        return input.slice().reverse();
      };
    }
  ])
  .filter('momentDevice', [
    function () {
      return function MomentDeviceFilter(input, format) {
        if (!format) {
          format = 'L LTS';
        }
        return moment.utc(input).locale(moment.locale()).format(format);
      };
    }
  ])
  .filter('momentUser', [
    function () {
      return function MomentUserFilter(input, format) {
        if (!format) {
          format = 'L LTS';
        }
        return moment(input).locale(moment.locale()).format(format);
      };
    }
  ])
  .filter('momentTime', [
    function () {
      /**
       * Filter to convert times in "HH:mm" format to valid moment localised strings.
       */
      return function MomentTime(input, format) {
        if (!format) {
          format = 'LT';
        }
        return moment.utc('1970-01-01T' + input + ':00Z').format(format);
      };
    }
  ])
  .filter('momentFromNow', [
    function () {
      return function MomentFromNow(input) {
        return moment(input).locale(moment.locale()).fromNow();
      };
    }
  ])
  .filter('momentSecondsFromNow', [
    function () {
      return function MomentSecondsFromNow(input) {
        return moment(Date.now() - (input * 1000), 'x').locale(moment.locale()).fromNow(true);
      };
    }
  ])
  .filter('momentFromUTC', [
    function () {
      return function MomentFromUTC(input) {
        return moment(input + "+00:00").from(moment().utc());
      };
    }
  ])
  .filter('momentDuration', [
    function () {
      return function MomentDuration(input) {
        return moment.duration(input, 'seconds').locale(moment.locale()).humanize();
      };
    }
  ])
  .filter('momentLocalFromNow', [
    function () {
      return function momentLocalFromNow(input) {
        // Force UTC-formatted input to be interpreted in current time zone.
        input = input.replace(/Z$/, '');
        return moment(input).fromNow();
      };
    }
  ]);
