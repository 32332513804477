module.exports = (function () {
  var NAMES = [
    'IMPORTED',
    'ARCHIVED',
    'LOGGING',
    'STOPPED',
    'EXAMPLE'
  ];

  return {
    IMPORTED: 1,
    ARCHIVED: 2,
    LOGGING: 3,
    STOPPED: 4,
    EXAMPLE: 5,
    getName: function (i) {
      return NAMES[i - 1];
    }
  };
}());
