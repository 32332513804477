angular.module('dlcApp.controllers.authentication.register', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('authentication.register', {
        url: '/register',
        templateUrl: '/views/authentication/register.html',
        controller: 'RegisterCtrl',
        data: {
          pageTitle: 'Register'
        }
      });
  }])
  .controller('RegisterCtrl', [
    '$scope', '$state', '$http', '$modal', 'TranslateNotify', 'notify', 'api',
    function ($scope, $state, $http, $modal, TranslateNotify, notify, api) {
      $scope.user = {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
        phoneNumber: null,
        acceptTerms: null
      };

      $scope.validatePasswords = function () {
        var password = $scope.registerForm.password;
        var confirmPassword = $scope.registerForm.confirmPassword;

        var isValid = password.$viewValue == confirmPassword.$viewValue;

        confirmPassword.$setValidity('matchesPassword', isValid);

        return isValid;
      };

      $scope.validatePhoneNumber = function () {
        var phoneNumber = $scope.registerForm.phoneNumber;

        $scope.user.phoneNumber = $scope.user.phoneNumber.replace(/[^0-9x+]/g, '');
        var valid = Boolean($scope.user.phoneNumber.match(/^\+[0-9]+[0-9x]{7,}$/));

        // Allow 0-length phone number.
        var isValid = ($scope.user.phoneNumber.length === 0 || valid);
        phoneNumber.$setValidity('phoneNumber', isValid);
      };

      $scope.register = function () {
        angular.forEach($scope.user, function (value, key) {
          if (!$scope.registerForm[key].$valid) {
            $scope.registerForm[key].$dirty = true;
          }
        });

        if (!$scope.registerForm.$valid) {
          return;
        }

        var u = $scope.user;

        api.register(u)
          .then(function () {
            return api.login(u.email, u.password);
          }).then(function () {
            return $state.go('app.welcome');
          }).then(function () {
            TranslateNotify({
              messageKey: 'auth.register.accountCreated',
              classes: 'alert-info'
            });
          }, function (req) {
            var errorKeys = [];

            // TODO: This needs to be much more robust!
            if (req.config.url == '/tokens') {
              errorKeys.push('auth.register.failedToLogin');
            } else {
              var pushMessage = function (message) {
                // Note that these aren't prefixed with a translation key path,
                // as we're not yet able to translate them. They'll be outputted
                // as-is until all errors are translatable.
                errorKeys.push(message);
              };

              if (req.data.name) {
                errorKeys.push('apiErrors.' + req.data.name);
              } else if (req.data.modelState) {
                angular.forEach(req.data.modelState, function (value, key) {
                  angular.forEach(value, pushMessage);
                });
              }
            }

            TranslateNotify({
              messageKey: errorKeys,
              messageValues: { user: $scope.user },
              classes: 'alert-danger'
            });
          });
      };
    }]);
