angular
  .module('dlcApp.factories')
  .factory('stats', [
    '$http', '$window',
    function ($http, $window) {
      var Stats = function () {
        var _this = this;

        this.events = [];
        this.inprogress = {};

        (function poll() {
          _this.flush();
          setTimeout(poll, 500);
        }());

      };

      Stats.prototype.start = function (name, params) {
        this.inprogress[name] = {
          start: Date.now(),
          params: params
        };
      };

      Stats.prototype.stop = function (name) {
        var stop = Date.now();
        var stat = this.inprogress[name];

        this.events.push({
          name: name,
          params: JSON.stringify(stat.params),
          start: moment(stat.start).format(),
          stop: moment(stop).format(),
          duration: stop - stat.start
        });

        delete this.inprogress[name];
      };

      Stats.prototype.flush = function () {
        if (!this.events.length) {
          return;
        }

        var options = {
          method: 'POST',
          url: '/v1/stats',
          data: this.events
        };

        this.events = [];

        // var _this = this;
        $http(options)
          .error(function (data, status, headers, config, statusText) {
            // console.error('Failed to record', options.data.length, 'events:', data, status, statusText);
            // Array.prototype.unshift.apply(_this.events, options.data);
          });
      };

      Stats.prototype.sendEvent = function (category, action) {
        $window.ga('send', 'event', category, action);
      };

      Stats.prototype.trackPageView = function (url, title, languageid) {
        $window.ga('set', 'page', url);
        $window.ga('set', 'title', title);
        $window.ga('set', 'dimension1', languageid);
        $window.ga('send', 'pageview');
      };

      return new Stats();
    }
  ]);
