module.exports = [
  '$scope',
  '$state',
  '$modal',
  'DLCDefaultOptions',
  'DTColumnDefBuilder',
  'api',
  'stats',
  'TranslateNotify',
  'PermissionTypes',
  function (
    $scope,
    $state,
    $modal,
    DLCDefaultOptions,
    DTColumnDefBuilder,
    api,
    stats,
    TranslateNotify,
    PermissionTypes
  ) {
    $scope.reports = false;

    $scope.createTableOptions = function () {
      if ($scope.tableOptions) {
        return;
      }

      DLCDefaultOptions({ 0: 'asc' })
        .then(function (options) {
          $scope.tableOptions = options;

          $scope.dtColumnDefs = [
            DTColumnDefBuilder.newColumnDef(0).withOption('orderDataType', 'cell-text'),
            DTColumnDefBuilder.newColumnDef(2).notSortable(),
            DTColumnDefBuilder.newColumnDef(3).notSortable()
          ];
        });
    };

    $scope.deleteReport = function (report) {
      var modal = $modal.open({
        templateUrl: '/views/reports/report_delete.html',
        controller: 'ReportDeleteModalCtrl',
        resolve: {
          report: function () {
            return report;
          }
        }
      });

      modal.result.then(function (report) {
        api.deleteReport(report).success(function () {
          stats.sendEvent('Report', 'Delete');
          var reportIndex = $scope.reports.indexOf(report);
          $scope.reports.splice(reportIndex, 1);
          TranslateNotify({
            messageKey: 'reports.deleteReportSuccess',
            messageValues: { report: report },
            classes: 'alert-info'
          });
        }).error(function (err) {
          TranslateNotify({
            messageKey: err.name ? 'apiErrors.' + err.name : 'errors.unknownError',
            classes: 'alert-danger'
          });
        });
      });
    };

    $scope.canDeleteReport = function (level) {
      return PermissionTypes.canEdit(level);
    };

    api.getReports()
      .success(function (data) {
        // Don't move this, else the "Search" above the table will be in the wrong language,
        // as it will have been created before the selected language was set/loaded if the
        // page is refreshed.
        $scope.createTableOptions();

        data.forEach(function (report) {
          if (report.datasets && report.datasets.length) {
            var names = report.datasets.map(function (dataset) {
              return '<li>' + dataset.name + '</li>';
            });
            report.datasetNamesHTML = '<ul>' + names.join('\n') + '</ul>';
          }
        });

        $scope.reports = data;
      })
      .error(function (err, status) {
        console.error(err, status);
        TranslateNotify({
          messageKey: err.name ? 'apiErrors.' + err.name : 'errors.unknownError',
          classes: 'alert-danger'
        });
      });

    // Workaround for tooltips on mobile.
    $('body').on('touchend', function () {
      $('.tooltip').css('opacity', '0').css('pointer-events', 'none');
    });

    // Remove any tooltip events when this scope is destroyed.
    $scope.$on('$destroy', function () {
      $('body').off('touchend');
    });
  }
];
