(function () {
  angular.module('dlcApp.controllers.public.report', [])
    .config(['$stateProvider', function ($stateProvider) {
      $stateProvider
        .state('public.report', {
          url: '/report/{reportToken:string}?timeframe&dateStart&dateEnd',
          templateUrl: '/views/public/report.html',
          controller: 'SharedReportCtrl'
        });
    }])
    .controller('SharedReportCtrl', [
      '$scope',
      '$stateParams',
      '$state',
      '$q',
      '$modal',
      'APIClient',
      'TranslateNotify',
      'ReportModel',
      'ReportView',
      'FeatureFlags',
      function (
        $scope,
        $stateParams,
        $state,
        $q,
        $modal,
        APIClient,
        TranslateNotify,
        ReportModel,
        ReportView,
        FeatureFlags
      ) {
        var sharedReportToken = $stateParams.reportToken;
        var apiInstance = new APIClient();
        var viewInstance = new ReportView(apiInstance);

        var startTime;
        var endTime;
        var dateFormat = 'YYYYMMDD-hhmm';

        $scope.view = viewInstance;

        // Confirm token
        apiInstance.getSharedReport(sharedReportToken)
          .success(function (data, status, headers, config) {
            var token = angular.copy(data);
            apiInstance.setToken(token, false);

            apiInstance.getReport(data.reportId)
              .success(function (data, status, headers, config) {
                var report = new ReportModel(data, apiInstance);

                if ($stateParams.dateStart && $stateParams.dateEnd) {
                  startTime = moment($stateParams.dateStart, dateFormat, true).valueOf();
                  endTime = moment($stateParams.dateEnd, dateFormat, true).valueOf();
                }

                report.loadDatasets().then(function () {
                  $scope.report = report;
                  viewInstance.renderReport(report, startTime, endTime, $stateParams.timeframe);
                });

                return apiInstance.profile()
                  .success(function (data) {
                    // Each shared report has its own user,
                    // grab that user and see if they can download reports.

                    data.features.some(function (featureId) {
                      if (FeatureFlags[featureId] === 'CAN_DOWNLOAD_SHARED_REPORT') {
                        $scope.downloadable = true;
                        return true;
                      }
                    });
                  });
              })
              .error(function (err) {
                TranslateNotify({
                  messageKey: err.name ? 'apiErrors.' + err.name : 'errors.unknownError',
                  classes: 'alert-danger'
                });
              });
          })
          .error(function (err) {
            TranslateNotify({
              messageKey: err.name ? 'apiErrors.' + err.name : 'errors.unknownError',
              classes: 'alert-danger'
            });
            $state.go('authentication.login');
          });

        $scope.downloadReport = function (report) {
          var modal = $modal.open({
            templateUrl: '/views/reports/report_download_modal.html',
            controller: 'DownloadReportModalCtrl',
            resolve: {
              report: function () {
                return report;
              },
              dateRange: function () {
                return {
                  fromDate: moment.utc(viewInstance.globalXMin),
                  toDate: moment.utc(viewInstance.globalXMax)
                };
              },
              token: function () {
                return apiInstance.token;
              }

            }
          });

          modal.result.then(function (report) {
            $scope.report.name = report.name;
            $scope.report.description = report.description;
          });
        };
      }
    ]);
})();
