angular.module('dlcApp.controllers.welcome', ['ui.router'])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('app.welcome', {
        url: '/welcome',
        templateUrl: '/views/welcome/index.html',
        controller: 'WelcomeCtrl',
        data: {
          pageTitle: 'Welcome'
        }
      });
  }])
  .controller('WelcomeCtrl', ['$scope', function ($scope) {
  }]);
