angular.module('dlcApp.controllers.devices', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('app.devices', {
        abstract: true,
        url: '/devices',
        template: '<ui-view />'
      })
      .state('app.devices.index', {
        url: '',
        templateUrl: '/views/devices/index.html',
        controller: 'DevicesCtrl',
        data: {
          pageTitle: 'Manage Devices'
        },
        resolve: {
          device: [
            'MemoryStorage',
            function (MemoryStorage) {
              var STORAGE_KEY = 'manageDevices.device';

              var device = MemoryStorage.get(STORAGE_KEY);

              if (!device) {
                return false;
              }

              MemoryStorage.remove(STORAGE_KEY);

              try {
                return JSON.parse(device);
              } catch (err) {
                return false;
              }
            }
          ]
        }
      });
  }])
  .run([
    '$rootScope', 'MemoryStorage',
    function ($rootScope, MemoryStorage) {
      $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        // If the user is transitioning from a device page to the "Manage Devices" page, then store the device
        // information before leaving the page, so Manage Devices can scroll to it once loaded.
        if (toState.name !== 'app.devices.index') {
          return;
        }

        if (fromState.name === 'app.gateways.show') {
          MemoryStorage.set('manageDevices.device', JSON.stringify({
            type: 'gateway',
            id: fromParams.id
          }));
        } else if (fromState.name === 'app.loggers.show') {
          MemoryStorage.set('manageDevices.device', JSON.stringify({
            type: 'logger',
            id: fromParams.id
          }));
        }
      });
    }
  ])
  .controller('DevicesCtrl', [
    '$scope', '$modal', '$q', '$timeout', '$translate', 'api', 'TranslateNotify', 'PermissionTypes',
    'gatewayStatus', 'loggerStatus', 'stats', 'device',
    function (
      $scope, $modal, $q, $timeout, $translate, api, TranslateNotify, PermissionTypes,
      gatewayStatus, loggerStatus, stats, device
    ) {
      $scope.gateways = false;

      var askUserToMoveLogger = function (logger) {
        var modal = $modal.open({
          templateUrl: '/views/devices/move_logger_dialog.html',
          controller: 'MoveLoggerModalCtrl',
          resolve: {
            logger: function () {
              return logger;
            }
          }
        });

        modal.result.then(function () {
          api.updateLogger(logger)
            .then(function () {
              $scope.getDevices();
            });
        });
      };

      $scope.canEditDevice = function (level) {
        return PermissionTypes.canEdit(level);
      };

      // Format a device's stateReasons into HTML for the tooltips.
      var formatStateReasons = function (device) {
        // Format any stateReasons messages into a string that can be
        // passed into Angular Bootstrap tooltips
        if (device.stateReasons) {
          var reasonKeys = device.stateReasons.map(function (reason) {
            return 'apiErrors.' + reason.name;
          });

          $translate(reasonKeys).then(function (translations) {
            var translated = reasonKeys.map(function (key) {
              return translations[key];
            });

            device.concatenatedStateReasons = '<ul><li>' + translated.join('</li><li>') + '</li></ul>';
          });
        }
      };

      // Listen to the language selector event so we can update the tooltips.
      $scope.$on('language-selector.changeSuccess', function () {
        if (!Array.isArray($scope.gateways)) {
          return;
        }

        $scope.gateways.forEach(function (gateway) {
          formatStateReasons(gateway);

          if (!Array.isArray(gateway.loggers)) {
            return;
          }

          gateway.loggers.forEach(function (logger) {
            formatStateReasons(logger);
          });
        });
      });

      $scope.addGateway = function () {
        var modal = $modal.open({
          templateUrl: '/views/devices/gateway_form.html',
          controller: 'GatewayModalCtrl', // defined in controllers/gateways.js
          resolve: {
            gateway: function () {
              // We only need an empty object when adding.
              return {};
            }
          }
        });

        modal.validate = function (gateway) {
          // Add users timezone to gateway object
          gateway.timeZone = jstz.determine().name();

          return api.createGateway(gateway)
            .success(function (data) {
              TranslateNotify({
                messageKey: 'manageDevices.addGatewaySuccess',
                messageValues: { gateway: gateway },
                classes: 'alert-info'
              });
            })
            .error(function (data, status, headers, config, statusText) {
              TranslateNotify({
                messageKey: data.name ? 'apiErrors.' + data.name : 'errors.unknownError',
                classes: 'alert-danger'
              });
            });
        };

        modal.result.then(function () {
          // Track add
          stats.sendEvent('Devices', 'Add gateway');

          $scope.getDevices();
        });
      };

      $scope.addLogger = function (gateway) {
        var modal = $modal.open({
          templateUrl: '/views/devices/logger_form.html',
          controller: 'LoggerModalCtrl', // defined in controllers/loggers.js
          resolve: {
            logger: function () {
              // We only need an empty object when adding.
              return {};
            }
          }
        });

        modal.result.then(function (logger) {
          logger.gatewayId = gateway.id;

          // Track add
          stats.sendEvent('Devices', 'Add logger');

          return api.createLogger(logger)
            .then(function (res) {
              TranslateNotify({
                messageKey: 'manageDevices.addLoggerSuccess',
                messageValues: { logger: logger },
                classes: 'alert-info'
              });
              $scope.getDevices();
            }, function (err) {
              if (err.data && (err.data.code == 4011 || err.data.name === 'loggerOnMyGateway')) {
                // Logger already exists on a gateway belonging to this account.
                // Ask the user if they want to move it to "this" gateway.
                var location = (err.headers('Location') || '').match(/\d+$/);
                if (location) {
                  logger.id = parseInt(location[0], 10);
                  return askUserToMoveLogger(logger);
                }
              }

              TranslateNotify({
                messageKey: err.data && err.data.name ? 'apiErrors.' + err.data.name : 'errors.unknownError',
                classes: 'alert-danger'
              });
            });
        });
      };

      $scope.getDevices = function () {
        $scope.gateways = false;
        return $q.all([api.getGateways(), api.getLoggers()])
          .then(function (res) {
            // First, map gateways to their id so we can find them when assigning loggers.
            var orderedGatewayIds = [];
            var gateways = {};
            res[0].data.forEach(function (gateway) {
              gatewayStatus(gateway);
              formatStateReasons(gateway);
              gateways[gateway.id] = gateway;
              orderedGatewayIds.push(gateway.id);
            });

            res[1].data.forEach(function (logger) {
              var gateway = gateways[logger.gatewayId];

              if (!gateway) {
                console.error('Invalid gatewayId in logger', logger);
                return;
              }

              if (!gateway.loggers) {
                gateway.loggers = [];
              }

              loggerStatus(logger);
              formatStateReasons(logger);
              gateway.loggers.push(logger);
            });

            // Convert the hash back to an array in the original order.
            var sortedGateways = [];
            orderedGatewayIds.forEach(function (id) {
              sortedGateways.push(gateways[id]);
            });

            $scope.gateways = sortedGateways;
          });
      };

      $scope.getDevices().then(function () {
        // After initial load, scroll to a device if there is a remembered one.
        // This uses $timeout as we need to trigger this code *after* the DOM has updated.
        if (!device) {
          return;
        }

        $timeout(function () {
          var element = document.getElementById(device.type + '-' + device.id);

          if (!element) {
            return;
          }

          element.scrollIntoView();
        });
      });

      // Hide any open tooltips when you click anywhere on (hopefully) mobile
      // We can't get angular bootstrap to programmatically close tooltips,
      // so we have to just visually hide them.
      // This means that on mobile you can only re-open a tooltip by
      // triggering a different tooltip
      $('body').on('touchend', function () {
        $('.tooltip').css('opacity', '0').css('pointer-events', 'none');
      });

      // Remove any tooltip events when this scope is destroyed.
      $scope.$on('$destroy', function () {
        $('body').off('touchend');
      });
    }
  ]);
