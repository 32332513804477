module.exports = [
  'ChartSeriesModel',
  function (ChartSeriesModel) {
    return {
      scope: {
        widget: '=dashboardWindroseWidget'
      },
      link: function (scope, element) {
        var watchers = [];

        function getLabelsForClassRoseData(data) {
          var labels = [];
          data.forEach(function (series) {
            if (labels.indexOf(series.name) !== -1) {
              return;
            }

            labels.push(series.name);
          });

          return labels;
        }

        function drawXAxisWithLabels(labels) {
          // Add labels - we need one more than the number of series, hence using
          // another loop
          labels.forEach(function (l, i) {
            var OFFSET = 55;
            // Work out the label positions. First one sits at x of the offset, last one
            // sits at x of 200 + the offset. 200 multiplied by current series as a percentage
            // of total number of series
            var xPos = OFFSET + (200 * (i / labels.length));
            // Work out the name of the label, out of a set of options like:
            // <1.5m/s
            // 1.5-3.3m/s
            // 3.3-5.4m/s
            // 5.4-7.9m/s
            // 7.9-10.7m/s
            // >10.7m/s
            var label;
            switch (i) {
              case 0:
                label = '0';
                break;
              case labels.length - 1:
                // For the last marker we just need to remove the units e.g. change >10.7m/s into >10.7
                label = l.match(/(>\d+\.*\d*)/g);

                // Final marker is slightly longer
                xPos += 10;
                break;
              default:
                // All other markers: Strip away last value to leave last value
                // E.g. change 7.9-10.7m/s into 7.9
                label = l.substring(0, l.indexOf('-'));
                break;
            }
            scope.chart.renderer.text(label, xPos, 250).attr({ align: 'center' }).add();
          });

          scope.chart.renderer.text(
            // Convert e.g >10.7m/s to m/s
            labels[labels.length - 1].match(/([a-z]+.+)/gi),
            10,
            250
          ).attr({ align: 'left' }).add();
        }

        watchers.push(scope.$parent.$watch('data', function (val) {
          if (!val) {
            return;
          }

          var dataseries = scope.widget.detail.data.dataseries;

          val.forEach(function (s, i) {
            if (!scope.chart.series[i]) {
              scope.chart.addSeries(s, false);
              return;
            }

            scope.chart.series[i].setData(s);
          });

          var stopSlice = 1 / scope.chart.series.length;
          var colorsAxisStops = [];

          scope.chart.series.forEach(function (series, i) {
            var idx = 2 * i;
            colorsAxisStops[idx] = [i * stopSlice, series.color];
            colorsAxisStops[idx + 1] = [(i + 1) * stopSlice, series.color];
          });

          scope.chart.update({
            colorAxis: {
              stops: colorsAxisStops
            }
          }, false);

          scope.chart.redraw();

          if (!dataseries) {
            // This is a widget on the edit screen, we already know the fake data is class rose data, so
            // we can fake the legend.
            drawXAxisWithLabels(getLabelsForClassRoseData(val));
            return;
          }

          var categories = [];

          dataseries.forEach(function (s) {
            if (categories.indexOf(s.chartDetail.segmentName) !== -1) {
              return;
            }
            categories.push(s.chartDetail.segmentName);
          });

          scope.chart.xAxis[0].update({
            tickInterval: 1,
            categories: categories,
            tickmarkPlacement: 'on'
          });

          if (dataseries[0].chartDetail.chartType === ChartSeriesModel.CHART_TYPE_ROSE) {
            scope.chart.update({
              legend: {
                enabled: false
              }
            });

            return;
          }
          // Set X axis labels for key
          var labels = [];
          if (dataseries[0].chartDetail.chartType === ChartSeriesModel.CHART_TYPE_CLASS_ROSE) {
            labels = getLabelsForClassRoseData(val);
          } else {

            dataseries.forEach(function (ds) {
              if (labels.indexOf(ds.chartDetail.segmentName) !== -1) {
                return;
              }

              labels.push(ds.chartDetail.segmentName);
            });
          }

          drawXAxisWithLabels(labels);
        }));

        // When the scope is destroyed, stop any watchers and destroy the chart
        scope.$on('$destroy', function () {
          watchers.forEach(function (stop) {
            stop();
          });

          if (scope.chart) {
            scope.chart.destroy();
            delete scope.chart;
          }
        });

        scope.chart = Highcharts.chart({
          title: {
            text: ''
          },

          chart: {
            polar: true,
            type: 'column',
            renderTo: element[0]
          },

          pane: [{
            center: ['50%', '45%'],
            size: 180
          }],

          credits: {
            enabled: false
          },

          legend: {
            y: -25,
            floating: true,
            margin: 0,
            itemStyle: {
              fontSize: 10
            },
            itemDistance: 10,
            padding: 0,
            enabled: true
          },

          plotOptions: {
            series: {
              stacking: 'normal',
              shadow: false,
              groupPadding: 0,
              pointPlacement: 'on',
              showInLegend: false, // hide old legend
              crisp: false
            }
          },

          colorAxis: { },

          tooltip: {
            valueSuffix: '%',
            crosshairs: true,
            shared: true,
            xDateFormat: '%e. %b %Y %H:%M'
          },

          xAxis: {
            labels: {
              enabled: false
            },
            tickPlacement: 'off',
            ticks: false,
            visible: true
          },

          yAxis: {
            min: 0,
            endOnTick: false,
            showLastLabel: true,
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            reversedStacks: false
          }
        });
      }
    };
  }
];
