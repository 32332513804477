angular.module('dlcApp.controllers.reports', [])
  .config(['$stateProvider', function ($stateProvider) {
    var resolveChart = [
      'MemoryStorage',
      function (MemoryStorage) {
        var chart = MemoryStorage.get('reports.chart');

        if (!chart) {
          return false;
        }

        MemoryStorage.remove('reports.chart');

        try {
          return JSON.parse(chart);
        } catch (err) {
          return false;
        }
      }
    ];

    $stateProvider
      .state('app.reports', {
        abstract: true,
        url: '/reports',
        template: '<ui-view />'
      })
      .state('app.reports.index', {
        url: '',
        templateUrl: '/views/reports/index.html',
        controller: 'ReportsCtrl',
        data: {
          pageTitle: 'Reports'
        }
      })
      .state('app.reports.show', {
        url: '/{reportId:int}',
        templateUrl: '/views/reports/report.html',
        controller: 'ReportShowCtrl',
        resolve: {
          chart: resolveChart
        },
        data: {
          pageTitle: 'View Report'
        }
      })
      .state('app.reports.draft', {
        url: '/draft',
        templateUrl: '/views/reports/report.html',
        controller: 'ReportShowCtrl',
        resolve: {
          chart: resolveChart
        },
        data: {
          pageTitle: 'View Report'
        },
        params: {
          draft: true
        }
      })
      .state('app.reports.dataset', {
        url: '/dataset/{datasetId:int}',
        templateUrl: '/views/reports/report.html',
        controller: 'ReportShowCtrl',
        resolve: {
          chart: resolveChart
        },
        data: {
          pageTitle: 'View Report'
        }
      })
      .state('app.reports.dataseriesEdit', {
        url: '/chart/{chartIndex:int}',
        templateUrl: '/views/reports/dataseries_edit.html',
        controller: 'ChartDataSeriesEdit',
        data: {
          pageTitle: 'Edit Dataseries'
        }
      })
      .state('app.reports.addDataset', {
        url: '/datasets',
        templateUrl: '/views/reports/add_dataset.html',
        controller: 'ReportDatasetsAdd',
        data: {
          pageTitle: 'Add Datasets'
        }
      });
  }])
  .run([
    '$rootScope', 'MemoryStorage',
    function ($rootScope, MemoryStorage) {
      $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        // If the user is transitioning from editing a chart to the report page, then store the chart
        // information before leaving the page, so the report can scroll to it once loaded.

        if (fromState.name !== 'app.reports.dataseriesEdit') {
          return;
        }

        var validToStates = [
          'app.reports.show',
          'app.reports.draft',
          'app.reports.dataset'
        ];

        if (validToStates.indexOf(toState.name) === -1) {
          return;
        }

        MemoryStorage.set('reports.chart', JSON.stringify({
          index: fromParams.chartIndex
        }));
      });
    }
  ])
  .controller('ReportsCtrl', require('./controllers/ReportsCtrl'))
  .controller('ReportShowCtrl', require('./controllers/ReportShowCtrl'))
  .controller('ChartDataSeriesEdit', require('./controllers/ChartDataSeriesEdit'))
  .controller('ReportDatasetsAdd', require('./controllers/ReportDatasetsAdd'))
  .controller('ReportDatasetModalCtrl', require('./controllers/ReportDatasetModalCtrl'))
  .controller('ReportDeleteModalCtrl', require('./controllers/ReportDeleteModalCtrl'))
  .controller('ReportModalCtrl', require('./controllers/ReportModalCtrl'))
  .controller('RenameChartModalCtrl', require('./controllers/RenameChartModalCtrl'))
  .controller('SharedReportModalCtrl', require('./controllers/SharedReportModalCtrl'))
  .controller('DownloadReportModalCtrl', require('./controllers/DownloadReportModalCtrl'));

