/**
 * INSPINIA - Responsive Admin Theme
 * Copyright 2014 Webapplayers.com
 */
/**
 * sideNavigation - Directive for running slimScroll on sidebar navigation
 */
var sideNavigation = [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        // This code is from the theme and was originally in inspinia.js, fired
        // with window.load
        // That won't work with Angular so we've moved it here
        $('.sidebar-collapse').slimScroll({
          height: 'auto',
          railOpacity: 0.9
        });

        scope.tokens = $rootScope.tokens;

        // Monitor transitions that are triggered when the side menu opens or closes
        // based either on clicking the toggle switch or on resizing the screen
        // When these transitions end, fire another screen resize event so that
        // things like Highcharts will resize themselves to fit
        var endEvents = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';
        // Remove existing events
        $('#page-wrapper').off(endEvents);
        $('.navbar-static-side').off(endEvents);
        // Add events
        $('#page-wrapper').on(endEvents, function (e) {
          $(window).trigger('resize');
        });
        $('.navbar-static-side').on(endEvents, function (e) {
          $(window).trigger('resize');
        });

        // Polyfill for scrollIntoViewIfNeeded
        // from https://gist.github.com/hsablonniere/2581101
        if (!Element.prototype.scrollIntoViewIfNeeded) {
          Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
            centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded;

            var parent = this.parentNode;
            var parentComputedStyle = window.getComputedStyle(parent, null);
            var parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'));
            var parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'));
            var overTop = this.offsetTop - parent.offsetTop < parent.scrollTop;
            var overBottom = (this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth) >
              (parent.scrollTop + parent.clientHeight);
            var overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft;
            var overRight = (this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth) >
              (parent.scrollLeft + parent.clientWidth);
            var alignWithTop = overTop && !overBottom;

            if ((overTop || overBottom) && centerIfNeeded) {
              parent.scrollTop = this.offsetTop - parent.offsetTop - parent.clientHeight / 2 -
                parentBorderTopWidth + this.clientHeight / 2;
            }

            if ((overLeft || overRight) && centerIfNeeded) {
              parent.scrollLeft = this.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 -
                parentBorderLeftWidth + this.clientWidth / 2;
            }

            if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
              this.scrollIntoView(alignWithTop);
            }
          };
        }

        // Horrible edge case, Samsung browser doesn't scroll focused input into
        // view until you type on the keyboard
        // http://stackoverflow.com/questions/23757345/android-does-not-correctly-scroll-on-input-focus-if-not-body-element
        // Only apply this on Android, in the stock Samsung browser, when your
        // screen width is less than 768 (i.e. hopefully just phones, not tablets)
        if (/Android/.test(navigator.appVersion)) {
          if (/SamsungBrowser/.test(navigator.appVersion)) {
            // Remove any possible pre-existing event
            $(window).off('resize', fixScrolling);
            // Add (back) scoped resize event
            $(window).on('resize', fixScrolling);
          }
        }

        function fixScrolling() {
          if (document.activeElement.tagName == "INPUT" && screen.width < 768) {
            window.setTimeout(function () {
              document.activeElement.scrollIntoViewIfNeeded();
            }, 250);
          }
        }

      }
    };
  }
];

/**
 * iboxTools - Directive for iBox tools elements in right corner of ibox (collapse only)
 */
function iboxToolsCollapse($timeout) {
  return {
    restrict: 'A',
    scope: true,
    templateUrl: 'views/common/ibox_tools_collapse.html',
    controller: ['$scope', '$element', function ($scope, $element) {
      // Function for collapse ibox
      var ibox = $element.closest('div.ibox');
      var icon = $element.find('i:first');
      var content = ibox.find('div.ibox-content');
      $scope.showhide = function () {
        content.slideToggle(200);
        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        $timeout(function () {
          ibox.resize();
          ibox.find('[id^=map-]').resize();
        }, 50);
      };
      // Start collapsed if needed
      if ($element.attr("initialCollapseState") === "collapsed") {
        icon.removeClass('fa-chevron-up').addClass('fa-chevron-down');
        ibox.addClass('border-bottom');
        content.hide();
      }
    }]
  };
}

/**
 * icheck - Directive for custom checkbox icheck
 */
function icheck($timeout, $parse) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function ($scope, element, $attrs) {
      var value = $attrs.value;
      var ngModelGetter = $parse($attrs.ngModel);

      return $timeout(function () {

        $scope.$watch($attrs.ngModel, function (newValue) {
          $(element).iCheck('update');
        });

        $(element).iCheck({
          checkboxClass: 'icheckbox_square-green',
          radioClass: 'iradio_square-green'
        })
        .on('ifChanged', function (event) {
          var elemType = $(element).attr('type');

          if (elemType === 'checkbox' && $attrs.ngModel) {
            $timeout(function () {
              $scope.$apply(function () {
                return ngModelGetter.assign($scope, event.target.checked);
              });
            });
          } else if (elemType === 'radio' && $attrs.ngModel) {
            if (event.target.checked) {
              $scope.$root.$broadcast('icheck-radio-changed', { ev: event.target });
            }
            $timeout(function () {
              return $scope.$apply(function () {
                return ngModelGetter.assign($scope, value);
              });
            });
          }
        });
      });
    }
  };
}

function autoFocus($timeout) {
  return {
    restrict: 'AC',
    link: function (_scope, _element) {
      $timeout(function () {
        _element[0].focus();
      }, 0);
    }
  };
}

function autoSelect($timeout) {
  return {
    restrict: 'AC',
    link: function (_scope, _element) {
      $timeout(function () {
        _element[0].select();
      }, 0);
    }
  };
}

angular
  .module('dlcApp')
  .directive('bigtext', require('./directives/bigtext'))
  .directive('bodyClass', require('./directives/body-class'))
  .directive('compile', require('./directives/compile'))
  .directive('dashboardGaugeWidget', require('./dashboards/directives/dashboard-gauge-widget'))
  .directive('dashboardLineWidget', require('./dashboards/directives/dashboard-line-widget'))
  .directive('dashboardWindroseWidget', require('./dashboards/directives/dashboard-windrose-widget'))
  .directive('dashboardWidget', require('./dashboards/directives/dashboard-widget'))
  .directive('datetimeIcon', require('./directives/datetime-icon'))
  .directive('deviceStatus', require('./directives/device-status'))
  .directive('gatewaySyncSchedule', require('./directives/gateway-sync-schedule'))
  .directive('gatewaySyncScheduleEdit', require('./directives/gateway-sync-schedule-edit'))
  .directive('languageSelector', require('./directives/language-selector'))
  .directive('loadingSpinner', require('./directives/loading-spinner'))
  .directive('minimalizaSidebar', require('./directives/minimaliza-sidebar'))
  .directive('pageTitle', require('./directives/page-title'))
  .directive('touchDetection', require('./directives/touch-detection'))
  .directive('notification', require('./directives/notification'))

  .directive('sideNavigation', sideNavigation)
  .directive('iboxToolsCollapse', ['$timeout', iboxToolsCollapse])
  .directive('icheck', ['$timeout', '$parse', icheck])
  .directive('autoFocus', ['$timeout', autoFocus])
  .directive('autoSelect', ['$timeout', autoSelect]);
