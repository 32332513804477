angular.module('dlcApp.controllers.authentication.forgotten-password', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('authentication.forgotten-password', {
        url: '/forgotten-password',
        templateUrl: '/views/authentication/forgotten-password.html',
        controller: 'ForgottenPasswordCtrl',
        data: {
          pageTitle: 'Forgotten Password'
        }
      });
  }])
  .controller('ForgottenPasswordCtrl', [
    '$scope', '$state', 'TranslateNotify', 'api',
    function ($scope, $state, TranslateNotify, api) {
      $scope.data = {
        email: null,
        submitted: false
      };

      $scope.forgottenPassword = function () {
        if (!$scope.forgottenPasswordForm.$valid) {
          return;
        }

        api.forgottenPassword($scope.data.email)
          .then(function (res) {
            $scope.data.submitted = true;

            TranslateNotify({
              messageKey: 'auth.forgottenPassword.submitted',
              messageValues: {
                email: $scope.data.email
              },
              classes: 'alert-success'
            });
          }, function (err) {
            TranslateNotify({
              messageKey: 'errors.unknownError',
              classes: 'alert-danger'
            });
          });
      };
    }
  ]);
