module.exports = [
  '$rootScope',
  '$scope',
  'api',
  'DLCDefaultOptions',
  'DTColumnDefBuilder',
  '$modal',
  'TranslateNotify',
  'PermissionTypes',
  function ($rootScope, $scope, api, DLCDefaultOptions, DTColumnDefBuilder, $modal, TranslateNotify, PermissionTypes) {
    $scope.dashboards = false;
    $scope.features = $rootScope.featureFlags;

    $scope.deleteDashboard = function (dashboard) {
      var modal = $modal.open({
        templateUrl: '/views/dashboards/dashboard_delete.html',
        controller: ['$scope', function ($scope) {
          $scope.dashboard = dashboard;
        }]
      });

      modal.result.then(function () {
        api.deleteDashboard(dashboard.id).success(function () {
          var idx = $scope.dashboards.indexOf(dashboard);
          $scope.dashboards.splice(idx, 1);
          TranslateNotify({
            messageKey: 'dashboards.deleteSuccess',
            messageValues: { dashboard: dashboard },
            classes: 'alert-info'
          });
        }).error(function (err) {
          TranslateNotify({
            messageKey: err.name ? 'apiErrors.' + err.name : 'errors.unknownError',
            classes: 'alert-danger'
          });
        });
      });
    };

    $scope.getDatasetNamesHTML = function (dashboard) {
      if (!dashboard.datasets || !dashboard.datasets.length) {
        return null;
      }

      var items = dashboard.datasets
        .map(function (ds) {
          return '<li>' + ds.name + '</li>';
        });

      return '<ul>' + items.join('') + '</ul>';
    };

    /**
     * This needs to be called "late" in order to use the correct language.
     */
    function createTableOptions() {
      if ($scope.tableOptions) {
        return;
      }

      DLCDefaultOptions({ 0: 'asc' })
        .then(function (options) {
          $scope.tableOptions = options;

          $scope.dtColumnDefs = [
            DTColumnDefBuilder.newColumnDef(0).withOption('orderDataType', 'cell-text'),
            DTColumnDefBuilder.newColumnDef(3).notSortable()
          ];
        });
    }

    $scope.canDeleteDashboard = function (level) {
      return PermissionTypes.canEdit(level);
    };

    api.getDashboards()
      .then(function (res) {
        createTableOptions();
        $scope.dashboards = res.data;
      });
  }
];
