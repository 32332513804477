var placeholderDataseries = {
  minimum: 0,
  maximum: 100,
  units: '°C'
};

module.exports = [
  function () {
    return {
      scope: {
        widget: '=dashboardGaugeWidget'
      },
      link: function (scope, element) {
        function getDataSeries() {
          var w = scope.widget;
          // Gauge widgets only take one dataseries
          return (
            w.detail &&
            w.detail.data &&
            w.detail.data.dataseries &&
            w.detail.data.dataseries[0]
          ) || placeholderDataseries;
        }

        /**
         * getDataLabelsFormat
         * @return {string} template for the labels on gauge widgets
         */
        function getDataLabelsFormat() {
          var ds = getDataSeries();

          return '<div style="text-align: center">' +
            '<span style="font-size: 25px; color: black">' +
            '{y:.' + (scope.$parent.dataDecimalPlaces || '0') + 'f}' +
            '</span><br/>' +
            '<span style="font-size: 12px; color: silver">' + ds.units + '</span>' +
            '</div>';
        }

        var watchers = [];

        scope.$on('$destroy', function () {
          watchers.forEach(function (stop) {
            stop();
          });

          if (scope.chart) {
            scope.chart.destroy();
            delete scope.chart;
          }
        });

        var isFirst = true;
        watchers.push(scope.$parent.$watch('data', function (val) {
          // We should only ever receive a single value, the latest,
          // but it's wrapped in an array.
          // We might also get no data back!
          if (!Array.isArray(val) || val.length === 0) {
            return;
          }

          var series = scope.chart.series[0];
          if (series.points.length === 0) {
            var minimum = isNaN(scope.widget.detail.minimum) || scope.widget.detail.minimum === null ?
              getDataSeries().minimum : scope.widget.detail.minimum;
            series.addPoint(minimum);
          }

          series.points[0].update(val[val.length - 1].floatValue, true, isFirst);
          isFirst = false;
        }));

        watchers.push(scope.$parent.$watch('thresholdColor', function (thresholdColor) {
          if (!thresholdColor) {
            return;
          }
          scope.chart.yAxis[0].update({ minColor: thresholdColor, maxColor: thresholdColor });
        }));

        var ds = getDataSeries();

        // If the widget has a defualt minimim and maximum, use that, otherwise
        // fall back to the datseries mimimum and maximum
        var minimum = isNaN(scope.widget.detail.minimum) || scope.widget.detail.minimum === null ?
          ds.minimum : scope.widget.detail.minimum;
        var maximum = isNaN(scope.widget.detail.maximum) || scope.widget.detail.maximum === null ?
          ds.maximum : scope.widget.detail.maximum;

        scope.chart = new Highcharts.Chart({
          chart: {
            type: 'solidgauge',
            renderTo: element[0],
            height: 200
          },

          title: null,

          pane: {
            center: ['50%', '70%'],
            size: '140%',
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc'
            }
          },

          tooltip: {
            enabled: false
          },
          yAxis: {
            min: minimum,
            max: maximum,
            tickPositions: [minimum, maximum],
            lineWidth: 0,
            minorTickInterval: null,
            title: {
              y: -70
            },
            labels: {
              y: 16
            }
          },

          plotOptions: {
            solidgauge: {
              dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true
              }
            }
          },

          credits: {
            enabled: false
          },

          series: [{
            data: [],
            dataLabels: {
              format: getDataLabelsFormat(),
              y: -50
            },
            tooltip: {
              valueSuffix: ds.units
            }
          }]
        });

        // If the widget has a default minimim and maximum, use that, otherwise
        // fall back to the dataseries minimum and maximum
        function changeGaugeDisplayRange() {
          // TODO: Handle multiple dataseries
          var ds = scope.widget.detail.data.dataseries[0];

          var minimum = isNaN(scope.widget.detail.minimum) || scope.widget.detail.minimum === null ?
            ds.minimum : scope.widget.detail.minimum;
          var maximum = isNaN(scope.widget.detail.maximum) || scope.widget.detail.maximum === null ?
            ds.maximum : scope.widget.detail.maximum;
          scope.chart.update({
            yAxis: {
              min: minimum,
              max: maximum,
              tickPositions: [minimum, maximum]
            }
          });

          scope.chart.series.forEach(function (series) {
            series.update({
              dataLabels: {
                format: getDataLabelsFormat(),
                y: -50
              },
              tooltip: {
                valueSuffix: ds.units
              }
            });
          });
        }

        watchers.push(scope.$watch('widget.detail.data.dataseries', function (value) {
          if (!value) {
            return;
          }
          changeGaugeDisplayRange();
          setupThresholds();
        }));

        watchers.push(scope.$watch('widget.detail.minimum', function () {
          changeGaugeDisplayRange();
          setupThresholds();
        }));

        watchers.push(scope.$watch('widget.detail.maximum', function () {
          changeGaugeDisplayRange();
          setupThresholds();
        }));

        var CENTER_X = 145;
        var CENTER_Y = 130;
        var INNER_RADIUS = 73;
        var OUTER_RADIUS = 120;

        // We have to wait until the data is available before drawing the thresholds because we need
        // the dataseries' min and max values in order to calculation positions.
        var existingPaths = [];

        function setupThresholds() {
          if (!scope.$parent.data) {
            return;
          }

          if (!Array.isArray(scope.widget.thresholds) || scope.widget.thresholds.length === 0) {
            return;
          }

          // TODO: Remove any existing thresholds from the chart here
          existingPaths.forEach(function (path) {
            path.destroy();
          });
          existingPaths = [];

          var ds = getDataSeries();
          var thresholds = scope.widget.thresholds;

          // If the widget has a default minimum and maximum, use that, otherwise
          // fall back to the daatseries minimum and maximum
          var minimum = isNaN(scope.widget.detail.minimum) || scope.widget.detail.minimum === null ?
            ds.minimum : scope.widget.detail.minimum;
          var maximum = isNaN(scope.widget.detail.maximum) || scope.widget.detail.maximum === null ?
            ds.maximum : scope.widget.detail.maximum;

          var fullscale = maximum - minimum;

          thresholds.forEach(function (t) {
            var val = t.data.number - minimum;

            // Don't draw thresholds that are off of the graph.
            if (t.data.number < minimum || t.data.number > maximum) {
              return;
            }

            var angleRad = val / fullscale * Math.PI;
            var path = scope.chart.renderer
              .path([
                'M',
                CENTER_X - Math.cos(angleRad) * OUTER_RADIUS, CENTER_Y - Math.sin(angleRad) * OUTER_RADIUS,
                'L',
                CENTER_X - Math.cos(angleRad) * INNER_RADIUS, CENTER_Y - Math.sin(angleRad) * INNER_RADIUS
              ])
              .attr({
                'stroke-width': 2,
                dashstyle: 'shortdash',
                stroke: '#999999',
                zIndex: 20
              })
              .add();
            existingPaths.push(path);
          });
        }

        watchers.push(scope.$parent.$watch('data', function () {
          setupThresholds();
        }));

        watchers.push(scope.$watch('widget.thresholds', function () {
          setupThresholds();
        }));
      }
    };
  }
];
