angular.module('dlcApp.controllers.loggers', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('app.loggers', {
        abstract: true,
        url: '/loggers',
        template: '<ui-view />'
      })
      .state('app.loggers.show', {
        url: '/{id:int}',
        templateUrl: '/views/devices/logger_detail.html',
        controller: 'LoggerShowCtrl',
        data: {
          pageTitle: 'View Logger'
        },
        resolve: {
          logger: ['$stateParams', 'api', function ($stateParams, api) {
            return api.getLogger($stateParams.id)
              .then(function (res) {
                return res.data;
              });
          }]
        }
      });
  }])
  .controller('LoggerShowCtrl', [
      '$scope', '$state', '$modal', '$translate', '$q',
      'api', 'TranslateNotify', 'loggerStatus', 'logger', 'stats', 'errors', 'PermissionTypes',
    function (
      $scope, $state, $modal, $translate, $q,
      api, TranslateNotify, loggerStatus, logger, stats, errors, PermissionTypes
    ) {
        loggerStatus(logger);
        $scope.logger = logger;

        $scope.canEditOrDeleteLogger = PermissionTypes.canEdit(logger.accessLevel);

        if (logger.errors) {
          logger.errors.forEach(function (error) {
            var errorTypes = error.errorTypes
              .map(function (code) {
                return errors[code];
              })
              .filter(function (errorType) {
                return errorType;
              })
              .map(function (errorType) {
                return $translate('loggerErrorTypes.' + errorType);
              });

            $q.all(errorTypes).then(function (errors) {
              error.types = errors.join(', ');

              if (!error.types) {
                $translate('manageDevices.viewLogger.loggerErrors.defaultErrorType').then(function (defaultType) {
                  error.types = defaultType;
                });
              }
            });
          });
        }

        $scope.updateLogger = function (logger) {
          var modal = $modal.open({
            templateUrl: '/views/devices/logger_form.html',
            controller: 'LoggerModalCtrl',
            resolve: {
              logger: function () {
                return angular.copy(logger);
              }
            }
          });

          modal.result.then(function (logger) {
            api.updateLogger(logger)
                .then(function () {
                  $scope.logger = logger;

                  // Track update
                  stats.sendEvent('Devices', 'Edit logger');
                }, function (err) {
                  TranslateNotify({
                    messageKey: err.name ? 'apiErrors.' + err.name : 'manageDevices.viewLogger.editLoggerFailure',
                    classes: 'alert-danger'
                  });
                });
          });
        };

        $scope.deleteLogger = function (logger) {
          var modal = $modal.open({
            templateUrl: '/views/devices/logger_remove.html',
            controller: 'LoggerModalCtrl',
            resolve: {
              logger: function () {
                return logger;
              }
            }
          });

          modal.result.then(function () {
            // Deliberately not chained from modal.result so we can ignore closing
            // the modal, but detect failing to communicate with the API.
            api.deleteLogger(logger)
                .then(function () {
                  TranslateNotify({
                    messageKey: 'manageDevices.viewLogger.removeLoggerSuccess',
                    messageValues: { logger: logger },
                    classes: 'alert-info'
                  });

                  // Track update
                  stats.sendEvent('Devices', 'Remove logger');

                  $state.go('app.devices.index');
                }, function (err) {
                  TranslateNotify({
                    messageKey: err.name ? 'apiErrors.' + err.name : 'manageDevices.viewLogger.removeLoggerFailure',
                    classes: 'alert-danger'
                  });
                });
          });
        };
      }
  ])
  .controller('LoggerModalCtrl', [
    '$scope', '$modalInstance', '$timeout', 'logger',
    function ($scope, $modalInstance, $timeout, logger) {
      $scope.logger = logger;

      // IE9 doesn't support maxlength on textarea, hence this workaround.
      $modalInstance.opened.then(function () {
        // Use of both `$modalInstance.opened.then` *and* `$timeout` are required,
        // else the textarea does not yet exist in the DOM.
        $timeout(function () {
          if (navigator.userAgent.match(/MSIE 9\.\d+/)) {
            $('textarea').on('keyup blur', function () {
              this.value = (this.value || '').slice(0, 255);
            });
          }
        });
      });

      var validateType = function (newValue, oldValue, $scope) {
        if (!newValue) {
          return;
        }

        var serialNo = $scope.logger.serialNo || '';

        if (serialNo.length === 0 || serialNo.match(/^(GP1|GP2|DL6|SG1|SG2|SD6)-/)) {
          $scope.logger.serialNo = $scope.logger.type + '-' + serialNo.slice(4);
        }
      };

      $scope.$watch('logger.type', validateType);

      $scope.validateSerial = function () {
        $scope.logger.serialNo = ($scope.logger.serialNo || '').toUpperCase().replace(/[^A-Z0-9-]/g, '');
        var matches = $scope.logger.serialNo.match(/^(?:GP1|GP2|DL6|SG1|SG2|SD6)-(\d{2,3})-(\d{2,3})$/);
        var valid = Boolean(matches);

        // Ensure the numbers are within the valid range.
        if (valid) {
          matches.slice(1).forEach(function (num) {
            num = parseInt(num, 10);

            if (isNaN(num)) {
              valid = false;
              return;
            }

            valid = valid && num > 0 && num < 255;
          });
        }

        $scope.loggerForm.serialNo.$setValidity('serialNo', valid);
      };

      var validateForm = function () {
        var valid = true;

        var fields = ['name'];
        if (!$scope.logger.id) {
          fields.push('type', 'serialNo');
        }

        fields.forEach(function (field) {
          if (!$scope.loggerForm[field].$valid) {
            $scope.loggerForm[field].$dirty = true;
            valid = false;
          }
        });

        return valid;
      };

      $scope.submit = function (event) {
        if (!validateForm()) {
          return;
        }

        $scope.$close($scope.logger);
      };
    }
  ])
  .controller('MoveLoggerModalCtrl', [
    '$scope', 'logger',
    function ($scope, logger) {
      $scope.logger = logger;
    }
  ]);
