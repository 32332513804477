module.exports = [
  '$scope', '$timeout', '$translate', '$rootScope', 'LocalStorage', 'TranslateHighcharts',
  function ($scope, $timeout, $translate, $rootScope, LocalStorage, TranslateHighcharts) {
    var _this = this;

    var STORAGE_KEY = 'language.selectedId';

    this.languages = [
      { id: 'en', name: 'EN', momentLocale: 'en-gb' },
      { id: 'de', name: 'DE', momentLocale: 'de' },
      { id: 'zh', name: '中文', momentLocale: 'zh-cn' },
      { id: 'es', name: 'ES', momentLocale: 'es' },
      { id: 'fr', name: 'FR', momentLocale: 'fr' }
    ];

    this.selectedLanguageId = LocalStorage.get(STORAGE_KEY, $translate.use() || 'en');

    var watchFunction = function () {
      return _this.selectedLanguageId;
    };

    $scope.$watch(watchFunction, function (selectedLanguageId) {
      $rootScope.$broadcast('language-selector.changeStarted');

      if (!selectedLanguageId) {
        return;
      }

      var lang;
      _this.languages.some(function (l) {
        if (l.id === selectedLanguageId) {
          lang = l;
          return true;
        }
      });

      if (!lang) {
        throw new Error('Failed to find language \'' + selectedLanguageId + '\'');
      }

      LocalStorage.set(STORAGE_KEY, lang.id);
      $translate.use(lang.id).then(function () {
        TranslateHighcharts().then(function () {
          $rootScope.$broadcast('language-selector.changeSuccess');
        });

        // Workaround for DataTables, wrapped in $timeout for bug when switching languages live
        // only works *from* English, not *to* it.
        $translate('general.search').then(function (search) {
          $timeout(function () {
            $('.dataTables_filter_search').text(search);
          }, 200);
        });
      });
      moment.locale(lang.momentLocale);
    });
  }
];
