module.exports = [
  'SysUnknown',
  'SysWatchdo',
  'SysRTC',
  'SysDataset',
  'SysSystemError',
  'SysResetFailed',
  'SysPowerup',
  'SysButto',
  'SysStuck',
  'SysPwrGone',
  'SysFlashWrit',
  'SysFullDataset',
  'SysOverrun',
  'SysRepaire',
  'SysNotLoggin',
  'SysDatawrite',
  'SysRTCRepair',
  'SysFlashEras',
  'SysInvalidData',
  'SenOverrange',
  'SenUnderrang',
  'SenTimeout',
  'SenWarmu',
  'SenPower',
  'SenZer',
  'SenCal',
  'SenLicense',
  'SenNoisy',
  'SenNoSenso',
  'SenNaN'
];
