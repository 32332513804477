angular.module('dlcApp.controllers.authentication.set-password', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('authentication.set-password', {
        url: '/set-password',
        templateUrl: '/views/authentication/set-password.html',
        controller: 'SetPasswordCtrl',
        data: {
          pageTitle: 'Set Password'
        }
      });
  }])
  .controller('SetPasswordCtrl', [
    '$scope', '$state', 'TranslateNotify', 'api', '$location',
    function ($scope, $state, TranslateNotify, api, $location) {
      var token = $location.search().token;

      $scope.data = {
      };

      $scope.validatePasswords = function () {
        var password = $scope.setPasswordForm.password;
        var confirmPassword = $scope.setPasswordForm.confirmPassword;

        var isValid = password.$viewValue !== '' && password.$viewValue === confirmPassword.$viewValue;

        confirmPassword.$setValidity('matchesPassword', isValid);

        return isValid;
      };

      $scope.setPassword = function () {
        // Don't change the order of these, else short-circuit boolean logic
        // means that validatePasswords() won't be run.
        if (!$scope.validatePasswords() || !$scope.setPasswordForm.$valid) {
          return;
        }

        api.setPassword(token, $scope.data.password)
          .then(function (res) {
            TranslateNotify({
              messageKey: 'auth.setPassword.success',
              classes: 'alert-success'
            });

            if (res && res.data && res.data.email) {
              return api.login(res.data.email, $scope.data.password);
            } else {
              $state.go('authentication.login');
              return false;
            }
          })
          .then(function (res) {
            if (res === false) {
              // Do nothing if we didn't get an email address back.
              return;
            }

            $state.go('app.welcome');
          }, function (err) {
            TranslateNotify({
              messageKey: 'errors.unknownError',
              classes: 'alert-danger'
            });
          });
      };
    }
  ]);
