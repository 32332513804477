angular
  .module('dlcApp.factories')
  .factory('NetworkErrorMessage', [
    '$translate',
    function ($translate) {
      return {
        build: function (messageId) {
          var key = 'networkError.' + messageId;

          return $translate(key)
            .then(function (title) {
              return $translate(key + 'Body')
                .then(function (body) {
                  return {
                    title: title,
                    body: body
                  };
                });
            });
        },
        FAILURE: 'errNetworkFailed'
      };
    }
  ]);
