angular.module('dlcApp.controllers.dashboards', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('app.dashboards', {
        abstract: true,
        url: '/dashboards',
        template: '<ui-view />',
        controller: 'DashboardsMasterController'
      })
      .state('app.dashboards.list', {
        url: '',
        templateUrl: '/views/dashboards/dashboards_list.html',
        controller: 'DashboardsListCtrl',
        data: {
          pageTitle: 'Dashboards'
        }
      })
      .state('app.dashboards.new', {
        url: '/new',
        templateUrl: '/views/dashboards/dashboard.html',
        controller: 'DashboardsNewCtrl',
        data: {
          pageTitle: 'New Dashboard'
        }
      })
      .state('app.dashboards.draft', {
        url: '/draft',
        templateUrl: '/views/dashboards/dashboard.html',
        controller: 'DashboardShowCtrl',
        data: {
          pageTitle: 'View Dashboard'
        }
      })
      .state('app.dashboards.show', {
        url: '/{dashboardId:int}',
        templateUrl: '/views/dashboards/dashboard.html',
        controller: 'DashboardShowCtrl',
        data: {
          pageTitle: 'View Dashboard'
        }
      })
      .state('app.dashboards.add_widget', {
        url: '/add-widget',
        templateUrl: '/views/dashboards/dashboard_add_widget.html',
        controller: 'DashboardAddWidgetCtrl',
        data: {
          pageTitle: 'Add Widget'
        }
      })
      .state('app.dashboards.edit_widget', {
        url: '/edit-widget/{widgetIndex:int}',
        templateUrl: '/views/dashboards/dashboard_edit_widget.html',
        controller: 'DashboardEditWidgetCtrl',
        data: {
          pageTitle: 'Edit Widget'
        }
      });
  }])
  .controller('DashboardsMasterController', require('./DashboardMasterCtrl'))
  .controller('DashboardsListCtrl', require('./DashboardsListCtrl'))
  .controller('DashboardsNewCtrl', require('./DashboardsNewCtrl'))
  .controller('DashboardShowCtrl', require('./DashboardShowCtrl'))
  .controller('DashboardAddWidgetCtrl', require('./DashboardAddWidgetCtrl'))
  // Importing ChartSeriesModel here so we can access Chart types,
  // to filter out data series that can't be added to Wind Roses
  .controller('DashboardEditWidgetCtrl', require('./DashboardEditWidgetCtrl'));
