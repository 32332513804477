angular.module('dlcApp.controllers.gateways', [])
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('app.gateways', {
        abstract: true,
        url: '/gateways',
        template: '<ui-view />'
      })
      .state('app.gateways.show', {
        url: '/{id:int}',
        templateUrl: '/views/devices/gateway_detail.html',
        controller: 'GatewayShowCtrl',
        data: {
          pageTitle: 'View Gateway'
        },
        resolve: {
          gateway: ['$stateParams', 'api', function ($stateParams, api) {
            return api.getGateway($stateParams.id)
              .then(function (res) {
                return res.data;
              });
          }]
        }
      });
  }])
  .controller('GatewayShowCtrl', [
    '$scope', '$state', '$modal', '$interval', 'api', 'TranslateNotify', 'gateway', 'gatewayStatus',
    'PermissionTypes', 'stats', 'weekdays', 'GatewayScheduleModel', 'repeatRates',
      function (
        $scope, $state, $modal, $interval, api, TranslateNotify, gateway, gatewayStatus,
        PermissionTypes, stats, weekdays, GatewayScheduleModel, repeatRates
      ) {
        gatewayStatus(gateway);

        $scope.gateway = gateway;
        $scope.weekdays = weekdays;
        $scope.repeatRates = repeatRates;

        $scope.canEditGateway = PermissionTypes.canEdit(gateway.accessLevel);
        $scope.canDeleteGateway = PermissionTypes.canDelete(gateway.accessLevel);

        $scope.showCreateTunnel = function () {
          $modal.open({
            templateUrl: '/views/devices/gateway_tunnel.html',
            controller: 'GatewayTunnelModalCtrl',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              gateway: function () {
                return gateway;
              }
            }
          });
        };

        $scope.scheduleTabs = [
          { active: true },
          { active: false }
        ];

        $scope.editSchedule = function () {
          $scope.scheduleTabs[1].active = true;
          $scope.editSettingsView = true;
        };

        $scope.cancelEditSchedule = function () {
          $scope.editSettingsView = false;

          if ($scope.gateway.currentSchedule) {
            $scope.currentSchedule = new GatewayScheduleModel($scope.gateway.currentSchedule);
          }

          if ($scope.gateway.pendingSchedule) {
            $scope.pendingSchedule = new GatewayScheduleModel($scope.gateway.pendingSchedule);
          }
        };

        $scope.cancelEditSchedule();

        $scope.updateGateway = function (gateway) {
          var modal = $modal.open({
            templateUrl: '/views/devices/gateway_form.html',
            controller: 'GatewayModalCtrl',
            resolve: {
              gateway: function () {
                return angular.copy(gateway);
              }
            }
          });

          modal.validate = function (gateway) {
            return api.updateGateway(gateway)
                .error(function (err) {
                  TranslateNotify({
                    messageKey: err.name ? 'apiErrors.' + err.name : 'manageDevices.viewGateway.editGatewayFailure',
                    classes: 'alert-danger'
                  });
                });
          };

          modal.result.then(function (gateway) {
            $scope.gateway = gateway;

            // Track update
            stats.sendEvent('Devices', 'Edit gateway');
          });
        };

        $scope.deleteGateway = function (gateway) {
          var modal = $modal.open({
            templateUrl: '/views/devices/gateway_remove.html',
            controller: 'GatewayModalCtrl',
            resolve: {
              gateway: function () {
                return gateway;
              }
            }
          });

          modal.result.then(function () {
            // Deliberately not chained from modal.result so we can ignore closing
            // the modal, but detect failing to communicate with the API.
            api.deleteGateway(gateway)
                .then(function () {
                  TranslateNotify({
                    messageKey: 'manageDevices.viewGateway.removeGatewaySuccess',
                    messageValues: { gateway: gateway },
                    classes: 'alert-info'
                  });

                  // Track delete
                  stats.sendEvent('Devices', 'Remove gateway');

                  $state.go('app.devices.index');
                }, function (err) {
                  TranslateNotify({
                    messageKey: err.name ? 'apiErrors.' + err.name : 'manageDevices.viewGateway.removeGatewayFailure',
                    classes: 'alert-danger'
                  });
                });
          });
        };
      }
  ])
  .controller('GatewayModalCtrl', [
    '$scope', '$modalInstance', '$timeout', 'gateway', 'FeatureFlags',
    function ($scope, $modalInstance, $timeout, gateway, FeatureFlags) {
      $scope.gateway = gateway;

      $scope.allowUserGateways = $scope.$root.featureFlags[FeatureFlags['30']];
      // IE9 doesn't support maxlength on textarea, hence this workaround.
      $modalInstance.opened.then(function () {
        // Use of both `$modalInstance.opened.then` *and* `$timeout` are required,
        // else the textarea does not yet exist in the DOM.
        $timeout(function () {
          if (navigator.userAgent.match(/MSIE 9\.\d+/)) {
            $('textarea').on('keyup blur', function () {
              this.value = (this.value || '').slice(0, 255);
            });
          }
        });
      });

      var validatorBuilder = function (key, expr) {
        return function () {
          $scope.gateway[key] = ($scope.gateway[key] || '').toUpperCase().replace(expr, '');
          $scope.gatewayForm[key].$setValidity(key, $scope.gateway[key].length !== 0);
        };
      };

      $scope.validateSerial = validatorBuilder('serialNo', /[^A-Z0-9]/g);

      $scope.validateAccessCode = validatorBuilder('accessCode', /[^A-Z0-9+/]/g);

      var validateForm = function () {
        var valid = true;

        var keys = ['name'];

        if (!$scope.gateway.id) {
          keys.push('type', 'serialNo', 'accessCode');
        }

        keys.forEach(function (key) {
          if (!$scope.gatewayForm[key].$valid) {
            $scope.gatewayForm[key].$dirty = true;
            valid = false;
          }
        });

        return valid;
      };

      $scope.submit = function (event) {
        if (!validateForm()) {
          return;
        }

        if ($modalInstance.validate) {
          $modalInstance.validate($scope.gateway)
            .then(function (res) {
              $scope.$close($scope.gateway);
            });
        } else {
          $scope.$close($scope.gateway);
        }
      };
    }
  ])
  .controller('GatewayTunnelModalCtrl', [
    '$scope', '$modalInstance', '$interval', 'api', 'gateway', 'stats',
    function ($scope, $modalInstance, $interval, api, gateway, stats) {
      stats.sendEvent('Devices', 'Create tunnel');

      var tunnelTimer = false;

      var startTunnelTimer = function () {
        if (tunnelTimer) {
          return;
        }

        tunnelTimer = $interval(
          function () {
            api.getGatewayTunnel(gateway)
              .then(function (res) {
                // Don't update the status if the timer has since been canceled.
                if (!tunnelTimer) {
                  return;
                }

                $scope.tunnel = res.data;
              });
          },
          5000
        );
      };

      var stopTunnelTimer = function () {
        if (tunnelTimer) {
          $interval.cancel(tunnelTimer);
          tunnelTimer = false;
        }
      };

      $scope.createTunnel = function () {
        $scope.tunnel = {
          active: false
        };

        startTunnelTimer();

        api.createGatewayTunnel(gateway)
          .then(function (res) {
            $scope.tunnel = res.data;
          });
      };

      $scope.deleteTunnel = function () {
        stopTunnelTimer();
        api.deleteGatewayTunnel(gateway)
          .then(function (res) {
            $scope.tunnel = null;
          });
      };

      $scope.$on('$destroy', function () {
        $scope.deleteTunnel();
      });

      // We've defined all our functions, this part is then actually run.
      $scope.createTunnel();
    }
  ]);
