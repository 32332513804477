module.exports = [
  'LocalStorage',
  function (LocalStorage) {
    return {
      restrict: 'E',
      templateUrl: '/views/language-selector.html',
      scope: {},
      controller: 'LanguageSelectorController',
      controllerAs: 'ctrl'
    };
  }
];
