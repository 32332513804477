angular
  .module('dlcApp.factories')
  .factory('datasetGrouping', ['ChartModel', 'ChartSeriesModel', function (ChartModel, ChartSeriesModel) {
    return function (dataset) {
      var chartCounter = 0;
      var binarySeriesId = 0;
      var keyedCharts = {};
      var charts = [];

      var addToKeyedSeries = function (key, series) {
        var chartSeries;

        if (series.chartDetail.chartType === ChartSeriesModel.CHART_TYPE_CLASS_ROSE) {
          // Ignore all wind class rose series apart from segment 0, class 0.
          if (series.chartDetail.segmentIdx !== 0 || series.chartDetail.classIdx !== 0) {
            return;
          }

          chartSeries = new ChartSeriesModel({
            datasetId: dataset.id,
            chartType: series.chartDetail.chartType,
            roseName: series.chartDetail.roseName
          });
        } else if (series.chartDetail.chartType === ChartSeriesModel.CHART_TYPE_ROSE) {
          // Ignore all wind rose series apart from the one for segment 0.
          if (series.chartDetail.segmentIdx !== 0) {
            return;
          }

          chartSeries = new ChartSeriesModel({
            datasetId: dataset.id,
            chartType: series.chartDetail.chartType,
            roseName: series.chartDetail.roseName
          });
        } else {
          chartSeries = new ChartSeriesModel({
            datasetId: dataset.id,
            dataSeriesId: series.id,
            chartType: series.chartDetail.chartType
          });
        }

        if (key === 'binary') {
          key = 'binary-' + binarySeriesId;
          if (keyedCharts[key] && keyedCharts[key].series.length === 4) {
            key = 'binary-' + (++binarySeriesId);
          }
        } else if (key === 'units') {
          key = 'units-' + series.units;
        }

        if (!keyedCharts[key]) {
          keyedCharts[key] = new ChartModel({
            name: 'Chart ' + (++chartCounter),
            description: '',
            series: [],
            key: key
          });

          charts.push(keyedCharts[key]);
        }

        keyedCharts[key].addSeries(chartSeries);
      };

      if (dataset.dataSeries && dataset.dataSeries.length) {
        dataset.dataSeries.forEach(function (series) {
          // First, find all dataseries that have a groupId
          if (series.chartDetail && series.chartDetail.groupId) {
            return addToKeyedSeries('group-' + series.chartDetail.groupId.toString(), series);
          }

          // Next, group all binary charts together.
          if (series.type === 6) {
            return addToKeyedSeries('binary', series);
          }

          // Finally, group on units (return isn't required here, just a reminder).
          return addToKeyedSeries('units', series);
        });
      }

      return charts;
    };
  }]);
