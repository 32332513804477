module.exports = [
  '$scope', '$modalInstance', 'report', 'saveOnApply', '$timeout',
  function ($scope, $modalInstance, report, saveOnApply, $timeout) {
    $scope.report = report;
    $scope.saveOnApply = saveOnApply;

    // IE9 doesn't support maxlength on textarea, hence this workaround.
    $modalInstance.opened.then(function () {
      // Use of both `$modalInstance.opened.then` *and* `$timeout` are required,
      // else the textarea does not yet exist in the DOM.
      $timeout(function () {
        if (navigator.userAgent.match(/MSIE 9\.\d+/)) {
          $('textarea').on('keyup blur', function () {
            this.value = (this.value || '').slice(0, 1000);
          });
        }
      });
    });

    var validateForm = function () {
      if (!$scope.reportForm.name.$valid) {
        $scope.reportForm.name.$dirty = true;
        return false;
      } else {
        return true;
      }
    };

    $scope.submit = function (event) {
      if (!validateForm()) {
        return;
      }

      $scope.$close($scope.report);
    };
  }
];
