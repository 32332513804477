var types = [
  'INT', 'FLOAT', 'DATETIMELOCAL', 'DATETIMEUTC', 'TIMESPAN', 'BINARY',
  'BOOLEAN', 'TEXT', 'LOCATIONLAT', 'LOCATIONLON', 'LOCATIONACCURACY'
];

module.exports = {
  INT: 1,
  FLOAT: 2,
  DATETIMELOCAL: 3,
  DATETIMEUTC: 4,
  TIMESPAN: 5,
  BINARY: 6,
  BOOLEAN: 7,
  TEXT: 8,
  LOCATIONLAT: 9,
  LOCATIONLON: 10,
  LOCATIONACCURACY: 11,
  getName: function (i) {
    return types[i - 1];
  }
};
