angular
  .module('dlcApp.values', [])
  .value('errors', require('./values/errors'))
  .value('weekdays', require('./values/weekdays'))
  .value('repeatRates', require('./values/repeat_rates'))
  .value('timezones', require('./values/timezones'))
  .value('DatasetStates', require('./values/dataset_states'))
  .value('DataseriesTypes', require('./values/dataseries_types'))
  .value('Utils', require('./values/utils'))
  .value('WidgetTypes', require('./values/widget_types'))
  .value('FeatureFlags', require('./values/feature_flags'))
  .value('DateRanges', require('./values/date_ranges'))
  .value('PermissionTypes', require('./values/permission_types'));
