module.exports = [
  '$scope', '$modalInstance', 'report', 'timeframeQuery', 'dateRange', '$translate', '$timeout',
  '$location', '$state', 'api',
  function ($scope, $modalInstance, report, timeframeQuery, dateRange, $translate, $timeout, $location, $state, api) {
    var DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
    var urlDateFormat = 'YYYYMMDD-hhmm';
    $scope.report = report;
    $scope.zoomData = {
      invalidRange: false,
      autoZoom: false,
      zoomType: null,
      timeframe: {
        value: 1,
        code: 'day'
      },
      dateRange: {
        start: dateRange.fromDate.format(DATE_FORMAT),
        end: dateRange.toDate.format(DATE_FORMAT)
      }
    };
    var options = ['day', 'week', 'month', 'year'];
    var arr = options.map(function (codeObject) {
      return 'reports.viewReport.shareReport.timeframeCode.' + codeObject;
    });
    $translate(arr).then(function (translations) {
      var translated = arr.map(function (key) {
        return translations[key];
      });
      $scope.codeOptions = options.map(function (option, ind) {
        return { id: option, name: translated[ind] };
      });
    });
    $scope.createCodes = function () {
      return $scope.codeOptions;
    };

    $scope.$on('icheck-radio-changed', function (e, args) {
      $scope.zoomData.invalidRange = false;
      $scope.zoomData.zoomType = args.ev.value;
      $scope.updateURL();
    });

    var requestNewEndpoint = function () {
      api.createSharedReport(report, $scope.downloadable)
        .success(function (data, status, headers, config) {
          var currentDomain = $location.host();

          $scope.downloadable = data.allowDataDownload;

          if (($location.protocol() == 'http' && $location.port() != 80) ||
            ($location.protocol() == 'https' && $location.port() != 443)
          ) {
            currentDomain += ':' + $location.port();
          }

          var queryString = '';

          if ($scope.zoomData.zoomType === 'timeframe') {
            var plural = $scope.zoomData.timeframe.value > 1;
            // if it's more than one, set code from day to days, etc
            queryString = '?timeframe=' + $scope.zoomData.timeframe.value + '-' +
              (plural ? $scope.zoomData.timeframe.code + 's' : $scope.zoomData.timeframe.code);

          } else if (typeof timeframeQuery !== 'undefined') {
            queryString = '?timeframe=' + timeframeQuery;

          } else if (!$scope.zoomData.dateRange.start || !$scope.zoomData.dateRange.end) {
            $scope.zoomData.invalidRange = true;
          } else if ($scope.zoomData.zoomType === 'daterange') {

            var startDate = moment.utc($scope.zoomData.dateRange.start, DATE_FORMAT, true);
            var endDate = moment.utc($scope.zoomData.dateRange.end, DATE_FORMAT, true);

            if (startDate.valueOf() >= endDate.valueOf()) {
              $scope.zoomData.invalidRange = true;
            } else {
              queryString = '?dateStart=' + startDate.format(urlDateFormat) +
                '&dateEnd=' + endDate.format(urlDateFormat);
            }
          }
          $scope.reportSharedUrl = $location.protocol() + '://' + currentDomain + '/' +
            $state.href('public.report', { reportToken: data.link_token }) + queryString;
        });
    };

    var promise = $modalInstance.opened.then(requestNewEndpoint);

    $scope.onToggleDownload = function () {
      $scope.reportSharedUrl = undefined;
      promise.then(requestNewEndpoint);
    };

    $scope.onToggleAutoZoom = function () {
      if ($scope.zoomData.autoZoom) {
        $scope.zoomData.zoomType = 'timeframe';
      } else {
        $scope.zoomData = {
          invalidRange: false,
          zoomType: null,
          timeframe: {
            value: 1,
            code: 'day'
          },
          dateRange: {
            start: dateRange.fromDate.format(DATE_FORMAT),
            end: dateRange.toDate.format(DATE_FORMAT)
          }
        };
      }
      promise.then(requestNewEndpoint);
    };

    $scope.updateURL = function () {
      $scope.zoomData.invalidRange = false;
      if ($scope.zoomData.zoomType === 'daterange') {
        $scope.zoomData.timeframe.value = 1;
      } else {
        $scope.zoomData.dateRange = {
          start: dateRange.fromDate.format(DATE_FORMAT),
          end: dateRange.toDate.format(DATE_FORMAT)
        };
      }
      promise.then(requestNewEndpoint);
    };

    $scope.validateInput = function (value) {
      $scope.zoomData.timeframe.value = isNaN(value) || value < 1 ? 1 : $scope.zoomData.timeframe.value;
    };
  }
];
