module.exports = {
  DAY: 'day',
  DAYS: 'days',
  WEEK: 'week',
  WEEKS: 'weeks',
  MONTH: 'month',
  MONTHS: 'months',
  THREE_MONTH: '3-months',
  YEAR: 'year',
  YEARS: 'years'
};
