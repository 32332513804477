var UPDATE_INTERVAL = 300000;

module.exports = [
  '$rootScope', '$scope', '$state', '$location', '$timeout', '$modal', 'api', 'DashboardRepository', 'PermissionTypes',
  function ($rootScope, $scope, $state, $location, $timeout, $modal, api, DashboardRepository, PermissionTypes) {
    var refreshTimeout = false;
    $scope.features = $rootScope.featureFlags;

    function refresh() {
      $scope.dashboard.widgets = angular.copy($scope.dashboard.widgets);
      setRefreshTimeout();
    }

    function clearRefreshTimeout() {
      if (refreshTimeout) {
        $timeout.cancel(refreshTimeout);
      }
    }

    function setRefreshTimeout() {
      clearRefreshTimeout();
      refreshTimeout = $timeout(refresh, UPDATE_INTERVAL);
    }

    function sanitizeDashboard(dashboard) {
      if (!Array.isArray(dashboard.widgets)) {
        dashboard.widgets = [];
      }

      dashboard.widgets.forEach(function (widget) {
        if (!Array.isArray(widget.thresholds)) {
          widget.thresholds = [];
        }

        // Add missing dataTimeSpan value for line chart widgets based on start/end data dates.
        if (widget && widget.detail && widget.detail.dataStartDT && widget.detail.dataEndDT) {
          var start = moment.utc(widget.detail.dataStartDT);
          var end = moment.utc(widget.detail.dataEndDT);

          widget.detail.dataTimeSpan = end.diff(start, 'seconds');
        }
      });

      return dashboard;
    }

    // Fetch the dashboard from the API or the repository, depending on the route params.
    if ($state.params.dashboardId) {
      // Specific, existing dashboard, by ID
      $scope.dashboardId = $state.params.dashboardId;
      api.getDashboard($state.params.dashboardId)
        .then(function (res) {
          $scope.dashboard = sanitizeDashboard(res.data);
          $scope.canEditDashboard = PermissionTypes.canEdit($scope.dashboard.accessLevel);
          setRefreshTimeout();
          checkForScroll();
        });
    } else {
      // Generic dashboards, e.g. draft dashboard
      var dashboard = DashboardRepository.get();
      $scope.dashboardId = dashboard.id;
      $scope.dashboard = sanitizeDashboard(dashboard);
      $scope.canEditDashboard = PermissionTypes.canEdit($scope.dashboard.accessLevel);
      setRefreshTimeout();
      checkForScroll();
    }


    // Check if we need to scroll to a particular widget.
    function checkForScroll() {
      $scope.dashboard.widgets.some(function (widget, index) {
        if (!widget.isNew) {
          return false;
        }

        // Only scroll to a brand-new widget, and only once!
        widget.isNew = false;
        DashboardRepository.set($scope.dashboard);

        $timeout(function () {
          var elements = document.getElementsByClassName('dashboard-widget-' + index);

          if (elements.length > 0) {
            elements[0].scrollIntoView();
          }
        }, 150);

        return true;
      });
    }

    // Store the current state of the dashboard before leaving this page so that
    // the "add widget" button can just navigate away safely with no further logic.
    $scope.$on('$destroy', function () {
      DashboardRepository.set($scope.dashboard);
      clearRefreshTimeout();
    });

    $scope.edit = function () {
      // We don't pass the dashboard object itself, else canceling can't work due to Angular's two-way bindings.
      var modalScope = $scope.$new(true);
      modalScope.dashboard = {
        name: $scope.dashboard.name,
        description: $scope.dashboard.description
      };

      var modal = $modal.open({
        templateUrl: '/views/dashboards/dashboard_edit.html',
        scope: modalScope
      });

      modal.result.then(function () {
        $scope.dashboard.name = modalScope.dashboard.name;
        $scope.dashboard.description = modalScope.dashboard.description;
      });
    };

    $scope.share = function () {
      var modalScope = $scope.$new(true);
      modalScope.dashboard = $scope.dashboard;

      $modal.open({
        templateUrl: '/views/dashboards/dashboard_share.html',
        scope: modalScope
      });

      api.shareDashboard($scope.dashboard.id)
        .then(function (res) {
            var domain = $location.host();

            if ([80, 443].indexOf($location.port()) === -1) {
              domain += ':' + $location.port();
            }

            var href = $state.href('public.dashboard', { token: res.data.link_token });
            modalScope.sharedUrl = $location.protocol() + '://' + domain + '/' + href;
          });
    };

    $scope.save = function () {
      $scope.isSaving = true;

      var p;

      if ($scope.dashboard.id) {
        p = api.putDashboard($scope.dashboard);
      } else {
        p = api.postDashboard($scope.dashboard);
      }

      p.then(function (res) {
        // If we created a dashboard, navigate to that dashboard.
        var location = res.headers('location');
        if (location) {
          var id = parseInt(location.split('/').pop(), 10);
          if (!isNaN(id)) {
            $scope.dashboard.id = id;
          }
        }

        $scope.isSaving = false;

        if ($scope.dashboard.id) {
          $state.go('app.dashboards.show', { dashboardId: $scope.dashboard.id });
        }
      });
    };
  }
];
