(function () {
  function addConstant(cls, key, value) {
    Object.defineProperty(cls, key, {
      enumerable: false,
      configurable: false,
      writable: false,
      value: value
    });
  }

  angular
    .module('dlcApp.factories')
    .factory('NotificationModel', [
      'NotificationSenderModel', 'NotificationBodyModel',
      function (NotificationSenderModel, NotificationBodyModel) {
        function NotificationModel(data) {
          this.id = data.id || null;
          this.type = data.type;
          this.createdAtUTC = data.createdAtUTC || moment.utc().format();
          this.title = data.title || '';
          this.sender = new NotificationSenderModel(data.sender || '');

          this.body = (data.body || []).map(function (b) {
            return new NotificationBodyModel(b);
          });
        }

        NotificationModel.prototype.isAlarm = function () {
          return this.type === NotificationModel.TYPE_ALARM;
        };

        NotificationModel.prototype.toJSON = function () {
          return {
            id: this.id,
            type: this.type,
            createdAtUTC: this.createdAtUTC,
            title: this.title,
            sender: this.sender,
            body: this.body
          };
        };

        addConstant(NotificationModel, 'TYPE_MESSAGE', 0);
        addConstant(NotificationModel, 'TYPE_ALARM', 1);

        return NotificationModel;
      }
    ])
    .factory('NotificationSenderModel', [
      function () {
        function NotificationSenderModel(data) {
          if (typeof data === 'string') {
            this.text = data;
          } else {
            this.text = data.text || '';
            if (data.link) {
              this.link = {
                type: data.link.type,
                id: data.link.id
              };
            }
          }
        }

        NotificationSenderModel.prototype.getLinkStateReference = function () {
          switch (this.link.type) {
            case NotificationSenderModel.LINK_TYPE_GATEWAY:
              return 'app.gateways.show({ id: ' + this.link.id + ' })';

            case NotificationSenderModel.LINK_TYPE_LOGGER:
              return 'app.loggers.show({ id: ' + this.link.id + ' })';

            case NotificationSenderModel.LINK_TYPE_DATASET:
              return 'app.reports.dataset({ datasetId: ' + this.link.id + ' })';

            case NotificationSenderModel.LINK_TYPE_REPORT:
              return 'app.reports.show({ reportId: ' + this.link.id + ' })';

            case NotificationSenderModel.LINK_TYPE_DASHBOARD:
              return 'app.dashboards.show({ dashboardId: ' + this.link.id + ' })';

            default:
              return null;
          }
        };

        NotificationSenderModel.prototype.toJSON = function () {
          var obj = {
            text: this.text
          };

          if (this.link) {
            obj.link = {
              type: this.link.type,
              id: this.link.id
            };
          }

          return obj;
        };

        addConstant(NotificationSenderModel, 'LINK_TYPE_GATEWAY', 0);
        addConstant(NotificationSenderModel, 'LINK_TYPE_LOGGER', 1);
        addConstant(NotificationSenderModel, 'LINK_TYPE_DATASET', 2);
        addConstant(NotificationSenderModel, 'LINK_TYPE_REPORT', 3);
        addConstant(NotificationSenderModel, 'LINK_TYPE_DASHBOARD', 4);

        return NotificationSenderModel;
      }
    ])
    .factory('NotificationBodyModel', [
      'NotificationBodyTableColumnModel',
      function (NotificationBodyTableColumnModel) {
        function NotificationBodyModel(data) {
          if (data.html) {
            this.html = data.html;
          } else if (data.table) {
            this.table = {
              columns: data.table.columns,
              rows: data.table.rows
            };
          }
        }

        NotificationBodyModel.prototype.toJSON = function () {
          if (this.html) {
            return {
              html: this.html
            };
          } else {
            return {
              table: {
                columns: (this.table.columns || []).map(function (col) {
                  return new NotificationBodyTableColumnModel(col);
                }),
                rows: this.table.rows
              }
            };
          }
        };

        NotificationBodyModel.withHTML = function (html) {
          return new NotificationBodyModel({ html: html });
        };

        NotificationBodyModel.withTable = function (columns, rows) {
          return new NotificationBodyModel({
            table: {
              columns: columns,
              rows: rows
            }
          });
        };

        return NotificationBodyModel;
      }
    ])
    .factory('NotificationBodyTableColumnModel', [
      function () {
        function NotificationBodyTableColumnModel(data) {
          this.key = data.key || '';
          this.type = data.type;

          var validTypes = [
            NotificationBodyTableColumnModel.TYPE_DATE,
            NotificationBodyTableColumnModel.TYPE_NUMBER,
            NotificationBodyTableColumnModel.TYPE_STRING
          ];

          if (validTypes.indexOf(this.type) === -1) {
            this.type = NotificationBodyTableColumnModel.TYPE_STRING;
          }
        }

        NotificationBodyTableColumnModel.prototype.toJSON = function () {
          return {
            key: this.key,
            type: this.type
          };
        };

        addConstant(NotificationBodyTableColumnModel, 'TYPE_DATE', 'date');
        addConstant(NotificationBodyTableColumnModel, 'TYPE_NUMBER', 'number');
        addConstant(NotificationBodyTableColumnModel, 'TYPE_STRING', 'string');

        return NotificationBodyTableColumnModel;
      }
    ]);
}());
