module.exports = [
  function () {
    return {
      restrict: 'A',
      templateUrl: '/views/common/status.html',
      scope: {
        item: '=deviceStatus'
      }
    };
  }
];
