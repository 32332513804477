var SimpleStorage = require('./factories/simple_storage');

angular
  .module('dlcApp.factories', ['datatables.bootstrap'])

  // Do *not* change the order of these, as each relies on the previous one as a fallback.
  .factory('MemoryStorage', SimpleStorage.Memory)
  .factory('SessionStorage', SimpleStorage.Session)
  .factory('LocalStorage', SimpleStorage.Local)
  .factory('TranslateNotify', require('./factories/translate_notify'))
  .factory('TranslateHighcharts', require('./factories/translate_highcharts'));

require('./factories/api');
require('./factories/chart_model');
require('./factories/chart_series_model');
require('./factories/cookies');
require('./dashboards/factories/dashboard_repository');
require('./factories/dataset_grouping');
require('./factories/datatables');
require('./factories/gateway_schedule_model');
require('./factories/gateway_status');
require('./factories/logger_status');
require('./factories/network_error_message');
require('./factories/notification_model');
require('./factories/report_model');
require('./factories/report_repository');
require('./factories/report_view');
require('./factories/stats');
require('./factories/widget_manager');
