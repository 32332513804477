angular.module('dlcApp.controllers.help', [])
  .controller('HelpModalCtrl', [
    '$scope', '$modal', '$translate',
    function ($scope, $modal, $translate) {
      $scope.openHelp = function () {
        var lang = $translate.use();
        $modal.open({
          templateUrl: '/views/help/help-' + lang + '.html',
          size: 'lg'
        });
      };
    }
  ]);
