angular
  .module('dlcApp.factories')
  .config(function () {
    jQuery.fn.dataTable.moment('DD/MM/YYYY HH:mm:ss');

    // Workaround for angular-datatables issue, presenting as error:
    // "DataTables warning: table id=DataTables_Table_0 - Cannot reinitialise DataTable.
    // For more information about this error, please see http://datatables.net/tn/3"
    // https://github.com/l-lin/angular-datatables/issues/262
    // https://github.com/l-lin/angular-datatables/issues/302
    jQuery.fn.dataTable.ext.errMode = 'throw';

    jQuery.fn.dataTable.ext.order['cell-text'] = function (settings, col) {
      return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return td.textContent.trim();
      });
    };
  })
  .factory('DLCDefaultOptions', [
    '$translate', 'DTOptionsBuilder',
    function ($translate, DTOptionsBuilder) {
      return function (order) {
        var orderArr = [];

        if (order !== false) {
          if (!order) {
            orderArr.push([1, 'asc']);
          } else {
            angular.forEach(order, function (value, key) {
              orderArr.push([key, value]);
            });
          }
        }

        return $translate('general.search').then(function (search) {
          var options = DTOptionsBuilder.newOptions()
            .withLanguage({ sSearch: '<span class="dataTables_filter_search">' + search + '</span>' + ':' })
            .withOption('responsive', true)
            .withOption('paging', false)
            // http://datatables.net/reference/option/dom
            .withDOM('T<"clear">frt')
            .withBootstrap();

          if (order !== false) {
            // For on-load sort
            options.withOption('order', orderArr);
          }

          return options;
        });
      };
    }
  ]);
