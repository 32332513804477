(function () {
  var controllers = [
    'dlcApp.controllers.authentication',
    'dlcApp.controllers.datasets',
    'dlcApp.controllers.dashboards',
    'dlcApp.controllers.devices',
    'dlcApp.controllers.gateways',
    'dlcApp.controllers.help',
    'dlcApp.controllers.loggers',
    'dlcApp.controllers.notifications',
    'dlcApp.controllers.public',
    'dlcApp.controllers.reports',
    'dlcApp.controllers.welcome'
  ];

  angular
    .module('dlcApp.controllers', controllers)
    .controller('LanguageSelectorController', require('./controllers/language-selector'))
    .controller('GatewaySyncScheduleEditController', require('./controllers/gateway-sync-schedule-edit'))
    .controller('SidebarController', require('./controllers/sidebar'));

  require('./controllers/authentication.js');
  require('./controllers/datasets.js');
  require('./dashboards/controllers/dashboards.js');
  require('./controllers/devices.js');
  require('./controllers/gateways.js');
  require('./controllers/help.js');
  require('./controllers/loggers.js');
  require('./controllers/notifications.js');
  require('./controllers/public.js');
  require('./controllers/reports/reports.js');
  require('./controllers/welcome.js');
})();
