(function () {
  var dependencies = [
    'ngSanitize',                   // Safe inclusion of HTML
    'ui.router',                    // Routing
    'ui.bootstrap',                 // Bootstrap
    'ui.sortable',                  // Sortable
    'cgNotify',                     // Angular notify
    'dlcApp.controllers',           // Application controllers
    'dlcApp.factories',             // Application factories
    'dlcApp.filters',               // Application filters
    'dlcApp.values',                // Application values
    'datatables',                   // jQuery datatables
    'datatables.bootstrap',         // Angular datatables
    'pascalprecht.translate',       // Angular translate
    'colorpicker.module',           // Color picker
    'infinite-scroll'               // Infinite scrolling
  ];

  angular.module('dlcApp', dependencies)
    .config([
      '$urlRouterProvider', '$stateProvider', '$translateProvider',
      function ($urlRouterProvider, $stateProvider, $translateProvider) {
        $urlRouterProvider.otherwise(function ($injector) {
          var api = $injector.get('api');
          if (!api.hasToken()) {
            return '/auth/login';
          } else {
            return '/welcome';
          }
        });

        // Root application state, defining the layout.
        $stateProvider
          .state('app', {
            abstract: true,
            templateUrl: '/views/common/app_layout.html',
            resolve: {
              featureFlags: ['$rootScope', 'api', 'FeatureFlags', function ($rootScope, api, FeatureFlags) {
                return api.profile()
                  .then(function (res) {
                    $rootScope.featureFlags = {};

                    $rootScope.tokens = res.data.tokenBalance;

                    res.data.features.forEach(function (featureId) {
                      var feature = FeatureFlags[featureId];
                      $rootScope.featureFlags[feature] = true;
                    });

                    // Broadcast whether we have an error or not in order to show alerts
                    $rootScope.hasDashboardError = false;
                    return $rootScope.featureFlags;
                  })
                  .catch(function () {
                    $rootScope.hasDashboardError = true;
                  });
              }]
            }
          });

        $translateProvider
          .useSanitizeValueStrategy('escapeParameters')
          .uniformLanguageTag()
          .registerAvailableLanguageKeys(['en', 'de', 'zh', 'es', 'fr'], {
            'de-*': 'de',
            'en-*': 'en',
            'zh-*': 'zh',
            'fr-*': 'fr',
            'es-*': 'es'
          })
          .fallbackLanguage('en')
          .useStaticFilesLoader({
            prefix: 'i18n/',
            suffix: '.json'
          })
          .determinePreferredLanguage();
      }
    ])
    .run(['$rootScope', '$state', 'api', 'TranslateNotify', 'notify',
          '$modalStack', 'stats', '$location', '$window', '$timeout',
      function ($rootScope, $state, api, TranslateNotify, notify, $modalStack, stats, $location, $window, $timeout) {
        var gaTrackingCode;

        if ($location.host().indexOf('deltalink-cloud.com') !== -1) {
          gaTrackingCode = 'UA-62398189-2';
        } else {
          gaTrackingCode = 'UA-62398189-1';
        }

        $window.ga('create', gaTrackingCode);

        $rootScope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
          stats.start('stateChange:' + toState.name, toParams);

          // Close any modals before changing route.
          $modalStack.dismissAll();

          if (!api.hasToken() && !toState.name.match(/^(authentication|public)\./)) {
            // We're not logged in, and attempting to access a non-auth page. Redirect to log in.
            e.preventDefault();
            $state.go('authentication.login');
          } else if (api.hasToken() && toState.name.match(/^authentication\./)) {
            // We're logged in, but attempting to access an auth page. Redirect to dashboard.
            e.preventDefault();
            $state.go('app.welcome');
          }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          stats.stop('stateChange:' + toState.name);

          // Track pageviews
          stats.trackPageView($location.url(), toState && toState.data && toState.data.pageTitle,
            this.localStorage["language.selectedId"]);

          // Make sure you're looking at the top of any page that loads
          window.scroll(0, 0);

          // Initialise any resizable panels
          // Wrap this in a setTimeout otherwise Angular doesn't detect any panels on the page
          $timeout(function () {
            $('.ibox.ui-resizable').resizable({
              helper: 'ui-resizable-helper', // Class to add on resize, to control appearance during dragging
              grid: 20
            });
          });

          // Add classes to identify iOS7 and iOS8, which have poor
          // position: fixed support, to work around modal problems
          var ua = navigator.userAgent;
          var iPad = /iPad/.test(ua);
          if (iPad) {
            var uaOS = ua.indexOf('OS ');
            var uaOSMajorVersion = ua.slice(uaOS + 3, uaOS + 4);
            $('html').addClass('iPad').addClass('iOS' + uaOSMajorVersion);
          }

        });

        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
          var messageKey = 'errors.unknownError';

          if (error.status == 404) {
            messageKey = 'errors.pageNotFound';
          }

          TranslateNotify({
            messageKey: messageKey,
            classes: 'alert-danger'
          });

          if (api.hasToken()) {
            $state.go('app.welcome');
          } else {
            $state.go('authentication.login');
          }
        });

        notify.config({
          templateUrl: 'views/common/notify.html'
        });

        // For interactive debugging.
        window.api = api;

        // Workaround for notifications on test environment.
        if (window.location.hostname === '127.0.0.1') {
          notify.config({ duration: 0 });
        }
      }
    ]);

  // Application dependencies
  require('./controllers.js');
  require('./factories.js');
  require('./directives.js');
  require('./filters.js');
  require('./inspinia.js');
  require('./values.js');
})();
