module.exports = [
  '$scope', '$state', '$stateParams', '$q', '$timeout', 'ReportModel', 'ReportRepository', 'TranslateNotify',
  'DLCDefaultOptions', 'DTColumnDefBuilder', 'stats', 'api',
  function (
    $scope, $state, $stateParams, $q, $timeout, ReportModel, ReportRepository, TranslateNotify,
    DLCDefaultOptions, DTColumnDefBuilder, stats, api
  ) {
    var report = ReportRepository.get();

    $scope.report = report;
    $scope.queuedDatasetIds = [];
    $scope.selected = {
      dataset: {}
    };

    $scope.createTableOptions = function () {
      if ($scope.tableOptions) {
        return;
      }

      DLCDefaultOptions({ 2: 'asc' }).then(function (options) {
        $scope.reportTableOptions = options;

        $scope.reportColumnDefs = [
          DTColumnDefBuilder.newColumnDef(1).notSortable(),
          DTColumnDefBuilder.newColumnDef(2).withOption('orderDataType', 'cell-text')
        ];
      });
    };

    $scope.$on('DatasetsCtrl.datasetsLoaded', function () {
      $scope.createTableOptions();
    });

    $scope.applyChanges = function () {
      $scope.applying = true;
      if ($scope.selected.dataset.id) {
        api.getDataset($scope.selected.dataset.id)
          .then(function (res) {
            $scope.report.addDataset(res.data);
          });

        // Notify user of changes being applied
        TranslateNotify({
          messageKey: 'reports.addDataset.addDatasetSuccess',
          classes: 'alert-info'
        });
      }

      // Track add
      stats.sendEvent('Report', 'Add dataset');

      $state.go('app.reports.draft');
    };
  }
];
