angular
  .module('dlcApp.factories')
  .factory('ChartSeriesModel', [
    function () {
      /**
       * Basic model to represent ChartSeriesModel from the API.
       *
       * This doesn't currently have any methods, but it allows us to enforce the
       * types of objects being passed around, since there are some very similar ones
       * for charts/dataset/dataseries.
       *
       * @param {object} data Parsed object from the API.
       */
      function ChartSeriesModel(data) {
        this.datasetId = data.datasetId;
        this.dataSeriesId = data.dataSeriesId;
        if (/^\d+$/.test(data.chartType)) {
          this.chartType = parseInt(data.chartType, 10);
        } else {
          this.chartType = data.chartType;
        }
        this.color = data.color;
        this.symbol = data.symbol;
        this.roseName = data.roseName;
      }

      ChartSeriesModel.CHART_TYPE_LINE = 0;
      ChartSeriesModel.CHART_TYPE_STATS = 1;
      ChartSeriesModel.CHART_TYPE_ROSE = 2;
      ChartSeriesModel.CHART_TYPE_CLASS_ROSE = 3;
      ChartSeriesModel.CHART_TYPE_PROFILE = 4;
      ChartSeriesModel.CHART_TYPE_BAR = 5;
      ChartSeriesModel.CHART_TYPE_WRAPPED = 6;
      ChartSeriesModel.CHART_TYPE_STEPPED = 7;

      return ChartSeriesModel;
    }
  ]);
