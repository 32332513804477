(function (root) {
  function BaseStorage(storage) {
    if (typeof Storage === 'undefined') {
      throw new Error('This environment does not support Storage');
    }

    if (!(storage instanceof root.Storage)) {
      throw new Error('Invalid storage');
    }

    this.storage = storage;
  }

  BaseStorage.prototype.validateString = function (str, type) {
    if (typeof str !== 'string') {
      throw new Error(type + ' must be a string');
    }
  };

  BaseStorage.prototype.has = function (key) {
    this.validateString(key, 'Key');
    return this.storage.key(key);
  };

  BaseStorage.prototype.set = function (key, value) {
    this.validateString(key, 'Key');
    this.validateString(value, 'Value');
    return this.storage.setItem(key, value);
  };

  BaseStorage.prototype.get = function (key, defaultValue) {
    this.validateString(key, 'Key');
    if (defaultValue !== undefined) {
      this.validateString(defaultValue, 'Default value');
    }
    return this.storage.getItem(key) || defaultValue;
  };

  BaseStorage.prototype.remove = function (key) {
    this.validateString(key, 'Key');
    return this.storage.removeItem(key);
  };

  module.exports = BaseStorage;
}(typeof window !== 'undefined' ? window : global));
