module.exports = [
  function touchDetection() {
    return {
      restrict: 'A',
      link: function (scope, element) {
        scope.isTouchDevice = false;

        function isTouchDevice() {
          scope.$apply(function () {
            scope.isTouchDevice = true;
          });
        }

        element[0].addEventListener('touchstart', isTouchDevice, false);
        element[0].addEventListener('touchend', isTouchDevice, false);
        element[0].addEventListener('touchcancel', isTouchDevice, false);
        element[0].addEventListener('touchmove', isTouchDevice, false);
      }
    };
  }
];
