module.exports = [
  '$scope', 'TranslateNotify', 'GatewayScheduleModel', 'weekdays', 'timezones', 'repeatRates', 'api',
  function ($scope, TranslateNotify, GatewayScheduleModel, weekdays, timezones, repeatRates, api) {
    var _this = this;

    var hours = [];
    for (var i = 0; i < 24; ++i) {
      hours.push(i < 10 ? '0' + i.toString() : i.toString());
    }

    this.lookups = {
      weekdays: weekdays,
      timezones: timezones,
      repeatRates: repeatRates,
      hours: hours,
      minutes: [
        '00',
        '15',
        '30',
        '45'
      ]
    };

    this.setup = function (schedule) {
      _this.newSchedule = new GatewayScheduleModel(schedule);

      var startTime = schedule.startTime.split(':');
      _this.startTime = {
        hours: startTime[0],
        minutes: startTime[1]
      };

      var endTime = schedule.endTime.split(':');
      _this.endTime = {
        hours: endTime[0],
        minutes: endTime[1]
      };

      _this.selectedDays = [];
      if (Array.isArray(schedule.daysOfWeek)) {
        schedule.daysOfWeek.forEach(function (dayId) {
          _this.selectedDays[dayId] = true;
        });
      }

      repeatRates.some(function (option) {
        if (option.units === schedule.repeatRate && option.periodType === schedule.periodType) {
          _this.repeatRate = option;
          return true;
        }
      });
    };

    this.cancel = function () {
      _this.setup($scope.gateway.pendingSchedule || $scope.gateway.currentSchedule);
      if ($scope.cancel) {
        $scope.cancel();
      }
    };

    this.save = function () {
      api.updateGatewaySchedule(_this.newSchedule, $scope.gateway)
        .success(function (data) {
          // Update pending schedule settings
          $scope.gateway.pendingSchedule = data.pendingSchedule;

          TranslateNotify({
            messageKey: 'manageDevices.gatewayScheduleSettings.updateSuccess',
            classes: 'alert-info'
          });

          _this.cancel();
        })
        .error(function (err) {
          TranslateNotify({
            messageKey: err.name ? 'apiErrors.' + err.name : 'errors.unknownError',
            classes: 'alert-danger'
          });
        });
    };

    // Watch for when the gateway is set (note that this is on the scope directly
    // because of it being an isolate scope in the directive.
    $scope.$watch('gateway', function (gateway) {
      if (!gateway) {
        return;
      }

      _this.setup(gateway.pendingSchedule || gateway.currentSchedule);
    });

    // Watch for when the UI changes hours/minutes and update the relevant schedule property.
    var startTimeWatcher = function () {
      return _this.startTime;
    };
    $scope.$watchCollection(startTimeWatcher, function (startTime) {
      _this.newSchedule.startTime = startTime.hours + ':' + startTime.minutes;
    });

    var endTimeWatcher = function () {
      return _this.endTime;
    };
    $scope.$watchCollection(endTimeWatcher, function (endTime) {
      _this.newSchedule.endTime = endTime.hours + ':' + endTime.minutes;
    });

    // Watch for when the UI changes the selected days and build a proper array.
    var selectedDaysWatcher = function () {
      return _this.selectedDays;
    };
    $scope.$watchCollection(selectedDaysWatcher, function (selectedDays) {
      _this.newSchedule.daysOfWeek = [];

      selectedDays.forEach(function (value, index) {
        if (value) {
          _this.newSchedule.daysOfWeek.push(index);
        }
      });
    });

    // Watch for then the UI changes the selected repeat rate, set the relevant properties.
    var repeatRateWatcher = function () {
      return _this.repeatRate;
    };
    $scope.$watchCollection(repeatRateWatcher, function (option) {
      _this.newSchedule.repeatRate = option.units;
      _this.newSchedule.periodType = option.periodType;
    });
  }
];
