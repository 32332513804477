module.exports = {
  NONE: 0,
  VIEW: 1,
  CHANGE: 2,
  OWNER: 3,
  AUTO_VIEW: 4,
  AUTO_CHANGE: 5,
  canView: function (level) {
    return level > this.NONE;
  },
  canEdit: function (level) {
    return level > this.VIEW && level !== this.AUTO_VIEW;
  },
  canDelete: function (level) {
    return level === this.OWNER;
  }
};
