angular.module('dlcApp.controllers.authentication.logout', [])
  .controller('LogoutCtrl', [
    '$scope', '$state', 'api', 'TranslateNotify',
    function ($scope, $state, api, TranslateNotify) {
      $scope.logout = function () {
        api.logout();

        TranslateNotify({
          messageKey: 'general.loggedOut',
          classes: 'alert-info'
        });

        $state.go('authentication.login');
      };
    }
  ]);
