module.exports = [
  '$scope', '$modalInstance', 'report', 'dateRange', 'token', '$timeout', 'cookies',
  function ($scope, $modalInstance, report, dateRange, token, $timeout, cookies) {
    var DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

    $scope.report = report;

    $scope.separators = [
      {
        titleKey: 'reports.viewReport.downloadReport.separatorCharOptions.comma',
        value: ','
      },
      {
        titleKey: 'reports.viewReport.downloadReport.separatorCharOptions.tab',
        value: 'tab'
      },
      {
        titleKey: 'reports.viewReport.downloadReport.separatorCharOptions.semi-colon',
        value: ';'
      },
      {
        titleKey: 'reports.viewReport.downloadReport.separatorCharOptions.space',
        value: 'space'
      }
    ];
    $scope.download = {
      decimalChar: '.',
      separatorChar: ',',
      dateRange: {
        fromDate: dateRange.fromDate.format(DATE_FORMAT),
        toDate: dateRange.toDate.format(DATE_FORMAT)
      },
      token: token
    };

    var cookieOptions = cookies.get('download-csv-options');
    if (cookieOptions) {
      // Use the user's previous selection if valid
      cookieOptions = JSON.parse(cookieOptions);
      $scope.download.decimalChar = cookieOptions.decimalChar;

      $scope.separators.some(function (separator) {
        if (separator.value == cookieOptions.separatorChar) {
          $scope.download.separatorChar = cookieOptions.separatorChar;
          return true;
        }
      });
    }

    $scope.isoFromDate = dateRange.fromDate.format();
    $scope.isoToDate = dateRange.toDate.format();

    // Build array of all series used in charts
    $scope.dataSeries = [];
    $scope.report.charts.forEach(function (chart) {
      chart.series.forEach(function (chartSeries) {
        if (chartSeries.dataSeriesId) {
          $scope.dataSeries.push($scope.report.getDataSeries(chartSeries.dataSeriesId));
        } else if (chartSeries.roseName) {
          var roseSeries = $scope.report.getWindRoseDataSeries(chartSeries.datasetId, chartSeries.roseName);
          Array.prototype.push.apply($scope.dataSeries, roseSeries);
        } else {
          throw new Error('Invalid chartSeries');
        }
      });
    });

    $scope.submit = function (event) {
      // This happens before the form is posted

      // Use moment's validation to ensure valid datetimes.
      var fromDate = moment.utc($scope.download.dateRange.fromDate, DATE_FORMAT, true);
      var toDate = moment.utc($scope.download.dateRange.toDate, DATE_FORMAT, true);

      var fromValid = fromDate.isValid();
      var toValid = toDate.isValid();

      // Are the values the right way around?
      if (fromDate.valueOf() >= toDate.valueOf()) {
        fromValid = false;
        toValid = false;
      }

      // Update the form's validation properties.
      $scope.reportForm['daterange[from]'].$valid = fromValid;
      $scope.reportForm['daterange[to]'].$valid = toValid;

      // Stop if anything isn't valid.
      if (!fromValid || !toValid) {
        event.preventDefault();
        return false;
      }

      // Update the form with the parsed values.
      $scope.download.dateRange.fromDate = fromDate.format(DATE_FORMAT);
      $scope.download.dateRange.toDate = toDate.format(DATE_FORMAT);

      // Convert the user supplied dates to ISO format.
      $scope.isoFromDate = fromDate.format();
      $scope.isoToDate = toDate.format();

      // Check to make sure the access token has been set. When a report is shared the users that view this report are
      // not likely to be logged in, therefore they will have no access token against their user. Therefore use the
      // access token that the shared report has given us.
      if ($scope.download.token && !cookies.get('token')) {
        cookies.set('token', JSON.stringify($scope.download.token), { expires: $scope.download.token['.expires'] });
      }
      // Save the users decimal/separator selection in a cookie
      cookies.set('download-csv-options', JSON.stringify({
        decimalChar: $scope.download.decimalChar,
        separatorChar: $scope.download.separatorChar
      }));
    };
  }
];
