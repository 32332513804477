angular
  .module('dlcApp.factories')
  .factory('gatewayStatus', [
    function () {
      var signalKey = 'Signal Strength';

      function processSignal(gateway) {
        var status = gateway.status;

        if (!status[signalKey] || !status[signalKey].values.dBm) {
          return;
        }

        var dbmValue = status[signalKey].values.dBm.value;

        var signalWidget = {
          nameKey: 'devices.statuses.Signal Strength',
          icon: 'fa-signal',
          classes: '',
          textKey: ''
        };
        var signalBadge = {
          nameKey: 'devices.statuses.Signal Strength',
          icon: 'fa-signal',
          classes: '',
          textKey: ''
        };

        var signalTextKey;
        if (dbmValue < -90) {
          signalTextKey = 'devices.gateway.signalStrengthStates.bad';
          signalWidget.classes = 'red-bg';
          signalBadge.classes = 'text-danger';
        } else if (dbmValue < -80) {
          signalTextKey = 'devices.gateway.signalStrengthStates.ok';
          signalWidget.classes = 'yellow-bg';
          signalBadge.classes = 'text-warning';
        } else {
          signalTextKey = 'devices.gateway.signalStrengthStates.good';
          signalWidget.classes = 'navy-bg';
          // Note: "navy" in the theme is actually green!
          signalBadge.classes = 'text-navy';
        }

        signalWidget.textKey = signalTextKey;
        signalBadge.textKey = signalTextKey;

        gateway.widgets.push(signalWidget);
        gateway.badges.push(signalBadge);
      }

      function processNextConnect(gateway) {
        if (!gateway.nextConnect) {
          return;
        }

        var nextConnect = moment.utc(gateway.nextConnect);

        var nextConnectWidget = {
          nameKey: 'devices.statuses.Next connect',
          icon: 'fa-calendar',
          moment: nextConnect
        };

        var nextConnectBadge = {
          nameKey: 'devices.statuses.Next connect',
          icon: 'fa-calendar',
          moment: nextConnect
        };

        if (nextConnect.isBefore()) {
          nextConnectWidget.classes = 'red-bg';
          nextConnectBadge.classes = 'text-danger';
        } else {
          nextConnectWidget.classes = 'navy-bg';
          nextConnectBadge.classes = 'text-navy';
        }

        gateway.widgets.push(nextConnectWidget);
        gateway.badges.push(nextConnectBadge);
      }

      function processLastConnect(gateway) {
        if (!gateway.lastConnected) {
          return;
        }

        var lastConnected = moment.utc(gateway.lastConnected);

        var lastConnectedWidget = {
          nameKey: 'devices.statuses.Last connected',
          icon: 'fa-calendar',
          moment: lastConnected
        };

        var lastConnectedBadge = {
          nameKey: 'devices.statuses.Last connected',
          icon: 'fa-calendar',
          moment: lastConnected
        };

        if (gateway.lastExpectedConnect && gateway.lastExpectedConnect > gateway.lastConnected) {
          lastConnectedWidget.classes = 'yellow-bg';
          lastConnectedBadge.classes = 'text-warning';
        } else {
          lastConnectedWidget.classes = 'navy-bg';
          lastConnectedBadge.classes = 'text-navy';
        }

        gateway.widgets.push(lastConnectedWidget);
        gateway.badges.push(lastConnectedBadge);
      }

      return function (gateway) {
        gateway.widgets = [];
        gateway.badges = [];

        var status = {};

        if (gateway.statuses) {
          gateway.statuses.forEach(function (item) {
            if (item.name === signalKey) {
              if (!status[signalKey]) {
                status[signalKey] = {
                  name: signalKey,
                  values: {}
                };
              }

              status[signalKey].values[item.units] = {
                type: item.type,
                value: item.value
              };
            } else {
              status[item.name.toLowerCase()] = item;
            }
          });

          delete gateway.statuses;
        }

        gateway.status = status;

        processSignal(gateway);
        processLastConnect(gateway);
        processNextConnect(gateway);
      };
    }
  ]);
