angular
  .module('dlcApp.factories')
  .factory('GatewayScheduleModel', [
    function () {
      return function GatewayScheduleModel(options) {
        this.daysOfWeek = options.daysOfWeek;
        this.startTime = options.startTime;
        this.endTime = options.endTime;
        this.periodType = options.periodType;
        this.repeatRate = options.repeatRate;
        this.timeZone = options.timeZone;
      };
    }
  ]);
