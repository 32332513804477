module.exports = [
  '$translate', 'notify',
  function ($translate, notify) {
    return function (options) {
      if (options.messageTemplate) {
        throw new Error('Option \'messageTemplate\' is not supported by TranslateNotify');
      }

      if (options.message) {
        throw new Error('Option \'message\' is not supported by TranslateNotify');
      }

      if (!options.messageKey) {
        throw new Error('Missing required option \'messageKey\'');
      }

      if (
        typeof options.messageKey !== 'string' &&
        !(options.messageKey instanceof String) &&
        !Array.isArray(options.messageKey)
      ) {
        throw new Error('Option \'messageKey\' must be a string or array');
      }

      return $translate(options.messageKey, options.messageValues).then(function (translated) {
        var message;

        if (typeof translated === 'string' || translated instanceof String) {
          message = translated;
        } else {
          message = options.messageKey.map(function (key) {
            return translated[key];
          }).join('\n');
        }

        notify({
          message: message,
          classes: options.classes
        });

        return translated;
      });
    };
  }
];
