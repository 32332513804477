(function (root) {
  module.exports = [
    'SessionStorage',
    function (SessionStorage) {
      var BaseStorage = require('./base');

      var testKey = 'test-storage-' + Date.now();

      try {
        root.localStorage.setItem(testKey, testKey);
        if (root.localStorage.getItem(testKey) !== testKey) {
          throw new Error('Failed to use localStorage');
        }
        return new BaseStorage(root.localStorage);
      } catch (e) {
        console.error('Local storage unavailable', e.message);
      }

      return SessionStorage;
    }
  ];
}(typeof window !== 'undefined' ? window : global));
