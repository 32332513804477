var placeholderDataseries = {
  minimum: 0,
  maximum: 100,
  units: '°C'
};

module.exports = [
  'DataseriesTypes',
  function (DataseriesTypes) {
    return {
      scope: {
        widget: '=dashboardLineWidget'
      },
      link: function (scope, element) {
        var watchers = [];

        watchers.push(scope.$parent.$watch('data', function (val) {
          if (!val) {
            return;
          }
          var data = null;
          // process aggregated data
          if (val.length > 0 && val[0].stats !== undefined) {
            if (scope.chart.series[0].type !== 'arearange') {
              scope.chart.series[0].update({ type: 'arearange' }, false);
            }

            data = val.map(function (bucket) {
              return [
                Date.parse(bucket.key_as_string),
                bucket.stats.min,
                bucket.stats.max
              ];
            });
          // This section handles non-aggregated data
          } else {
            data = val.map(function (e) {
              return [Date.parse(e.date), e.floatValue];
            });
          }

          scope.chart.series[0].setData(data);
        }));

        scope.$on('$destroy', function () {
          watchers.forEach(function (stop) {
            stop();
          });

          if (scope.chart) {
            scope.chart.destroy();
            delete scope.chart;
          }
        });

        var getDataSeries = function () {
          var ds = scope.widget && scope.widget.detail && scope.widget.detail.data &&
            scope.widget.detail.data.dataseries && scope.widget.detail.data.dataseries[0];
          return ds || placeholderDataseries;
        };

        var getCategories = function () {
          var dataseries = getDataSeries();

          if (dataseries.type === DataseriesTypes.BINARY || dataseries.type === DataseriesTypes.BOOLEAN) {
            if (dataseries.units.indexOf('~') === -1) {
              return ['false', 'true'];
            } else {
              return dataseries.units.split('~');
            }
          }

          return null;
        };

        var getPointFormatter = function () {
          return function () {
            var dataseries = getDataSeries();
            var categories = getCategories();

            var formattedValue = function (value) {
              if (categories === null) {
                return value.toFixed(scope.$parent.dataDecimalPlaces) + dataseries.units;
              } else {
                return categories[value];
              }
            };

            var prefix = '<span style="color:' + this.color + '">\u25CF</span>' + this.series.name + ': <b>';

            if (this.series.type === 'arearange') {
              return prefix + formattedValue(this.low) + ' - ' + formattedValue(this.high) + '</b><br/>';
            } else {
              return prefix + formattedValue(this.y) + '</b><br/>';
            }
          };
        };

        var dataseries = getDataSeries();
        var categories = getCategories();

        var dataStartDT = Date.parse(scope.widget.detail.dataStartDT);
        var dataEndDT = Date.parse(scope.widget.detail.dataEndDT);

        scope.chart = new Highcharts.Chart({
          chart: {
            type: 'line',
            renderTo: element[0]
          },
          xAxis: {
            type: 'datetime',
            min: dataStartDT,
            max: dataEndDT
          },
          yAxis: {
            title: {
              text: dataseries.units
            },
            categories: categories,
            min: scope.widget.detail.minimum,
            max: scope.widget.detail.maximum,
            startOnTick: true,
            endOnTick: true,
            tickmarkPlacement: 'on'
          },
          series: [{
            dashStyle: dataseries.isProgramSetting ? 'dash' : undefined,
            name: dataseries.name,
            step: categories !== null,
            tooltip: {
              valueDecimals: scope.$parent.dataDecimalPlaces,
              pointFormatter: getPointFormatter()
            }
          }],
          legend: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          title: {
            text: null
          }
        });

        function changeLineDisplayRange() {
          var ds = scope.widget.detail.data.dataseries[0];

          var categories = getCategories();

          scope.chart.update({
            yAxis: {
              title: {
                text: ds && ds.units || undefined
              },
              categories: categories,
              min: scope.widget.detail.minimum,
              max: scope.widget.detail.maximum
            }
          });

          scope.chart.series[0].update({
            dashStyle: ds.isProgramSetting ? 'dash' : undefined,
            name: ds.name,
            step: categories !== null,
            tooltip: {
              pointFormatter: getPointFormatter()
            }
          });
        }

        watchers.push(scope.$watch('widget.detail.data.dataseries', function (ds) {
          if (!ds) {
            return;
          }

          changeLineDisplayRange();
        }));

        watchers.push(scope.$watch('widget.detail.minimum', function () {
          changeLineDisplayRange();
        }));

        watchers.push(scope.$watch('widget.detail.maximum', function () {
          changeLineDisplayRange();
        }));

        var onThresholdsChanged = function () {
          var axis = scope.chart.yAxis[0];

          // This removes them all since we give them all the same id.
          axis.removePlotBand('plotband');

          var thresholds = (scope.widget.thresholds || []).slice();

          if (thresholds.length === 0) {
            return;
          }

          // Prepend the band from minimum to the first threshold using the widget's default color.
          thresholds.unshift({
            color: scope.widget.color,
            data: {
              number: -Infinity
            }
          });

          thresholds.forEach(function (t, idx, all) {
            var nextValue;

            if (idx < all.length - 1) {
              nextValue = all[idx + 1].data.number;
            } else {
              nextValue = Infinity;
            }

            axis.addPlotBand({
              id: 'plotband',
              color: t.color,
              from: t.data.number,
              to: nextValue
            });
          });
        };

        watchers.push(scope.$watch('widget.color', onThresholdsChanged));
        watchers.push(scope.$watchCollection('widget.thresholds', onThresholdsChanged));
      }
    };
  }
];
