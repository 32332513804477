angular
  .module('dlcApp.factories')
  .factory('ReportRepository', [
    'ReportModel',
    'SessionStorage',
    function (ReportModel, SessionStorage) {
      var STORAGE_KEY = 'ReportRepository.report';

      var ReportRepository = function () {
        this.report = null;

        var report = SessionStorage.get(STORAGE_KEY);
        if (report) {
          try {
            this.report = new ReportModel(JSON.parse(report));
          } catch (e) {
            console.error(e);
          }
        }
      };

      ReportRepository.prototype.set = function (report) {
        this.report = report;
        SessionStorage.set(STORAGE_KEY, angular.toJson(report));
      };

      ReportRepository.prototype.get = function () {
        return this.report;
      };

      return new ReportRepository();
    }
  ]);
