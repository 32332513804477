module.exports = [
  '$scope', '$state', '$modal', '$stateParams', '$timeout', '$q', '$translate', 'TranslateNotify', 'PermissionTypes',
  'api', 'datasetGrouping', 'ReportModel', 'ReportRepository', 'ReportView', 'stats', 'chart',
  function (
    $scope, $state, $modal, $stateParams, $timeout, $q, $translate, TranslateNotify, PermissionTypes,
    api, datasetGrouping, ReportModel, ReportRepository, ReportView, stats, chart
  ) {
    var viewInstance = new ReportView();
    $scope.view = viewInstance;

    // Allow debugging of specific ranges by adding a zoomTo function to the window.
    window.zoomTo = function (from, to) {
      viewInstance.zoomTo($scope.report, from ? moment.utc(from) : null, to ? moment.utc(to) : null);
    };

    $scope.updateReport = function (report, saveOnApply) {
      var modal = $modal.open({
        templateUrl: '/views/reports/report_form.html',
        controller: 'ReportModalCtrl',
        resolve: {
          report: function () {
            return angular.copy(report);
          },
          saveOnApply: function () {
            return saveOnApply ? true : false;
          }
        }
      });

      modal.result.then(function (report) {
        $scope.report.name = report.name;
        $scope.report.description = report.description;

        // Track update
        stats.sendEvent('Report', 'Edit');

        if (saveOnApply) {
          $scope.saveReport();
        }
      });
    };

    $scope.renameChart = function (chart) {
      var modal = $modal.open({
        templateUrl: '/views/reports/chart_rename.html',
        controller: 'RenameChartModalCtrl',
        resolve: {
          chart: function () {
            return angular.copy(chart);
          }
        }
      });

      modal.result.then(function (chartData) {
        chart.name = chartData.name;
        chart.description = chartData.description;

        // Track rename
        stats.sendEvent('Report', 'Rename chart');
      });
    };

    $scope.removeDataset = function (datasetIndex) {
      var dataset = $scope.report.datasets[datasetIndex];
      var modal = $modal.open({
        templateUrl: '/views/reports/dataset_remove.html',
        controller: 'ReportDatasetModalCtrl',
        resolve: {
          dataset: function () {
            return dataset;
          }
        }
      });

      modal.result.then(function () {
        // Iterate through each chart and check if it uses any series
        // that belong to the dataset we're deleting
        $scope.report.charts = $scope.report.charts.filter(function (chart) {
          chart.series = chart.series.filter(function (series) {
            return series.datasetId != dataset.id;
          });
          return chart.series.length;
        });

        // Remove the dataset from the report.
        $scope.report.deleteDataset(datasetIndex);

        TranslateNotify({
          messageKey: 'reports.viewReport.removeDatasetSuccess',
          classes: 'alert-info'
        });

        // Track delete
        stats.sendEvent('Report', 'Remove dataset');

        // Re-render the report by taking the user to the draft page
        if ($stateParams.draft) {
          $state.reload();
        } else {
          $state.go('app.reports.draft');
        }
      });
    };

    $scope.saveReport = function () {
      var saveStartTime = Date.now();
      $scope.isSaving = true;

      // Function to ensure loading spinner on the save button is shown long enough to be visible.
      var saveComplete = function () {
        var saveDuration = Date.now() - saveStartTime;
        var timeout = saveDuration < 1500 ? 1500 - saveDuration : 0;

        $timeout(function () {
          $scope.isSaving = false;
        }, timeout);
      };

      var success = function (data, status, headers) {
        saveComplete();

        if (status == 201) {
          // We need to update the report with the id.
          var reportIdMatches = (headers('location') || '').match(/reports\/(\d+)/i);

          if (!reportIdMatches) {
            // We saved the report, but failed to get an id back, this shouldn't ever happen.
            return TranslateNotify({
              messageKey: 'errors.unknownError',
              classes: 'alert-danger'
            });
          }

          $scope.report.id = reportIdMatches[1];

          console.log('Notifying reports.viewReport.createReportSuccess');
          TranslateNotify({
            messageKey: 'reports.viewReport.createReportSuccess',
            messageValues: { report: $scope.report },
            classes: 'alert-info'
          });

          $state.go('app.reports.show', { reportId: $scope.report.id });
        } else {
          // Just inform the user the report was saved.
          TranslateNotify({
            messageKey: 'reports.viewReport.saveReportSuccess',
            messageValues: { report: $scope.report },
            classes: 'alert-info'
          });
        }
      };

      var error = function (err) {
        saveComplete();

        TranslateNotify({
          messageKey: err.name ? 'apiErrors.' + err.name : 'reports.viewReport.saveReportFailure',
          classes: 'alert-danger'
        });
      };

      if (!$scope.report.id) {
        api.createReport($scope.report)
          .success(success)
          .error(error)
          .then(function () {
            // Track saving new report
            stats.sendEvent('Report', 'Generate');
          });
      } else {
        api.updateReport($scope.report)
          .success(success)
          .error(error)
          .then(function () {
            // Track save
            stats.sendEvent('Report', 'Save');
          });
      }
    };

    var createReportFromDataset = function (dataset) {
      var deferred = $q.defer();

      var translateKeys = [
        'reports.viewReport.generatedName',
        'reports.viewReport.generatedDescription'
      ];
      $translate(translateKeys, { dataset: dataset }).then(function (translations) {
        var statName = 'createReportFromDataset:' + dataset.id;

        stats.start(statName);

        var report = new ReportModel({
          name: translations['reports.viewReport.generatedName'],
          description: translations['reports.viewReport.generatedDescription'],
          charts: datasetGrouping(dataset),
          datasets: [
            dataset
          ],
          lastUpdated: false,
          accessLevel: dataset.accessLevel
        });

        stats.stop(statName);

        deferred.resolve(report);
      });

      return deferred.promise;
    };

    $scope.shareReport = function (report) {
      // Track share
      stats.sendEvent('Report', 'Share');

      $modal.open({
        templateUrl: '/views/reports/report_share.html',
        controller: 'SharedReportModalCtrl',
        resolve: {
          report: function () {
            return report;
          },
          timeframeQuery: function () {
            return $scope.timeframe;
          },
          dateRange: function () {
            return {
              fromDate: moment.utc(viewInstance.globalXMin),
              toDate: moment.utc(viewInstance.globalXMax)
            };
          }
        }
      });
    };

    $scope.downloadReport = function (report) {
      var modal = $modal.open({
        templateUrl: '/views/reports/report_download_modal.html',
        controller: 'DownloadReportModalCtrl',
        resolve: {
          token: function () {
            return;
          },
          report: function () {
            return report;
          },
          dateRange: function () {
            return {
              fromDate: moment.utc(viewInstance.globalXMin),
              toDate: moment.utc(viewInstance.globalXMax)
            };
          }
        }
      });

      modal.result.then(function (report) {
        $scope.report.name = report.name;
        $scope.report.description = report.description;

        // Track update
        stats.sendEvent('Report', 'Download');
      });
    };

    var report;
    var deferred;

    if ($stateParams.draft) {
      report = ReportRepository.get();

      if (!report) {
        $state.go('app.welcome');
        TranslateNotify({
          messageKey: 'reports.viewReport.loadDraftFailure',
          classes: 'alert-danger'
        });
      }
    } else if ($stateParams.reportId) {
      deferred = $q.defer();
      report = deferred.promise;

      api.getReport($stateParams.reportId)
        .success(function (data) {
          var report = new ReportModel(data);
          // If you are saving an existing report then you can only save if you have edit permissions or higher.
          $scope.canSaveReport = PermissionTypes.canEdit(report.accessLevel);
          deferred.resolve(report);
        })
        .error(function (err) {
          deferred.reject(err);
          TranslateNotify({
            messageKey: err.name ? 'apiErrors.' + err.name : 'errors.unknownError',
            classes: 'alert-danger'
          });
        });
    } else {
      // Load in dataset and init report object properties with default values
      deferred = $q.defer();
      report = deferred.promise;

      api.getDataset($stateParams.datasetId)
        .then(function (res) {
          createReportFromDataset(res.data).then(function (report) {
            // If you are saving a dataset you are creating a report, anyone with view permissions on a dataset
            // or higher can create a new report with this dataset on it.
            $scope.canSaveReport = PermissionTypes.canView(report.accessLevel);
            deferred.resolve(report);
          });
        })
        .catch(function (err) {
          console.error('getDataset', arguments);
          deferred.reject(err);
        });
    }

    $q.when(report).then(function (report) {
      $scope.canEditOrDeleteReport = PermissionTypes.canEdit(report.accessLevel);
      report.loadDatasets().then(function () {
        $scope.report = report;
        ReportRepository.set(report);

        viewInstance.renderReport(report)
          .then(function (loadDataFunctions) {
            if (chart) {
              var element = document.getElementById('chart-row-' + chart.index);

              if (!element) {
                return;
              }

              // Force the chart we're scrolling to to load first.
              var loadFunction = loadDataFunctions.splice(chart.index, 1);
              loadDataFunctions.unshift(loadFunction[0]);

              element.scrollIntoView();
            }
          });
      });
    });

    $scope.deleteChart = function (chartIndex) {
      $scope.report.deleteChart(chartIndex);
      TranslateNotify({
        messageKey: 'reports.viewReport.deleteChartSuccess',
        classes: 'alert-info'
      });
      // Track delete
      stats.sendEvent('Report', 'Remove chart');
    };

    $scope.addChart = function () {
      // Init our new chart object
      var chart = {
        name: 'Chart ' + ($scope.report.charts.length + 1),
        description: '',
        series: []
      };

      // Take user to chart dataseries page.
      var newChartIndex = $scope.report.addChart(chart);
      $state.go('app.reports.dataseriesEdit', { chartIndex: newChartIndex });

      TranslateNotify({
        messageKey: 'reports.viewReport.createChartSuccess',
        classes: 'alert-info'
      });

      // Track create
      stats.sendEvent('Report', 'Create chart');
    };
  }
];
