module.exports = [
  '$rootScope',
  function bodyClass($rootScope) {
    return {
      link: function (scope, elem) {
        function BodyClassCtrl() {
          this.state = '';
          this.isCollapsed = false;
        }

        BodyClassCtrl.prototype.useCollapsedSideBar = function () {
          this.isCollapsed = true;
          this.updateClasses();
        };

        BodyClassCtrl.prototype.useNormalSideBar = function () {
          this.isCollapsed = false;
          elem.addClass('mini-navbar');
          this.updateClasses(false);
        };

        BodyClassCtrl.prototype.setState = function (state) {
          this.state = state;
          this.updateClasses();
        };

        BodyClassCtrl.prototype.updateClasses = function (allowCollapsed) {
          if (allowCollapsed === undefined) {
            allowCollapsed = true;
          }

          var classes = [];

          if (this.state.match(/^authentication\./)) {
            classes.push('gray-bg');
          } else if (this.state.match(/^public\./)) {
            classes.push('top-navigation');
            classes.push('skin-1');
          } else {
            classes.push('skin-1');
            classes.push('pace-done');
            if (!this.isCollapsed) {
              classes.push('fixed-sidebar');
            }
          }

          // inspinia.js adds classes to body on load and resize to control left nav visibility
          // Check for these here and add the classes back if needed carefully to maintain sidebar state.
          if (elem.hasClass('body-small')) {
            // Maintain body-small if it's set.
            classes.push('body-small');

            $('#side-menu').hide();
          } else if ((allowCollapsed && elem.hasClass('mini-navbar')) || ctrl.isCollapsed) {
            classes.push('mini-navbar');
          }

          if (this.state) {
            classes.push(this.state);
            classes.push(this.state.replace(/\./g, '-'));
          }

          elem.attr('class', classes.join(' '));
        };

        var ctrl = new BodyClassCtrl();
        $rootScope.bodyClassCtrl = ctrl;

        $rootScope.$on('$stateChangeSuccess', function (event, toState) {
          ctrl.setState(toState.name);
        });
      }
    };
  }
];
