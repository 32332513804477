module.exports = [
  '$rootScope',
  '$state',
  function ($rootScope, $state) {
    // If you don't have access to the dashboards, redirect the user away
    // from these pages.
    if (!$rootScope.featureFlags.CAN_SEE_DASHBOARDS) {
      $state.go('app.welcome', null, { location: 'replace' });
    }
  }
];
