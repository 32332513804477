module.exports = [
  '$scope', '$state', '$translate', '$interval', '$sce', 'ChartSeriesModel', 'DashboardRepository', 'DatasetStates',
  'DataseriesTypes', 'WidgetTypes', 'Utils', 'api',
  function (
    $scope, $state, $translate, $interval, $sce, ChartSeriesModel, DashboardRepository, DatasetStates,
    DataseriesTypes, WidgetTypes, Utils, api
  ) {
    $scope.dashboard = DashboardRepository.get();

    var originalWidget = $scope.dashboard.widgets[$state.params.widgetIndex];
    $scope.widget = angular.copy(originalWidget);
    $scope.widget.placeholder = true;

    $scope.editWidgetTabs = [
      { active: true },
      { active: false },
      { active: false }
    ];

    var showHistory = $scope.widget.detail && $scope.widget.detail.showHistory;

    $scope.WidgetTypes = WidgetTypes;

    $scope.datasets = null;
    $scope.dataseries = null;

    $scope.selectedDataset = null;
    $scope.selectedDataseries = null;

    // onChange function that fires when choosing a *dataset* on the Edit Widget
    // page: /dashboards/edit-widget/X, see dashboard_edit_widget.html
    $scope.setDataset = function (dataset) {
      $scope.selectedDataset = dataset;
      $scope.selectedDataseries = null;

      $scope.dataseries = null;
      $scope.widget.detail = {
        showHistory: showHistory
      };

      if (!dataset) {
        return;
      }

      // Fetch the dataset info, populate the dataseries array in the scope
      $scope.fetchingDataseries = true;
      api.getDataset($scope.selectedDataset.id)
        .then(function (res) {
          $scope.fetchingDataseries = false;

          if (res.data.timeOffset) {
            $scope.widget.timeOffset = res.data.timeOffset;
          }

          $scope.dataseries = res.data.dataSeries
            .filter(function (ds) {
              // See if the widget we want is a Wind Rose or a Wind Class Rose,
              // so that we can remove any data series that can't be displayed
              // by those chart types
              if ($scope.widget.type === WidgetTypes.WINDROSE) {
                return ds.chartDetail.chartType === ChartSeriesModel.CHART_TYPE_ROSE ||
                  ds.chartDetail.chartType === ChartSeriesModel.CHART_TYPE_CLASS_ROSE;
              }

              // We've already handled windroses, windrose data should not appear on any other widgets.
              if (
                ds.chartDetail.chartType === ChartSeriesModel.CHART_TYPE_ROSE ||
                ds.chartDetail.chartType === ChartSeriesModel.CHART_TYPE_CLASS_ROSE
              ) {
                return false;
              }

              if (ds.type === DataseriesTypes.BINARY || ds.type === DataseriesTypes.BOOLEAN) {
                return $scope.widget.type !== WidgetTypes.GAUGE;
              }

              return true;
            });
        });
    };

    // onChange function that fires when choosing a *dataseries* on the Edit Widget
    // page: /dashboards/edit-widget/X, see dashboard_edit_widget.html
    $scope.setDataseries = function (dataseries) {
      // If the widget is a Wind Rose, we need to take the single selected
      // series and get all the other dataseries that this Wind Rose will need
      if ($scope.widget.type === WidgetTypes.WINDROSE) {
        var dataseriesLikeSelected = [];

        dataseriesLikeSelected = $scope.dataseries.filter(function (ds) {
          if (ds.chartDetail.chartType !== dataseries.chartDetail.chartType) {
            return false;
          }

          if (ds.chartDetail.roseName === dataseries.chartDetail.roseName) {
            return true;
          }
        }).map(function (ds) {
          ds = angular.copy(ds);
          ds.datasetId = $scope.selectedDataset.id;
          return ds;
        });

        $scope.selectedDataseries = dataseries;

        $scope.widget.detail = {
          showHistory: showHistory,
          data: {
            dataseries: dataseriesLikeSelected
          }
        };

        $scope.widget.thresholds.forEach(thresholdSummaryHTML);
        return;
      }

      $scope.selectedDataseries = dataseries;
      if (dataseries) {
        dataseries = angular.copy(dataseries);
        dataseries.datasetId = $scope.selectedDataset.id;
      }

      $scope.widget.detail = {
        showHistory: showHistory,
        data: {
          // Dataseries needs to be passed in as an array, as you can have multiple dataseries on a widget
          dataseries: [dataseries]
        }
      };

      $scope.widget.thresholds.forEach(thresholdSummaryHTML);
    };

    // onChange function that fires when choosing a *timespan* on the Edit Widget
    // page: /dashboards/edit-widget/X, see dashboard_edit_widget.html.
    // Not used for all widget types
    $scope.updateTimeSpan = function () {
      $scope.timeSpan.dirty = true;

      if (!$scope.timeSpan.seconds) {
        $scope.timeSpan.valid = false;
        return;
      }

      $scope.timeSpan.valid = true;
      $scope.widget.detail.dataTimeSpan = $scope.timeSpan.seconds;

      var now = moment();
      $scope.widget.detail.dataEndDT = now.format();
      $scope.widget.detail.dataStartDT = now.subtract($scope.timeSpan.seconds, 'second').format();
    };

    // Validation function that disables the Apply button until certain
    // conditions are met (e.g. must choose dataseries etc.)
    $scope.validate = function () {
      var data = Utils.get($scope, 'widget.detail.data', {});
      return (data.dataseries || data.text) &&
        ($scope.widget.type !== WidgetTypes.LINE || $scope.timeSpan.valid) &&
        ($scope.widget.type !== WidgetTypes.WINDROSE || $scope.timeSpan.valid) &&
        $scope.minMax.valid;
    };

    $scope.updateMinMax = function () {
      $scope.minMax.dirty = true;
      var min = $scope.widget.detail.minimum;
      var max = $scope.widget.detail.maximum;

      if (!isNaN(parseInt(min, 10)) && !isNaN(parseInt(max, 10))) {
        $scope.minMax.valid = min < max;
        return;
      }

      $scope.minMax.valid = true;
    };

    // Runs when you click the Apply button
    $scope.update = function () {
      var widget = $scope.widget;
      widget.placeholder = false;

      if ($scope.timeSpan.valid) {
        widget.detail.dataTimeSpan = $scope.timeSpan.seconds;

        var now = moment();
        widget.detail.dataEndDT = now.format();
        widget.detail.dataStartDT = now.subtract($scope.timeSpan.seconds, 'second').format();
      } else {
        delete widget.detail.dataTimeSpan;
        delete widget.detail.dataStartDT;
        delete widget.detail.dataEndDT;
      }

      $scope.dashboard.widgets[$state.params.widgetIndex] = widget;
      $state.go('app.dashboards.draft');
    };

    $scope.thresholdNumberChanged = function () {
      delete $scope.threshold.data.dataset;
      delete $scope.threshold.data.dataseries;
    };

    $scope.thresholdDatasetChanged = function () {
      delete $scope.threshold.data.number;
      delete $scope.threshold.data.dataseries;

      if (!$scope.threshold.data.dataset.dataSeries) {
        api.getDataset($scope.threshold.data.dataset.id)
          .then(function (res) {
            $scope.threshold.data.dataset.dataSeries = res.data.dataSeries;
          });
      }
    };

    $scope.thresholdDataseriesChanged = function () {
      delete $scope.threshold.data.number;
    };

    $scope.validateThreshold = function () {
      var t = $scope.threshold;

      if (!t || !t.data) {
        return false;
      }

      var number = parseInt(t.data.number || '', 10);

      if (!isNaN(number) && t.data.dataseries) {
        console.log('Cannot have a value *and* a dataseries');
        return false;
      }

      return (!isNaN(number) || t.data.dataseries) && t.color;
    };

    $scope.addThreshold = function () {
      var threshold = angular.copy($scope.threshold);
      delete threshold.isNew;
      $scope.widget.thresholds.push(threshold);

      $scope.newThreshold();
    };

    $scope.updateThreshold = function () {
      var threshold = angular.copy($scope.threshold);
      var index = threshold.index;
      delete threshold.index;
      $scope.widget.thresholds.splice(index, 1, threshold);

      $scope.newThreshold();
    };

    $scope.editThreshold = function (threshold, index) {
      // Automatically switch to the thresholds tab.
      $scope.editWidgetTabs = [
        { active: false },
        { active: true },
        { active: false }
      ];

      $scope.threshold = angular.copy(threshold);
      $scope.threshold.index = index;

      // TODO: Handle multiple datasets
      var selectedDatasetId = Utils.get($scope, 'threshold.data.dataseries')[0].datasetId;
      var selectedDataseriesId = Utils.get($scope, 'threshold.data.dataseries')[0].id;

      if (threshold.data.dataseries && threshold.data.dataseries.datasetId && !$scope.threshold.data.dataset) {
        $scope.datasets.some(function (ds) {
          if (ds.id === selectedDatasetId) {
            $scope.threshold.data.dataset = ds;
            return true;
          }
        });
      }

      if (selectedDatasetId && !Utils.get($scope, 'threshold.data.dataset.dataSeries')) {
        api.getDataset(selectedDatasetId)
          .then(function (res) {
            $scope.threshold.data.dataset.dataSeries = res.data.dataSeries;

            res.data.dataSeries.some(function (ds) {
              if (ds.id === selectedDataseriesId) {
                $scope.threshold.data.dataseries = ds;
                return true;
              }
            });
          });
      }
    };

    $scope.deleteThreshold = function (threshold, index) {
      // Automatically switch to the thresholds tab.
      $scope.editWidgetTabs = [
        { active: false },
        { active: true },
        { active: false }
      ];

      $scope.threshold = threshold;
      $scope.threshold.isNew = true;

      $scope.widget.thresholds.splice(index, 1);
    };

    $scope.newThreshold = function () {
      $scope.threshold = {
        isNew: true,
        color: 'rgba(0,0,0,1.0)',
        data: {
        }
      };

      if (!Array.isArray($scope.widget.thresholds)) {
        return;
      }

      // Sort the thresholds - numbers first, then dataseries-based.
      $scope.widget.thresholds.sort(function (a, b) {
        a = a.data.number;
        b = b.data.number;

        if (a === b) {
          return 0;
        }

        return a < b ? -1 : 1;
      });

      // Update the summary for all thresholds.
      $scope.widget.thresholds.forEach(thresholdSummaryHTML);
    };

    // TODO: Is there a nicer way to achieve this? HTML shouldn't be in the controller.
    function thresholdSummaryHTML(threshold) {
      if (!$scope.selectedDataseries) {
        return null;
      }

      return $translate('dashboards.editWidget.summary.thresholdSummary')
        .then(function (translated) {
          translated = translated.replace('%1$s', '<strong>' + $scope.selectedDataseries.name + '</strong>');

          var value;
          if (threshold.data.dataseries) {
            value = threshold.data.dataseries.name;
          } else {
            value = threshold.data.number;
          }
          translated = translated.replace('%2$s', '<strong style="color: ' + threshold.color + '">' +
            value + '</strong>');

          // TODO: Move these inline styles to the stylesheets (except background-color
          // since that can't be set elsewhere that I know of).
          translated = translated.replace('%3$s',
            '<span style="display: inline-block; width: 16px; height: 16px; background-color: ' +
            threshold.color + '">&nbsp;</span>');
          threshold.summary = $sce.trustAsHtml(translated);
        });
    }

    // Controller init code starts here.

    // Update the summary for all thresholds.
    if (Array.isArray($scope.widget.thresholds)) {
      $scope.widget.thresholds.forEach(thresholdSummaryHTML);
    }

    if (Utils.get($scope, 'widget.detail.data.text')) {
      $scope.datasets = false;
      $scope.isStaticText = true;
    } else {
      api.getDatasets()
        .then(function (res) {
          var allDatasets = res.data;

          allDatasets.forEach(function (ds) {
            ds.groupName = 'devices.logger.loggingStatuses.' + DatasetStates.getName(ds.state).toLowerCase();
          });

          var stateOrder = [
            DatasetStates.LOGGING,
            DatasetStates.STOPPED,
            DatasetStates.IMPORTED,
            DatasetStates.ARCHIVED,
            DatasetStates.EXAMPLE
          ];
          allDatasets.sort(function (a, b) {
            var stateSort = stateOrder.indexOf(a.state) - stateOrder.indexOf(b.state);
            if (stateSort !== 0) {
              return stateSort;
            }

            return a.name.localeCompare(b.name);
          });

          // TODO: Handle multiple dataseries
          var widgetDataseries = Utils.get($scope, 'widget.detail.data.dataseries', [{}])[0];
          var selectedDataseriesId = widgetDataseries.id;
          var selectedDatasetId = widgetDataseries.datasetId;

          // Pre-select the dataset and dataseries if the widget already has one set
          var foundDataset = allDatasets.some(function (dataset) {
            if (dataset.id === selectedDatasetId) {
              $scope.selectedDataset = dataset;

              api.getDataset(dataset.id)
                .then(function (res) {
                  var allDataseries = res.data.dataSeries;

                  // Same as up in the $scope.setDataset function, we need to
                  // filter out any dataseries that are not compatible with
                  // being show on a Wind Rose or Wind Class Rose chart
                  allDataseries.some(function (dataseries) {
                    if (dataseries.id === selectedDataseriesId) {
                      $scope.selectedDataseries = dataseries;
                      $scope.newThreshold();
                      return true;
                    }
                  });

                  $scope.datasets = allDatasets;
                  $scope.dataseries = allDataseries;
                });

              return true;
            }
          });

          if (!foundDataset) {
            // Failed to find the currently-selected item, so offer all options immediately.
            $scope.datasets = allDatasets;
            $scope.newThreshold();
          }
        });
    }

    $scope.timeSpan = {
      seconds: null,
      dirty: false,
      valid: false
    };

    $scope.minMax = {
      dirty: false,
      valid: true
    };

    var translationPrefix = 'dashboards.editWidget.timeSpan.periods.';
    var timeSpanPeriodKeys = [
      translationPrefix + '1hr',
      translationPrefix + '4hrs',
      translationPrefix + '12hrs',
      translationPrefix + '1day',
      translationPrefix + '2days',
      translationPrefix + '3days',
      translationPrefix + '1week',
      translationPrefix + '2weeks',
      translationPrefix + '4weeks',
      translationPrefix + '8weeks',
      translationPrefix + '6months',
      translationPrefix + '1year'
    ];

    $translate(timeSpanPeriodKeys)
      .then(function (translations) {
        var HOUR = 3600;
        var DAY = HOUR * 24;
        var WEEK = DAY * 7;
        var MONTH = DAY * 30;
        var YEAR = DAY * 365;

        var unitSeconds = [
          1 * HOUR,
          4 * HOUR,
          12 * HOUR,
          1 * DAY,
          2 * DAY,
          3 * DAY,
          1 * WEEK,
          2 * WEEK,
          4 * WEEK,
          8 * WEEK,
          6 * MONTH,
          1 * YEAR
        ];

        $scope.timeSpanUnits = timeSpanPeriodKeys.map(function (key, idx) {
          return {
            name: translations[key],
            seconds: unitSeconds[idx]
          };
        });

        // Update the input on load to show the current selection if set
        var currentSeconds = null;

        var widgetDetail = originalWidget.detail;
        if (widgetDetail.dataTimeSpan) {
          // Check for dataTimeSpan *first*, since dataStartDT and dataEndDT are set to
          // fake values on placeholder widgets
          currentSeconds = widgetDetail.dataTimeSpan;
        } else if (widgetDetail.dataStartDT && widgetDetail.dataEndDT) {
          // Fall back to calculating the period from the start and end datetimes
          var start = moment.utc(widgetDetail.dataStartDT);
          var end = moment.utc(widgetDetail.dataEndDT);

          currentSeconds = end.diff(start, 'seconds');
        } else {
          // We don't need to do anything with the timeSpan on this one.
          return;
        }

        $scope.timeSpan.valid = $scope.timeSpanUnits.some(function (unit) {
          if (unit.seconds === currentSeconds) {
            $scope.timeSpan.seconds = unit.seconds;
            return true;
          }
        });
      });

    $scope.$on('$destroy', function () {
      // Don't forget to save the changes back to the repository, or page refresh will lose the data!
      DashboardRepository.set($scope.dashboard);
    });
  }
];
