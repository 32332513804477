angular
  .module('dlcApp.factories')
  .factory('APIClient', [
    '$http', '$q', '$state', 'TranslateNotify', 'cookies', 'ChartModel', 'ChartSeriesModel', 'Utils', 'stats',
    function ($http, $q, $state, TranslateNotify, cookies, ChartModel, ChartSeriesModel, Utils, stats) {
      var CACHE_LIFETIME = 300000;

      var APIClient = function (prefix) {
        this.prefix = (prefix || 'v1').replace(/^\/|\/$/, '');

        var token = cookies.get('token');
        if (token) {
          this.token = JSON.parse(token);
        } else {
          this.token = null;
        }

        this.redirectOnError = true;
      };

      APIClient.prototype.hasToken = function () {
        return Boolean(this.token && this.token.token_type && this.token.access_token);
      };

      APIClient.prototype.setToken = function (token, setCookie) {
        this.token = token;
        if (setCookie !== false) {
          cookies.set('token', JSON.stringify(token), { expires: token['.expires'] });
        }
      };

      APIClient.prototype.removeToken = function () {
        this.token = null;
        cookies.expire('token');
      };

      APIClient.prototype.buildPath = function (path) {
        return this.prefix + '/' + path.replace(/^\/+/, '');
      };

      APIClient.prototype.request = function (options, requiresAuthorization) {
        var _this = this;

        if (requiresAuthorization === undefined) {
          requiresAuthorization = true;
        }

        if (requiresAuthorization && !this.hasToken()) {
          return $q.reject('No API Token');
        }

        // Don't automatically display auth errors for the 'ping' call.
        var suppressAuthError = (options.url === '/sessions');

        // Ensure all requests are namespaced.
        options.url = this.buildPath(options.url);

        if (requiresAuthorization) {
          if (!options.headers) {
            options.headers = {};
          }
          options.headers.Authorization = this.token.token_type + ' ' + this.token.access_token;
        }

        return $http(options)
          .error(function (data, status) {
            if (status === 401 && !suppressAuthError) {
              _this.removeToken();
              _this.redirectToLogin(data);
            }
          });
      };

      APIClient.prototype.ping = function () {
        var _this = this;

        if (this.hasToken()) {
          var options = {
            method: 'GET',
            url: '/sessions',
            params: {
              access_token: this.token.access_token
            }
          };

          return this.request(options)
            .error(function (data) {
              _this.removeToken();
              _this.redirectToLogin('Invalid API Token');
            });
        } else {
          return $q.reject('No API token');
        }
      };

      APIClient.prototype.redirectToLogin = function (reason) {
        if (!this.redirectOnError) {
          return;
        }

        if (reason != 'No API token') {
          TranslateNotify({
            messageKey: 'errors.invalidOrExpiredSession',
            classes: 'alert-danger'
          });
        }
        $state.go('authentication.login');
      };

      APIClient.prototype.register = function (user) {
        return this.request({
          method: 'POST',
          url: '/accounts/register',
          data: user
        }, false);
      };

      APIClient.prototype.forgottenPassword = function (email) {
        return this.request({
          method: 'POST',
          url: '/accounts/resetpassword',
          data: {
            email: email
          }
        }, false);
      };

      APIClient.prototype.setPassword = function (token, password) {
        return this.request({
          method: 'POST',
          url: '/accounts/setpassword',
          data: {
            resetToken: token,
            newPassword: password
          }
        }, false);
      };

      APIClient.prototype.profile = function () {
        return this.request({
          method: 'GET',
          url: '/accounts/profile'
        });
      };

      /**
       * Note that this one call doesn't *send* JSON.
       */
      APIClient.prototype.login = function (email, password) {
        var _this = this;

        var options = {
          method: 'POST',
          url: '/tokens',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          transformRequest: function (json) {
            var params = [];
            for (var k in json) {
              params.push(encodeURIComponent(k) + '=' + encodeURIComponent(json[k]));
            }
            return params.join('&');
          },
          data: {
            userName: email,
            password: password,
            grant_type: 'password'
          }
        };

        return this.request(options, false).then(function (res) {
          if (res.status === 200) {
            _this.email = email;
            _this.setToken(res.data);
          } else {
            _this.removeToken();
          }

          return res;
        });
      };

      APIClient.prototype.logout = function () {
        this.removeToken();
      };

      /* Gateways */

      APIClient.prototype.getGateways = function () {
        return this.request({
          method: 'GET',
          url: '/gateways'
        });
      };

      APIClient.prototype.getGateway = function (id) {
        return this.request({
          method: 'GET',
          url: '/gateways/' + id
        });
      };

      APIClient.prototype.createGateway = function (gateway) {
        return this.request({
          method: 'POST',
          url: '/gateways',
          data: {
            name: gateway.name,
            description: gateway.description,
            type: gateway.type,
            serialNo: gateway.serialNo,
            accessCode: gateway.accessCode,
            timeZone: gateway.timeZone
          }
        });
      };

      APIClient.prototype.updateGateway = function (gateway) {
        return this.request({
          method: 'PUT',
          url: '/gateways/' + gateway.id,
          data: {
            name: gateway.name,
            description: gateway.description
          }
        });
      };

      APIClient.prototype.updateGatewaySchedule = function (gatewaySchedule, gateway) {
        return this.request({
          method: 'PUT',
          url: '/gateways/' + gateway.id + '/schedule',
          data: gatewaySchedule
        });
      };

      APIClient.prototype.deleteGateway = function (gateway) {
        return this.request({
          method: 'DELETE',
          url: '/gateways/' + gateway.id
        });
      };

      APIClient.prototype.createGatewayTunnel = function (gateway) {
        return this.request({
          method: 'POST',
          url: '/gateways/' + gateway.id + '/tunnel'
        });
      };

      APIClient.prototype.getGatewayTunnel = function (gateway) {
        return this.request({
          method: 'GET',
          url: '/gateways/' + gateway.id + '/tunnel'
        });
      };

      APIClient.prototype.deleteGatewayTunnel = function (gateway) {
        return this.request({
          method: 'DELETE',
          url: '/gateways/' + gateway.id + '/tunnel'
        });
      };

      /* Loggers */

      APIClient.prototype.getLoggers = function () {
        var deferred = $q.defer();

        this.request({
          method: 'GET',
          url: '/loggers'
        }).then(
          deferred.resolve,
          function (err) {
            if (err.status == 404) {
              deferred.resolve({ data: [] });
            } else {
              deferred.reject(err);
            }
          }
        );

        return deferred.promise;
      };

      APIClient.prototype.getLogger = function (id) {
        return this.request({
          method: 'GET',
          url: '/loggers/' + id
        });
      };

      APIClient.prototype.createLogger = function (logger) {
        return this.request({
          method: 'POST',
          url: '/loggers',
          data: {
            name: logger.name,
            description: logger.description,
            serialNo: logger.serialNo,
            gatewayId: logger.gatewayId
          }
        });
      };

      APIClient.prototype.updateLogger = function (logger) {
        return this.request({
          method: 'PUT',
          url: '/loggers/' + logger.id,
          data: {
            name: logger.name,
            description: logger.description,
            gatewayId: logger.gatewayId
          }
        });
      };

      APIClient.prototype.deleteLogger = function (logger) {
        return this.request({
          method: 'DELETE',
          url: '/loggers/' + logger.id
        });
      };

      /* Datasets */

      APIClient.prototype.getDatasets = function (force) {
        var _this = this;

        if (!force) {
          var c = this.datasetsCache || {};

          if (c.expiry > Date.now() && Array.isArray(c.data) && c.data.length > 0) {
            return $q.when({
              data: c.data
            });
          }
        }

        return this
          .request({
            method: 'GET',
            url: '/datasets'
          })
          .then(function (res) {
            if (!_this.datasetsCache) {
              _this.datasetsCache = {};
            }

            _this.datasetsCache.expiry = Date.now() + CACHE_LIFETIME;
            _this.datasetsCache.data = res.data;

            return res;
          });
      };

      APIClient.prototype.getDataset = function (id, force) {
        var _this = this;

        if (!force) {
          var c = (this.datasetsCache || {})[id] || {};

          if (c.expiry > Date.now() && c.data) {
            return $q.when({
              data: c.data
            });
          }
        }

        stats.start('getDataset:' + id);
        return this
          .request({
            method: 'GET',
            url: '/datasets/' + id
          })
          .then(function (res) {
            if (!_this.datasetsCache) {
              _this.datasetsCache = {};
            }

            _this.datasetsCache[id] = {
              expiry: Date.now() + CACHE_LIFETIME,
              data: res.data
            };

            stats.stop('getDataset:' + id);
            return res;
          });
      };

      APIClient.prototype.updateDataset = function (dataset) {
        return this.request({
          method: 'PUT',
          url: '/datasets/' + dataset.id,
          data: {
            name: dataset.name,
            description: dataset.description
          }
        });
      };

      APIClient.prototype.deleteDataset = function (dataset) {
        return this.request({
          method: 'DELETE',
          url: '/datasets/' + dataset.id
        });
      };

      /**
       * @param {moment} from
       * @param {moment} to
       * @param {DataSeries[]} series
       *
       * DataSeries: {
       *   datasetId: Number,
       *   dataSeriesId: Number,
       *   bucketSize: String,
       *   allowNull: Boolean
       * }
       *
       * @return {Promise}
       */
      APIClient.prototype.getDataSetValues = function (from, to, series) {
        return this.request({
          method: 'POST',
          url: '/datasets/values',
          data: {
            from: from.format(),
            to: to.format(),
            dataSeries: series
          }
        });
      };

      /* Reports */

      /**
       * Format a ReportModel object for use by the API.
       *
       * @param {ReportModel} report Report to format.
       *
       * @return {Object}
       */
      APIClient.prototype._formatReportModel = function (report) {
        return {
          name: report.name,
          description: report.description,
          datasetIds: report.datasets.map(function (dataset) {
            return dataset.id;
          }),
          charts: report.charts.map(function (chart, chartIdx) {
            if (!(chart instanceof ChartModel)) {
              console.error(chart);
              throw "Invalid chart in _formatReportModel!";
            }

            return {
              orderIdx: chartIdx,
              name: chart.name,
              description: chart.description,
              series: chart.series.map(function (series) {
                if (!(series instanceof ChartSeriesModel)) {
                  console.error(series);
                  throw "Invalid chart series in _formatReportModel!";
                }

                return {
                  datasetId: series.datasetId,
                  dataSeriesId: series.dataSeriesId,
                  roseName: series.roseName,
                  chartType: series.chartType
                  // These options aren't currently used, as there's no way to set them.
                  // color: '',
                  // symbol: ''
                };
              })
            };
          })
        };
      };

      APIClient.prototype.getReports = function () {
        return this.request({
          method: 'GET',
          url: '/reports'
        });
      };

      APIClient.prototype.getReport = function (id) {
        stats.start('getReport:' + id);
        return this.request({
          method: 'GET',
          url: '/reports/' + id
        }).success(function () {
          stats.stop('getReport:' + id);
        });
      };

      APIClient.prototype.createReport = function (report) {
        return this.request({
          method: 'POST',
          url: '/reports',
          data: this._formatReportModel(report)
        });
      };

      APIClient.prototype.updateReport = function (report) {
        return this.request({
          method: 'PUT',
          url: '/reports/' + report.id,
          data: this._formatReportModel(report)
        });
      };

      APIClient.prototype.deleteReport = function (report) {
        return this.request({
          method: 'DELETE',
          url: '/reports/' + report.id
        });
      };

      APIClient.prototype.createSharedReport = function (report, downloadable) {
        var data = {};

        if (typeof downloadable === 'boolean') {
          data = {
            allowDataDownload: downloadable
          };
        }

        return this.request({
          method: 'POST',
          url: '/reports/shared/' + report.id,
          data: data
        });
      };

      APIClient.prototype.getSharedReport = function (token) {
        return this.request({
          method: 'POST',
          url: '/reports/shared/token',
          data: {
            link_token: token
          }
        }, false);
      };

      /* Dashboards */

      /**
       * Format an object for use by the dashboards API.
       *
       * @param {Object} dashboard Object to format.
       *
       * @return {Object}
       */
      APIClient.prototype._formatDashboard = function (dashboard) {
        return {
          name: dashboard.name || '',
          description: dashboard.description || '',
          creator: dashboard.creator || this.email || '',
          widgets: this._formatWidgets(dashboard.widgets)
        };
      };

      APIClient.prototype._formatWidgets = function (widgets) {
        if (!widgets) {
          return [];
        }

        var _this = this;

        return widgets.map(function (widget, idx) {
          return {
            orderIdx: idx,
            name: widget.name || '',
            header: widget.header || '',
            type: widget.type,
            color: widget.color || '',
            thresholds: _this._formatWidgetThresholds(widget.thresholds),
            detail: _this._formatWidgetDetail(widget.detail)
          };
        });
      };

      APIClient.prototype._formatWidgetThresholds = function (thresholds) {
        if (!thresholds) {
          return [];
        }

        return thresholds
          .filter(function (threshold) {
            if (!threshold.data) {
              return false;
            }

            return threshold.data.number !== undefined || Utils.get(threshold, 'data.dataseries.id') !== undefined;
          })
          .map(function (threshold) {
            return {
              color: threshold.color || '',
              value: threshold.data && threshold.data.number,
              dataseriesId: threshold.data.dataseries && threshold.data.dataseries.id
            };
          });
      };

      APIClient.prototype._formatWidgetDetail = function (detail) {
        var data = detail.data || {};

        var dataSeriesIds = data.dataseries && data.dataseries.map(function (dataseries) {
          return dataseries.id;
        });

        return {
          valueString: data.text || undefined,
          dataseriesIds: dataSeriesIds || undefined,
          dataTimeSpan: detail.dataTimeSpan || undefined,
          showHistory: detail.showHistory || undefined,
          minimum: !isNaN(parseInt(detail.minimum, 10)) ? parseInt(detail.minimum, 10) : undefined,
          maximum: !isNaN(parseInt(detail.maximum, 10)) ? parseInt(detail.maximum, 10) : undefined
        };
      };

      APIClient.prototype.getSharedDashboard = function (token, timeout) {
        return this.request({
          method: 'POST',
          url: '/dashboards/shared/token',
          data: {
            link_token: token
          },
          timeout: timeout
        }, false);
      };

      APIClient.prototype.getDashboards = function () {
        return this.request({
          method: 'GET',
          url: '/dashboards'
        });
      };

      APIClient.prototype.getDashboard = function (id, timeout) {
        return this.request({
          method: 'GET',
          url: '/dashboards/' + id,
          timeout: timeout
        });
      };

      APIClient.prototype.postDashboard = function (dashboard) {
        return this.request({
          method: 'POST',
          url: '/dashboards',
          data: this._formatDashboard(dashboard)
        });
      };

      APIClient.prototype.putDashboard = function (dashboard) {
        return this.request({
          method: 'PUT',
          url: '/dashboards/' + dashboard.id,
          data: this._formatDashboard(dashboard)
        });
      };

      APIClient.prototype.deleteDashboard = function (id) {
        return this.request({
          method: 'DELETE',
          url: '/dashboards/' + id
        });
      };

      APIClient.prototype.shareDashboard = function (id) {
        return this.request({
          method: 'POST',
          url: '/dashboards/shared/' + id
        });
      };

      /* Notifications */

      APIClient.prototype.getNotifications = function (count, offset) {
        if (offset === undefined) {
          offset = 0;
        }

        if (count === undefined) {
          count = 10;
        }

        return this.request({
          url: '/notifications',
          params: {
            startIdx: offset,
            count: count
          }
        });
      };

      APIClient.prototype.putNotificationsLastRead = function (lastRead) {
        if (lastRead === undefined) {
          lastRead = moment.utc().format();
        }

        return this.request({
          method: 'PUT',
          url: '/notifications/lastRead',
          data: {
            lastReadUTC: lastRead
          }
        });
      };

      /**
       * @param {GetDataModel[]} series
       *
       * GetDataModel: {
       *   datasetId: Number,
       *   dataSeriesIds: Array<Number>,
       *   from: ?Moment,
       *   to: ?Moment,
       *   limit: ?Number,
       *   extendOneValue: ?bool,
       *   bucketSize: ?Number
       * }
       *
       * Either (from && to) || (limit) must be specified.
       *
       * @return {Promise}
       */
      APIClient.prototype.getLatestValues = function (series) {

        // Loop through the set of dataseries passed to this function, and sanitise
        // the data inside each one (turn Moment objects into proper timestamps,
        // nullify any empty values etc.)
        var data = series.map(function (s) {
          return {
            datasetId: s.datasetId,
            dataSeriesIds: s.dataSeriesIds || null,
            from: s.from ? s.from.format() : null,
            to: s.to ? s.to.format() : null,
            limit: s.limit || null,
            extendOneValue: s.extendOneValue || null,
            bucketSize: s.bucketSize || null,
            aggregate: s.aggregate || null
          };
        });

        return this.request({
          method: 'POST',
          url: '/datasets/latestValues',
          data: data
        });
      };

      /**
       * Calculate aggregated bucket size for a chart
       *
       * @param {Series} series        A data series.
       * @param {Moment} from          Start date.
       * @param {Moment} to            End date.
       * @param {Number} maxDataPoints Maximum data points to fetch.
       *
       * @return {mixed} False if no aggregation needed, or a bucket size string like '120s'.
       */
      APIClient.prototype.calcChartBucketSize = function (series, from, to, maxDataPoints) {
        if (!moment.isMoment(from) || !moment.isMoment(to)) {
          throw new Error('Must use Moment objects!');
        }

        var seconds = Math.ceil(to.diff(from) / 1000);

        var safeSampleRate = series.sampleRate || 300;
        var rawDataPoints = Math.ceil(seconds / safeSampleRate);

        if (!series.sampleRate) {
          console.error('Series', series.id, 'has no sampleRate!');
        }

        // Calculate the bucket size needed to get a sensible number of points on-screen.
        if (rawDataPoints > maxDataPoints) {
          var bucketSize = Math.ceil(seconds / maxDataPoints);

          var breaks = [604800, 86400, 3600, 1800, 600, 300, 60, 30, 10, 5, 1];
          var breakValue = 1;
          for (var i = 0; i < breaks.length; i++) {
            if (bucketSize > breaks[i]) {
              breakValue = breaks[i];
              break;
            }
          }

          bucketSize = Math.ceil(bucketSize / breakValue) * breakValue;
          return bucketSize + 's';
        }
        return false;
      };

      return APIClient;
    }
  ])
  .factory('api', ['APIClient', function (APIClient) {
    return new APIClient();
  }])
  .factory('sharedAPI', [
    'APIClient',
    function (APIClient) {
      var instances = {};

      return function (name) {
        if (!instances[name]) {
          instances[name] = new APIClient();
        }

        return instances[name];
      };
    }
  ]);
