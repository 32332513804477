module.exports = [
  '$rootScope',
  function pageTitle($rootScope) {
    return {
      link: function (scope, element) {
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
          // Default title
          var title = 'DeltaLINK-Cloud';

          if (toState.data && toState.data.pageTitle) {
            title += ' | ' + toState.data.pageTitle;
          }

          element.text(title);
        });
      }
    };
  }
];
