module.exports = [
  '$scope', '$state', '$q', '$translate', 'DashboardRepository', 'WidgetTypes',
  function ($scope, $state, $q, $translate, DashboardRepository, WidgetTypes) {
    var TYPE_GAUGE = 'gauge';
    var TYPE_LINE = 'line';
    var TYPE_TEXT_DATA = 'textData';
    var TYPE_TEXT_HISTORY = 'textHistory';
    var TYPE_TEXT_STATIC = 'textStatic';
    var TYPE_WIND_ROSE = 'windRose'; // Only one type here, will handle both Wind Roses and Wind Class Roses

    var typeNames = [TYPE_GAUGE, TYPE_LINE, TYPE_WIND_ROSE, TYPE_TEXT_HISTORY, TYPE_TEXT_DATA, TYPE_TEXT_STATIC];
    $scope.types = typeNames.map(function (name) {
      return {
        id: name,
        name: 'dashboards.addWidget.types.' + name + '.name',
        intro: 'dashboards.addWidget.types.' + name + '.intro'
      };
    });

    $scope.dashboard = DashboardRepository.get();

    $scope.addWidget = function (type) {
      var typeId;
      switch (type.id) {
        case TYPE_GAUGE:
          typeId = WidgetTypes.GAUGE;
          break;

        case TYPE_LINE:
          typeId = WidgetTypes.LINE;
          break;

        case TYPE_WIND_ROSE:
          typeId = WidgetTypes.WINDROSE;
          break;

        default:
          typeId = WidgetTypes.TEXT;
      }

      var widget = {
        isNew: true,
        placeholder: true,
        name: '',
        header: '',
        type: typeId,
        color: typeId === WidgetTypes.LINE ? '#ffffff' : '#0e9aef',
        thresholds: [],
        detail: {
          data: {
          }
        }
      };

      var titleKey = 'dashboards.addWidget.title';
      var subtitleKey = 'dashboards.addWidget.subtitle';
      var placeholderKey = 'dashboards.addWidget.types.' + TYPE_TEXT_STATIC + '.placeholderText';
      $translate([titleKey, subtitleKey, placeholderKey])
        .then(function (translations) {
          widget.header = translations[titleKey];
          widget.name = translations[subtitleKey];

          if (type.id === TYPE_TEXT_HISTORY) {
            widget.detail.showHistory = true;
          } else if (type.id === TYPE_TEXT_STATIC) {
            widget.detail.data.text = translations[placeholderKey];
          }

          $scope.dashboard.widgets.push(widget);
          $state.go('app.dashboards.draft');
        });
    };

    $scope.$on('$destroy', function () {
      DashboardRepository.set($scope.dashboard);
    });
  }
];
