var Utils = {};

Utils.get = function (object, keyPath, defaultValue) {
  var keys = keyPath.split('.');
  var value = object;

  var key;
  while ((key = keys.shift())) {
    if (key in value) {
      value = value[key];
      continue;
    }

    return defaultValue;
  }

  return value;
};

module.exports = Utils;
