module.exports = [
  function () {
    return {
      restrict: 'E',
      scope: {
        gateway: '=',
        cancel: '&'
      },
      templateUrl: '/views/devices/gateway_sync_schedule_edit_form.html',
      controller: 'GatewaySyncScheduleEditController',
      controllerAs: 'ctrl'
    };
  }
];

