// WINDROSE is used for both Wind Roses and Wind Class Roses, so that users
// can just choose a single "Wind Rose" widget type at /dashboards/add-widget
// and have that single type display data as either a Wind Rose or a WInd Class Rose

var types = ['GAUGE', 'LINE', 'TEXT', 'WINDROSE'];

module.exports = {
  GAUGE: 0,
  LINE: 1,
  TEXT: 2,
  WINDROSE: 3,
  getName: function (i) {
    return types[i];
  }
};
