module.exports = [
  function () {
    var BaseStorage = require('./base');

    function MemoryStorage() {
      this.items = {};
    }

    MemoryStorage.prototype.has = function (key) {
      BaseStorage.prototype.validateString.call(this, key, 'Key');
      return this.items.hasOwnProperty(key);
    };

    MemoryStorage.prototype.set = function (key, value) {
      BaseStorage.prototype.validateString.call(this, key, 'Key');
      BaseStorage.prototype.validateString.call(this, value, 'Value');
      this.items[key] = value;
    };

    MemoryStorage.prototype.get = function (key, defaultValue) {
      BaseStorage.prototype.validateString.call(this, key, 'Key');
      if (defaultValue !== undefined) {
        BaseStorage.prototype.validateString.call(this, defaultValue, 'Default value');
      }
      return this.items[key] || defaultValue;
    };

    MemoryStorage.prototype.remove = function (key) {
      BaseStorage.prototype.validateString.call(this, key, 'Key');
      delete this.items[key];
    };

    return new MemoryStorage();
  }
];
